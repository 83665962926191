import { useState } from "react";
import ServiceLeveLAgreement from "../../components/ServiceLevelAgreement/ServiceAgreement";
import NavBar from "../../components/navBar/NavBar";
import Footer from "../../components/footer/Footer";

const ServiceAgreement = () => {
  const [showSideBar, setShowSideBar] = useState(false);

  return (
    <>
      <NavBar showSideBar={showSideBar} setShowSideBar={setShowSideBar} />
      {!showSideBar && (
        <>
          <ServiceLeveLAgreement />
        </>
      )}
      <Footer />
    </>
  );
};

export default ServiceAgreement;
