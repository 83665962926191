import { ChangeEvent } from "react";
import MilerButton from "../milerButton/MilerButton";
import { FormFields } from "../../pages/getStarted/getStarted";
import clsx from "clsx";
import LoadingComponent from "../loading/Loading";
import Alert from "../alert/Alert";
import getProductSolutionName from "../../utils/getProductSolutionName";

interface FirstStepProps {
  nextStep: () => void;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  companyDetails: FormFields;
  error: boolean;
  loading: boolean;
  apiError: boolean;
  product: string;
}

const FirstStep: React.FC<FirstStepProps> = ({
  nextStep,
  handleChange,
  companyDetails,
  error,
  loading,
  apiError,
  product,
}) => {
  return (
    <main>
      <section className=" w-full flex flex-row h-screen ">
        <section className="w-[50%] bg-miler_blue h-screen flex flex-col items-center justify-center gap-y-7">
          <p className="text-4xl text-white">
            {`Let's setup your ${getProductSolutionName(product)} account`}
          </p>
        </section>
        <section className=" w-[50%] px-[4rem]  flex flex-col justify-center">
          <section className=" flex flex-col gap-y-2">
            <p className="font-bold text-3xl">Let's get started!</p>
            <p className=" font-bold text-miler_yellow text-3xl">
              {`Create a ${getProductSolutionName(product)} `}
              <span className=" font-bold text-3xl text-miler_yellow"></span>{" "}
              account
            </p>
          </section>
          <section className=" flex flex-col gap-y-7 mt-4">
            <section className="flex flex-col gap-y-2">
              <p className="font-semibold antialiased">
                {" "}
                Enter your DOT Number
              </p>
              <input
                type="text"
                value={companyDetails.dotNumber}
                className={clsx(
                  "w-full rounded-md border border-solid border-gray-400 p-[0.45rem] focus:outline-none",
                  {
                    "border border-[#ff2c2c] border-solid": error,
                  }
                )}
                placeholder="(1-8) digits"
                name="dotNumber"
                onChange={handleChange}
              />
              {error && (
                <p className=" text-[#ff2c2c] font-medium text-[0.7rem]">
                  Dot Number is required.
                </p>
              )}
              {apiError && <Alert />}
            </section>
            {loading ? (
              <LoadingComponent />
            ) : (
              <MilerButton
                title="Continue"
                size="rounded"
                handleClick={nextStep}
              />
            )}
          </section>
        </section>
      </section>
    </main>
  );
};

export default FirstStep;
