import clsx from "clsx";
import MilerButton from "../milerButton/MilerButton";
import formData from "./formData";
import { ChangeEvent } from "react";
import { FormFields } from "../../pages/getStarted/getStarted";
import getProductSolutionName from "../../utils/getProductSolutionName";

interface SecondStepProps {
  nextStep: () => void;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  companyDetails: FormFields;
  // error:boolean
  // error: boolean;
  // subscriptionSolution: string;
  // loading: boolean;
  // apiError: boolean;
  // subscription: string
  product: string;
}
const SecondStep = (props: SecondStepProps) => {
  const { companyDetails, handleChange, nextStep, product } = props;
  return (
    <main>
      <section className=" w-full flex flex-row ">
        <section className="w-[50%] bg-miler_blue h-screen flex flex-col items-center justify-center gap-y-7">
          <p className="text-4xl text-white">{`Setting up your ${getProductSolutionName(
            product
          )} account`}</p>
        </section>
        <section className=" w-[50%] px-[4rem] flex flex-col justify-center ">
          <section className=" flex flex-col gap-y-5">
            <p className="font-bold text-[1.7rem]">{`Welcome Onboard,`} </p>
            <p className="font-bold text-[1.7rem] text-miler_yellow">{`${companyDetails.companyInfo.company}`}</p>
            <p>
              We found a match in the publicly available information registered
              with the FMCSA for your carrier.{" "}
            </p>
            <p className=" font-semibold">Please confirm your fleet size.</p>
          </section>
          <section className=" grid grid-cols-1 gap-4 mt-4">
            {formData.map((fieldInfo) => {
              return (
                <>
                  <section className="flex flex-col gap-y-2">
                    <p className="font-semibold antialiased">
                      {fieldInfo.label}
                    </p>
                    <input
                      type="text"
                      value={companyDetails.companyInfo.fleet}
                      className={clsx(
                        " w-full rounded-md border border-solid border-gray-400 p-[0.45rem] focus:outline-none"
                      )}
                      placeholder={fieldInfo.placeHolderText}
                      name="fleet"
                      onChange={handleChange}
                    />
                    {/* {error && <p>Fleet Size must more than 5</p>} */}
                  </section>
                </>
              );
            })}
          </section>
          <section className=" mt-9">
            <MilerButton
              title="Continue"
              size="rounded"
              handleClick={nextStep}
            />
          </section>
        </section>
      </section>
    </main>
  );
};

export default SecondStep;
