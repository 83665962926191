const getProductSolutionName = (product: string) => {
  if (
    product ===
    "a2f078838857b1df381599a5560baaf72d1433f07e0c1f74ad482bf477cf719e"
  ) {
    return "carrier";
  } else if (
    product ===
      "4f09941115733a4e7609132dccc9891dcbf7b178e6af0857494e2c5ddd3f0051" ||
    product ===
      "4f09941115733a4e7609132dccc9891d64ca422e9690a35fb7d3f8995def816b" ||
    product ===
      "4f09941115733a4e7609132dccc9891d398d23d2f73003bf027cc62133d9423d"
  ) {
    return "broker";
  }
  if (
    product ===
      "4f09941115733a4e7609132dccc9891da6018b5446c1e5f3a3736e575b0b28dd" ||
    product ===
      "4f09941115733a4e7609132dccc9891d938a4aa64b216cc08e20721c6c09c65f"
  ) {
    return "shipper";
  }
  if (
    product ===
    "4f09941115733a4e7609132dccc9891de191e0d78598307f897808dcdcab122b"
  ) {
    return "provider";
  }
  return "";
};

export default getProductSolutionName;
