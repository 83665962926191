import MilerButton from "../milerButton/MilerButton";
import { useNavigate } from "react-router-dom";
import WrapperComponent from "../wrapperComponent/wrapperComponent";

interface HeroSectionProps {
  title: string;
  subTitle: string;
  description: string;
  image: string;
  isHomePage: boolean;
}

const HeroSection = (props: HeroSectionProps) => {
  const { title, subTitle, description, image, isHomePage = true } = props;
  const navigate = useNavigate();
  return (
    <>
      <section
        className={`bg-cover ${
          isHomePage
            ? `bg-miler_banner bg-[left_center]`
            : ` bg-aboutus_banner bg-right bg-no-repeat h-[30rem]`
        }  grid grid-cols-2 gap-[15rem] items-center  max-sm:w-full w-screen overflow-x-hidden max-sm:p-0 max-sm:flex max-sm:flex-col max-sm:bg-center max-sm:bg-contain max-sm:bg-no-repeat max-sm:h-fit max-sm:bg-slate-50 max-sm:gap-0 max-sm:overflow-y-hidden max-md:w-full max-md:flex max-md:flex-col max-md:gap-0 max-md:px-[1.5rem]`}
      >
        <WrapperComponent>
          <section className="w-[38rem] max-sm:mt-3 max-sm:w-full max-sm:p-1 max-sm:flex max-sm:flex-col max-sm:gap-y-2">
            <p
              className={
                isHomePage
                  ? "text-miler_blue text-5xl font-bold antialiased max-sm:text-[1.5rem] max-sm:w-full max-md:w-full max-md:text-[2.1rem]"
                  : `text-white text-5xl font-bold antialiased max-sm:text-[1.5rem] max-sm:w-full max-md:w-full max-md:text-[2.1rem]`
              }
            >
              {title}
            </p>
            <p className="text-5xl text-miler_yellow mt-3 font-extrabold antialiased max-sm:text-[1.2rem] max-md:w-full max-md:text-[1.8rem] ">
              {subTitle}
            </p>
            <section className="flex flex-col gap-6 mt-3 max-md:gap-2 w-full">
              <p
                className={
                  isHomePage
                    ? "text-[1rem] w-[38rem] antialiased leading-9 font-medium  max-sm:w-full max-sm:text-justify text-justify max-md:w-fit"
                    : " text-white text-[1rem] w-[80%] antialiased leading-9 font-medium  max-sm:w-full max-sm:text-justify text-justify max-md:w-fit"
                }
              >
                {description}
              </p>
              {isHomePage && (
                <section className="w-9">
                  <MilerButton
                    title="Schedule a demo"
                    size="large"
                    showIcon={true}
                    handleClick={() => {
                      navigate("/request-demo");
                    }}
                  />
                </section>
              )}
            </section>
          </section>
        </WrapperComponent>
        {image && (
          <section>
            <img
              src={image}
              width={550}
              height={232}
              alt="macbook_pro_image"
              className="object-contain max-sm:hidden max-lg:hidden "
            />
          </section>
        )}
      </section>
    </>
  );
};

export default HeroSection;
