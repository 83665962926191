import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const PageTitleUpdater = () => {
  const location = useLocation();

  useEffect(() => {
    const path = location.pathname;
    const pageTitles: { [key: string]: string } = {
      "/solutions/carrier": "Carrier TMS - Miler",
      "/solutions/broker": "Broker TMS - Miler",
      "/solutions/shipper": "Shipper TMS - Miler",
      "/solutions/provider": "Heavy-duty Fleet Maintenance Software - Miler",
      "/about-us": "About Us - Miler",
      "/request-demo":
        "Schedule Demo - Cloud Based Transport Management System (TMS) - Miler",
      "/pricing":
        "Pricing - Cloud Based Transport Management System (TMS) - Miler",
      "/blogs": "Blogs - Cloud Based Transport Management System (TMS) - Miler",
      "/privacy":
        "Privacy Policy - Cloud Based Transport Management System (TMS) - Miler",
      "/terms": "EULA - Cloud Based Transport Management System (TMS) - Miler",
      "/sla": "Service Level Agreement - Cloud Based Transport Management System (TMS) - Miler)",
    };

    const newTitle =
      pageTitles[path] ||
      "Cloud Based Transport Management System (TMS) - Miler";

    document.title = newTitle;
  }, [location]);

  return null;
};

export default PageTitleUpdater;
