import { CheckIcon } from "@heroicons/react/20/solid";

interface listItems {
  title: string;
  description: string;
}
interface SolutionProps {
  heading: string;
  itemList: listItems[];
  side: "right" | "left";
}
const SolutionCard = (props: SolutionProps) => {
  const { heading, itemList, side } = props;
  return (
    <>
      {side === "right" && (
        <section className="mt-4 ">
          <section className="flex flex-col justify-center items-center gap-y-2">
            <p className="text-miler_yellow text-sm">SOLUTION</p>
            <p className=" text-miler_blue font-bold text-xl">
              Unique value propositions
            </p>
            <p className="w-[48rem] text-center text-miler_text_neutral max-sm:w-[90%]">
              Lorem ipsum dolor sit amet consectetur, adipisicing elit. Eos
              sapiente sed similique adipisci corrupti veritatis?
            </p>
          </section>
          <section className="flex flex-row justify-evenly mt-4  max-sm:grid max-sm:grid-col-1 max-sm:p-3 max-sm:gap-2">
            <section className="flex flex-col gap-y-1  ">
              <p className=" text-miler_blue font-bold text-xl ">{heading}</p>
              {itemList.map((item, index) => {
                return (
                  <section key={index} className="flex flex-col gap-y-1">
                    <section className="flex flex-row gap-x-2">
                      <CheckIcon className="w-4 text-green-500" />
                      <p className="text-miler_blue font-semibold">
                        {item.title}
                      </p>
                    </section>
                    <p className="w-[37rem] ml-6 max-sm:w-[90%]">
                      {item.description}
                    </p>
                  </section>
                );
              })}
            </section>
            <section>
              <p className="w-[38rem] h-[20rem] bg-miler_light_blue rounded max-sm:w-full leading-7 tracking-normal antialiased"></p>
            </section>
          </section>
        </section>
      )}

      {side === "left" && (
        <section className="flex flex-row justify-evenly mt-8 max-sm:grid max-sm:grid-col-1 max-sm:p-3 max-sm:gap-2">
          <p className="w-[38rem] h-[20rem] bg-miler_light_blue rounded max-sm:w-full"></p>
          <section>
            <section className=" flex flex-col gap-y-1 max-sm:mt-3">
              <p className=" text-miler_blue font-bold text-xl">{heading}</p>
              {itemList.map((item, index) => {
                return (
                  <section key={index} className=" flex flex-col gap-y-1">
                    <section className="flex flex-row gap-x-2">
                      <CheckIcon className="w-4 text-green-500" />
                      <p className="text-miler_blue font-semibold">
                        {item.title}
                      </p>
                    </section>
                    <p className="w-[37rem] ml-6 max-sm:w-[90%] leading-7 tracking-normal antialiased">
                      {item.description}
                    </p>
                  </section>
                );
              })}
            </section>
          </section>
        </section>
      )}
    </>
  );
};

export default SolutionCard;
