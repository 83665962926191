import { useState } from "react";
import NavBar from "../../components/navBar/NavBar";
import Footer from "../../components/footer/Footer";
import HeroPageSection from "../../components/pagesSection/HeroSection";
import MilerButton from "../../components/milerButton/MilerButton";
import { useNavigate } from "react-router-dom";
import AboutUsImage from "../../assets/images/about_us.jpg";
const AboutUs = () => {
  const [showSideBar, setShowSideBar] = useState(false);
  const navigate = useNavigate();

  return (
    <section>
       
      <NavBar showSideBar={showSideBar} setShowSideBar={setShowSideBar} />
      {!showSideBar && (
        <>
          <HeroPageSection
            title="About Miler"
            description="Miler offers a suite of cloud-native software solutions for freight brokers, carriers, and heavy-duty maintenance service providers through an integrated platform"
            plansOptions={[]}
            heroImage={""}
            subscriptionPlan={""}
          />

          <section className=" flex flex-col gap-y-3 justify-center items-center w-full p-[3.5rem] py-4 max-sm:px-[2rem]">
            <p className=" text-miler_yellow font-bold text-3xl max-sm:text-xl">
              Our Mission
            </p>
            <p className="text-center">
              To create a technology ecosystem that powers an interconnected
              freight industry.
            </p>
          </section>

          <section className=" flex flex-row justify-center items-center w-full  mt-10 px-[3.5rem] max-sm:flex max-sm:flex-col max-sm:px-[2rem] max-sm:mt-5 max-sm:w-full max-md:flex max-md:flex-col max-md:w-full max-md:mt-5 max-md:px-[2rem] max-lg:flex max-lg:flex-col max-lg:w-full max-lg:p-[2rem] max-lg:mt-3 max-xl:gap-x-10">
            <section className="w-[50%] max-sm:w-full max-md:w-full max-lg:w-full">
              <img
                src={AboutUsImage}
                alt="about_us_image"
                width={570}
                height={700}
                className="object-contain py-5 max-sm:w-full max-md:w-full max-lg:w-full"
              />
            </section>
            <section className="w-[50%] flex flex-col gap-y-3  text-left max-sm:w-full max-sm:py-4 max-md:w-full max-md:py-4 max-lg:w-full max-lg:py-4">
              <p className=" w-[70%] text-miler_blue font-semibold text-xl leading-9 tracking-normal max-sm:w-full max-md:w-full max-lg:w-full max-xl:w-full">
                Don't run solo. Join the Milers, a growing network of freight
                carriers, brokers, shippers and service providers
              </p>
              <MilerButton
                title="Schedule a demo"
                size="large"
                showIcon={true}
                handleClick={() => {
                  navigate("/request-demo");
                }}
              />
            </section>
          </section>
          <Footer />
        </>
      )}
    </section>
  );
};

export default AboutUs;
