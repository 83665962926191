import { useEffect, useState } from "react";
import clsx from "clsx";
import PricingCard from "../pricing/pricingCard/PricingCard";
import { Tab } from "@headlessui/react";
import { tabList } from "./data";
import { useLocation } from "react-router-dom";
// import { CheckIcon } from "@heroicons/react/20/solid";

interface TabProps {
  hidePanel?: boolean;
}
const TabSection = (props: TabProps) => {
  const { hidePanel } = props;
  const [selectedIndex, setSelectedIndex] = useState(0);

  const location = useLocation();
  const productType: string = location.state?.productType;


  useEffect(() => {
    if (productType?.includes("carrier")) {
      return setSelectedIndex(0);
    }
    if (productType?.includes("broker")) {
      return setSelectedIndex(1);
    }
    if (productType?.includes("shipper")) {
      return setSelectedIndex(2);
    }
    if (productType?.includes("provider")) {
      return setSelectedIndex(3);
    }
    setSelectedIndex(0)
  }, [productType]);
  return (
    <section className={clsx("w-screen  p-3 max-sm:w-screen", {})}>
      <section className="flex flex-col p-3">
        <Tab.Group
          as="section"
          className="mt-10"
          selectedIndex={selectedIndex}
          onChange={setSelectedIndex}
        >
          <Tab.List className="flex bg-miler_light_blue hover:border-none hover:outline-none w-fit flex-row gap-x-2 m-auto place-items-center text-[15px]  shadow h-[3rem] rounded-full max-sm:w-full max-sm:h-full max-sm:p-2 max-sm:overflow-x-scroll max-sm:no-scrollbar max-md:overflow-x-scroll max-md:no-scrollbar  max-lg:no-scrollbar max-md:w-full max-lg:w-fit max-xl:w-fit max-xl:m-auto max-xxl:w-fit max-xxl:h-fit max-xxl:m-auto">
            {tabList.map((tab, index) => {
              return (
                <Tab
                  as="section"
                  key={index}
                  className=" border-none  outline-none"
                >
                  {({ selected }) => (
                    <button
                      className={
                        selected
                          ? "bg-miler_blue text-white w-[11rem] h-[60px] rounded-full text-xl max-sm:w-[11rem] max-lg:font-semibold max-lg:w-[11rem] "
                          : "text-xl max-sm:w-[11em] max-lg:w-[11rem] w-[11rem]"
                      }
                    >
                      {tab.title}
                    </button>
                  )}
                </Tab>
              );
            })}
          </Tab.List>
          {hidePanel ? (
            <></>
          ) : (
            <Tab.Panels>
              <section className="flex flex-row mt-4 justify-center">
                <h3 className="font-extrabold text-3xl p-4 max-sm:text-xl max-sm:w-fit max-sm:text-center">
                  {`Pricing plans for `}
                  <span className=" font-extrabold  text-3xl text-miler_yellow max-sm:text-xl">
                    {selectedIndex === 0 && "Carrier Solution"}
                    {selectedIndex === 1 && "Broker Solution"}
                    {selectedIndex === 2 && "Shipper Solution"}
                    {selectedIndex === 3 && "Service Provider Solution"}
                  </span>
                </h3>
                <p></p>
              </section>
              <Tab.Panel
                as="section"
                className="w-full gap-x-3 max-sm:grid max-sm:grid-cols-1 max-sm:p-2  max-md:grid max-md:grid-cols-1 max-lg:grid max-lg:grid-cols-1 max-xl:grid max-xl:grid-cols-1"
              >
                <PricingCard selectedIndex={selectedIndex} />

                {/* <section className="grid grid-cols-4 mt-4 w-full p-3 max-sm:hidden max-md:hidden max-lg:hidden max-xl:hidden  max-xxl:gap-5 max-xxl:h-fit">
                  {tabData.map((tab, index) => {
                    return (
                      <section key={index} className="w-fit">
                        <section className="flex flex-row gap-x-1 ">
                          <CheckIcon className="w-4 text-green-500" />
                          <p className="text-base text-miler_button_blue_500 font-medium">
                            {tab.title} 
                          </p>
                        </section>
                        <p className="w-[16rem] pl-5 pt-2 text-miler_text_neutral">
                          {tab.description}
                        </p>
                      </section>
                    );
                  })}
                </section> */}

                {/* <section className="hidden max-sm:grid max-sm:grid-cols-1 max-sm:place-items-center max-sm:mt-4 max-md:grid max-md:grid-cols-2 max-md:w-full max-md:mt-4 max-lg:grid max-lg:grid-cols-3 max-lg:w-full max-xl:grid max-xl:grid-cols-3 max-xl:gap-2 max-xl:p-2 max-xl:mt-4">
                  {tabData.map((tab, index) => {
                    return (
                      <section key={index} className="w-fit">
                        <section className="flex flex-row gap-x-1">
                          <CheckIcon className="w-4 text-green-500" />
                          <p className="text-base text-miler_button_blue_500 font-medium">
                            {tab.title}
                          </p>
                        </section>
                        <p className="w-[16rem] pl-5 pt-2 text-miler_text_neutral">
                          {tab.description}
                        </p>
                      </section>
                    );
                  })}
                </section> */}
              </Tab.Panel>
              <Tab.Panel
                as="section"
                className="w-full gap-x-3 max-sm:grid max-sm:grid-cols-1 max-sm:p-2  max-md:grid max-md:grid-cols-1 max-lg:grid max-lg:grid-cols-1 max-xl:grid max-xl:grid-cols-1"
              >
                <PricingCard selectedIndex={selectedIndex} />

                {/* <section className="grid grid-cols-4 mt-4 w-full p-3 max-sm:hidden max-md:hidden max-lg:hidden max-xl:hidden  max-xxl:gap-5 max-xxl:h-fit">
                  {tabData.map((tab, index) => {
                    return (
                      <section key={index} className="w-fit">
                        <section className="flex flex-row gap-x-1 ">
                          <CheckIcon className="w-4 text-green-500" />
                          <p className="text-base text-miler_button_blue_500 font-medium">
                            {tab.title}
                          </p>
                        </section>
                        <p className="w-[16rem] pl-5 pt-2 text-miler_text_neutral">
                          {tab.description}
                        </p>
                      </section>
                    );
                  })}
                </section> */}

                {/* <section className="hidden max-sm:grid max-sm:grid-cols-1 max-sm:place-items-center max-sm:mt-4 max-md:grid max-md:grid-cols-2 max-md:w-full max-md:mt-4 max-lg:grid max-lg:grid-cols-3 max-lg:w-full max-xl:grid max-xl:grid-cols-3 max-xl:gap-2 max-xl:p-2 max-xl:mt-4">
                  {tabData.map((tab, index) => {
                    return (
                      <section key={index} className="w-fit">
                        <section className="flex flex-row gap-x-1">
                          <CheckIcon className="w-4 text-green-500" />
                          <p className="text-base text-miler_button_blue_500 font-medium">
                            {tab.title}
                          </p>
                        </section>
                        <p className="w-[16rem] pl-5 pt-2 text-miler_text_neutral">
                          {tab.description}
                        </p>
                      </section>
                    );
                  })}
                </section> */}
              </Tab.Panel>
              <Tab.Panel
                as="section"
                className="w-full gap-x-3 max-sm:grid max-sm:grid-cols-1 max-sm:p-2  max-md:grid max-md:grid-cols-1 max-lg:grid max-lg:grid-cols-1 max-xl:grid max-xl:grid-cols-1"
              >
                <PricingCard selectedIndex={selectedIndex} />

                {/* <section className="grid grid-cols-4 mt-4 w-full p-3 max-sm:hidden max-md:hidden max-lg:hidden max-xl:hidden  max-xxl:gap-5 max-xxl:h-fit">
                  {tabData.map((tab, index) => {
                    return (
                      <section key={index} className="w-fit">
                        <section className="flex flex-row gap-x-1 ">
                          <CheckIcon className="w-4 text-green-500" />
                          <p className="text-base text-miler_button_blue_500 font-medium">
                            {tab.title}
                          </p>
                        </section>
                        <p className="w-[16rem] pl-5 pt-2 text-miler_text_neutral">
                          {tab.description}
                        </p>
                      </section>
                    );
                  })}
                </section> */}

                {/* <section className="hidden max-sm:grid max-sm:grid-cols-1 max-sm:place-items-center max-sm:mt-4 max-md:grid max-md:grid-cols-2 max-md:w-full max-md:mt-4 max-lg:grid max-lg:grid-cols-3 max-lg:w-full max-xl:grid max-xl:grid-cols-3 max-xl:gap-2 max-xl:p-2 max-xl:mt-4">
                  {tabData.map((tab, index) => {
                    return (
                      <section key={index} className="w-fit">
                        <section className="flex flex-row gap-x-1">
                          <CheckIcon className="w-4 text-green-500" />
                          <p className="text-base text-miler_button_blue_500 font-medium">
                            {tab.title}
                          </p>
                        </section>
                        <p className="w-[16rem] pl-5 pt-2 text-miler_text_neutral">
                          {tab.description}
                        </p>
                      </section>
                    );
                  })}
                </section> */}
              </Tab.Panel>
              <Tab.Panel
                as="section"
                className="w-full gap-x-3 max-sm:grid max-sm:grid-cols-1 max-sm:p-2  max-md:grid max-md:grid-cols-1 max-lg:grid max-lg:grid-cols-1 max-xl:grid max-xl:grid-cols-1"
              >
                <PricingCard selectedIndex={selectedIndex} />

                {/* <section className="grid grid-cols-4 mt-4 w-full p-3 max-sm:hidden max-md:hidden max-lg:hidden max-xl:hidden  max-xxl:gap-5 max-xxl:h-fit">
                  {tabData.map((tab, index) => {
                    return (
                      <section key={index} className="w-fit">
                        <section className="flex flex-row gap-x-1 ">
                          <CheckIcon className="w-4 text-green-500" />
                          <p className="text-base text-miler_button_blue_500 font-medium">
                            {tab.title}
                          </p>
                        </section>
                        <p className="w-[16rem] pl-5 pt-2 text-miler_text_neutral">
                          {tab.description}
                        </p>
                      </section>
                    );
                  })}
                </section> */}

                {/* <section className="hidden max-sm:grid max-sm:grid-cols-1 max-sm:place-items-center max-sm:mt-4 max-md:grid max-md:grid-cols-2 max-md:w-full max-md:mt-4 max-lg:grid max-lg:grid-cols-3 max-lg:w-full max-xl:grid max-xl:grid-cols-3 max-xl:gap-2 max-xl:p-2 max-xl:mt-4">
                  {tabData.map((tab, index) => {
                    return (
                      <section key={index} className="w-fit">
                        <section className="flex flex-row gap-x-1">
                          <CheckIcon className="w-4 text-green-500" />
                          <p className="text-base text-miler_button_blue_500 font-medium">
                            {tab.title}
                          </p>
                        </section>
                        <p className="w-[16rem] pl-5 pt-2 text-miler_text_neutral">
                          {tab.description}
                        </p>
                      </section>
                    );
                  })}
                </section> */}
              </Tab.Panel>
              {/* <Tab.Panel>Content 1</Tab.Panel>
          <Tab.Panel>Content 2</Tab.Panel> */}
            </Tab.Panels>
          )}
        </Tab.Group>
      </section>
    </section>
  );
};

export default TabSection;
