import { CheckIcon } from "@heroicons/react/20/solid";
import MilerLogo from "../../assets/logo/logo_one.svg";
import SamsaraLogo from "../../assets/intergration/Samsara_logo_primary_final.png";
import MilerButton from "../milerButton/MilerButton";
import MotiveLogo from "../../assets/intergration/motive-logo.svg";
import VerizonLogo from "../../assets/intergration/verizon-connect3355-removebg-preview.png";
import { useLocation, useNavigate } from "react-router-dom";

interface plans {
  title: string;
}
interface HeroSectionProps {
  title: string;
  subTitle?: string;
  subscriptionPlan: string;
  description: string;
  plansOptions: plans[];
  isIntergration?: boolean;
  registerTitle?: string;
  showRegisterBtn?: boolean;
  heroImage?: string;
}

const HeroPageSection = (props: HeroSectionProps) => {
  const {
    title,
    subTitle,
    subscriptionPlan,
    description,
    plansOptions,
    isIntergration = false,
    registerTitle,
    showRegisterBtn = true,
    heroImage,
  } = props;

  const slugParam = useLocation();

  const renderIntegrationImage = () => {
    if (slugParam.pathname === "/motive-eld") {
      return MotiveLogo;
    }
    if (slugParam.pathname === "/samsara-eld-tms") {
      return SamsaraLogo;
    }

    if (slugParam.pathname === "/verizon-eld-tms") {
      return VerizonLogo;
    }
  };
  const renderName = () => {
    if (slugParam.pathname === "/solutions/broker") {
      return "Brokers";
    }
    if (slugParam.pathname === "/solutions/provider") {
      return "Service Providers";
    }
    if (slugParam.pathname === "/solutions/shipper") {
      return "Shippers and 3PLs";
    }
  };

  const navigate = useNavigate();

  return (
    <section className="grid grid-cols-2 place-items-center bg-slate-50 p-[3.5rem]  gap-x-4  max-sm:grid max-sm:grid-cols-1 max-sm:p-[2rem] max-md:grid max-md:grid-cols-1 max-md:p-[2rem] max-lg:flex max-lg:flex-col">
      <section className="flex flex-col gap-y-3  ">
        <section className=" flex flex-col gap-y-3">
          {/* <h4 className="text-miler_yellow max-sm:w-full ">{"" || "Service Provider"}</h4> */}
          <p
            className={
              isIntergration
                ? "font-bold text-3xl text-miler_blue max-sm:w-full max-sm:text-[1.3rem] max-md:text-2xl"
                : "font-bold text-3xl text-miler_yellow max-sm:w-full max-sm:text-[1rem] max-md:text-2xl"
            }
          >
            {subscriptionPlan}{" "}
            <span className="font-bold text-3xl text-miler_blue max-sm:w-full max-sm:text-[1rem] max-md:text-2xl">
              {title}{" "}
              <span className="font-bold text-3xl text-miler_yellow max-sm:w-full max-sm:text-[1rem] max-md:text-2xl">
                {renderName()}
              </span>
            </span>
          </p>
          <p className="text-2xl font-semibold text-miler_blue max-sm:w-full max-sm:text-[1.3rem] max-md:text-2xl">
            {subTitle}
          </p>
          <p className="max-sm:w-full w-[80%] text-justify font-normal leading-8 tracking-normal max-sm:full max-md:w-full max-lg:w-full">
            {description}
          </p>
        </section>
        {plansOptions.map((plan, index) => {
          return (
            <section
              key={index}
              className="flex flex-row gap-x-3 max-sm:w-full"
            >
              {plan.title && <CheckIcon className="w-4 text-green-500" />}
              <p className="font-normal text-base">{plan.title}</p>
            </section>
          );
        })}
        {isIntergration ? (
          <></>
        ) : (
          <>
            {showRegisterBtn && (
              <section className=" flex flex-col gap-y-3">
                {registerTitle && (
                  <p className="text-base text-miler_blue font-semibold">
                    {`Are you a ${registerTitle}?`}
                  </p>
                )}

                <MilerButton
                  title="Get Started"
                  showIcon
                  size="large"
                  handleClick={() => {
                    navigate("/pricing", {
                      state: { productType: registerTitle   },
                  
                    });
                  }}
                />
              </section>
            )}
          </>
        )}
      </section>

      {isIntergration ? (
        <>
          <section className="flex flex-col py-10 lg:py-20  items-center justify-center space-y-10">
            <div className=" w-full flex flex-row space-x-3 lg:space-x-4 items-center justify-center max-sm:w-full">
              <div className="h-24 lg:h-20 w-48 bg-white  flex items-center justify-center border border-gray-300 rounded-full max-sm:w-full">
                <img
                  src={MilerLogo}
                  alt="Miler Logo"
                  className="object-contain h-full w-36  max-sm:w-28"
                />
              </div>

              <span className="text-2xl">+</span>
              <div className="h-24 lg:h-20 w-48 bg-white flex items-center justify-center border border-gray-300 rounded-full max-sm:w-full">
                <img
                  src={renderIntegrationImage()}
                  alt="Integration Logo"
                  className="object-contain h-full w-32 max-sm:w-24 max-sm:p-2"
                />
              </div>
            </div>
          </section>
        </>
      ) : (
        <section className="">
          {!showRegisterBtn ? (
            <></>
          ) : (
            <>
              {heroImage ? (
                <img
                  src={heroImage}
                  width={510}
                  height={250}
                  alt="carrier_image"
                  className="object-contain rounded-md max-sm:hidden"
                />
              ) : (
                <> </>
              )}
            </>
          )}
        </section>
      )}
    </section>
  );
};

export default HeroPageSection;
