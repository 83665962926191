import { useState } from "react";
import Footer from "../../footer/Footer";
import NavBar from "../../navBar/NavBar";
import Banner from "../../pagesSection/Banner";
import HeroPageSection from "../../pagesSection/HeroSection";
import MoreSection from "../../pagesSection/MoreSection";
import BrokerHeroImage from '../../../assets/images/Brokers Hero.png'
const SolutionBroker = () => {
  const [showSideBar, setShowSideBar] = useState(false);

  return (
    <>
      <section className="">
        <NavBar showSideBar={showSideBar} setShowSideBar={setShowSideBar} />
        {!showSideBar && (
          <>
            <section>
              <HeroPageSection
                title="Integrated TMS for"
                subscriptionPlan=""
                registerTitle="broker"
                description={
                  "Centralize your brokerage operations and data through one system. Build and grow your private network of carriers for increased communication, visibility and efficiency."
                }
                heroImage={BrokerHeroImage}
                plansOptions={[
                  {
                    title: "",
                  },
                  {
                    title: "",
                  },
                ]}
              />
            </section>

            <MoreSection
              type="right"
              title={`Broker TMS`}
              subtitle="Take your brokerage business to the next level"
              description={
                "Miler offers a fully-featured broker transportation management software (TMS) with shipment marketplace and carrier verification."
              }
              descriptionTwo=""
              planTitle=""
              planOptions={[
                {
                  title: "Shipment Marketplace",
                  description: [
                    "Submit RFQ and Tenders to preferred carriers in your own private network.",
                    "Cut down on calls and emails by 50%.",
                    "Transition your brokerage from a call center into well-orchestrated logistics hub.",
                  ],
                },
                {
                  title: "Integrated Dispatch",
                  description: [
                    "Work with your customers, carriers and drivers all through one system.",
                    "Minimize service failures and improve visibility for your customers.",
                    "Eliminate check calls and costly tracking services. Miler’s ELD integration gives you accurate locations and ETAs.",
                  ],
                },
                
              ]}
            />
            {/* <MoreSection
              type="left"
              title="Safety & Compliance"
              subtitle={
                "Comprehensive Solution for Carrier Safety and Compliance"
              }
              description={
                "With the right technology and leadership, your entire team can integrate safety and compliance into your organization's core business processes"
              }
              descriptionTwo={""}
              planTitle={""}
              planOptions={[
                {
                  title: "Keep documents, logs and reports, all in one place",
                  description: [
                    "Operate safely, stay compliant, and maintain organizational peace of mind.",
                    "Identify and mitigate risky drivers and unsafe equipments early and often.",
                    "Stand ready for any safety audit onsite or over the road.",
                    "Increase your safety score to lower your insurance and overall safety cost.",
                  ],
                },
                {
                  title: "Integrated with your TMS",
                  description: [
                    "Monitor and manage compliance and safety incidents.",
                    "Gain full visibility on safety issues, expirations, and violations.",
                    "Keep track of roadside inspections, their action items and remediations.",
                    "Track driver safety performance.",
                  ],
                },
              ]}
            /> */}
            <Banner title ="Ready to dive in?" />

            {/* <Section
              side="right"
              title="EDI Integration"
              mainTitle="Fully Managed and Seamless EDI Integration"
              description="Many enterprise shippers require EDI connectivity before partnering with carriers. Miler offers seamless EDI integration. Serve your customers better and gain competitive advantage with EDI connectivity."
              image={EDIImage}
            /> */}
            {/* <MoreSection
              type="left"
              title="Fleet Maintenance"
              subtitle="Integrated Fleet Maintenance"
              description="Miler’s Fleet Maintenance solution helps carriers drive efficiency, keep their costs down and increase their bottom line."
              descriptionTwo=""
              planTitle=""
              planOptions={[
                {
                  title: "Service Repairs",
                  description: [
                    "Connect all your maintenance dots in a single dashboard.",
                    "Manage service repairs (work orders).",
                    "Mark vehicles and trailers for repair shop to notify dispatch team.",
                  ],
                },
                {
                  title: "Service Reminders",
                  description: [
                    "Setup service tasks and assign them to fleet categories.",
                    "Create reminders for regular maintenance.",
                  ],
                },
                {
                  title: "Parts Inventory and Vendor Management",
                  description: [
                    "Manage parts and parts inventory.",
                    "Manage parts vendors.",
                  ],
                },
                {
                  title: "Purchase Order Management",
                  description: [
                    "Manage purchase orders and keep track of their balance sheet related to parts acquisition.",
                  ],
                },
              ]}
            /> */}
            <Footer />
          </>
        )}
      </section>
    </>
  );
};

export default SolutionBroker;
