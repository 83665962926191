interface ImageSectionProps {
  image: string;
  alt: string;
}

const ImageSection = (props: ImageSectionProps) => {
  const { image, alt } = props;
  return (
    <section className=" mt-4">
      <img src={image} className=" w-full object-contain" alt={alt} />
    </section>
  );
};

export default ImageSection;
