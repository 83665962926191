// sanityClient.js
import { createClient, type ClientConfig } from '@sanity/client' 
 const config: ClientConfig = {
  projectId: 'tdm4rhdw',     // Your Sanity project ID
  dataset: 'production',     // Your dataset name
  apiVersion: '2023-09-01',  // Use the current API version or your preferred version
  useCdn: true,              // `false` if you want to ensure fresh data
}
 
 export const client = createClient(config)
 
export default client