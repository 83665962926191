interface menuDetails {
  id: number;
  title: string;
  page: string;
  description: string;
}

export const customerNavInfo: menuDetails[] = [
  {
    id: 1,
    title: "Carriers",
    page: "carrier",
    description:
      "Source, plan and execute your freight or fleet capacity. Implement fleet safety or maintenance programs.",
  },
  {
    id: 2,
    title: "Brokers",
    page: "broker",
    description:
      "Effectively manage freight, tenders and carrier service performance.",
  },
  {
    id: 3,
    title: "Shippers",
    page: "shipper",
    description:
      "Manage relationships with your own private network. Gain visibilit and efficiency.",
  },
  {
    id: 4,
    title: "Service Providers",
    page: "provider",
    description:
      "Get connected to your fleet service customers and get paid immediately after service completion.",
  },
];

export const resourcesNavInfo: menuDetails[] = [
  {
    id: 1,
    title: "Blogs",
    page: "blogs",
    description:
      "",
  },
  {
    id: 2,
    title: "About us",
    page: "about-us",
    description:
      "",
  },
];
