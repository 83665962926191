import Card from "./card";

const WhySection = () => {
  return (
    <section className="max-sm:w-screen bg-slate-100">
      <section className="flex mt-2 flex-col justify-center items-center">
        <p className="mt-7 text-miler_blue text-3xl  text-center antialiased leading-10 font-extrabold tracking-tight max-sm:w-full max-sm:text-[1.3rem] max-md:text-2xl">
          Are you ready for where the freight industry is heading?
        </p>
        <p className="antialiased w-[40rem] text-center text-base leading-8 font-medium miler_black_color mt-3 max-sm:w-full max-sm:p-2 max-md:px-[3.5rem]">
          We’re here to help you stay ahead and compete with tomorrow&apos;s
          technology on your side.
        </p>
      </section>
      <Card />
    </section>
  );
};

export default WhySection;
