import { useNavigate } from "react-router-dom";
const ContentSection = () => {
  const navigate = useNavigate();
  return (
    <main className="py-5">
      <section className="flex flex-col justify-center items-center gap-y-8 mt-[2.5rem]">
        <p className="text-miler_blue font-bold text-3xl leading-9 text-center antialiased max-sm:w-full max-sm:text-[1.5rem] p-[0.9rem] max-md:text-2xl">
          Not sure which solution may be best for your business?
        </p>
        <button
          className="bg-miler_blue text-white font-medium py-2 px-6 rounded-full"
          onClick={() => {
            navigate("/request-demo");
          }}
        >
          Contact Us for Guidance
        </button>
      </section>
    </main>
  );
};

export default ContentSection;
