import clsx from "clsx";
import priceInfo, {
  brokerPricingPlan,
  shipperPricingPlan,
  serviceProviderPricingPlan,
} from "./data";
import { CheckIcon } from "@heroicons/react/20/solid";
import { useNavigate } from "react-router-dom";
interface PricinCardProps {
  selectedIndex?: number;
}
const PricingCard = (props: PricinCardProps) => {
  let { selectedIndex } = props;
  let planInfo;

  switch (selectedIndex) {
    case 1:
      planInfo = brokerPricingPlan;
      break;
    case 2:
      planInfo = shipperPricingPlan;
      break;
    case 3:
      planInfo = serviceProviderPricingPlan;
      break;
    default:
      planInfo = priceInfo;
      break;
  }

  const navigate = useNavigate();
  return (
    <section
      className={clsx(
        `${
          selectedIndex === 2 || selectedIndex === 3
            ? " flex flex-row mt-4 w-full justify-center items-center gap-x-8 m-auto max-sm:flex max-sm:flex-col  max-sm:gap-y-6 max-md:flex max-md:flex-col  max-md:gap-y-6"
            : "grid grid-cols-3 place-items-center gap-3 max-sm:flex max-sm:flex-col w-full max-md:grid max-md:grid-cols-1 max-md:gap-8 max-lg:grid max-lg:grid-cols-1 max-lg:gap-8"
        }`
      )}
    >
      {planInfo.map((price, index) => {
        return (
          <section
            key={index}
            className={
              selectedIndex === 2 || selectedIndex === 3
                ? "p-5 w-[20rem] h-full bg-white shadow-miler_pricing_card flex flex-col rounded-2xl justify-between gap-y-2 max-sm:h-fit max-sm:w-[20rem] max-md:w-[25rem] max-lg:w-[25rem] max-xl:w-[28rem] max-xxl:w-[28rem]"
                : "p-5 w-[20rem] h-full bg-white shadow-miler_pricing_card flex flex-col rounded-2xl justify-between gap-y-2 max-sm:h-fit max-md:w-[25rem] max-lg:w-[25rem] max-xl:w-[90%] max-xxl:w-[90%]"
            }
          >
            <section>
              <section className="flex flex-col justify-center items-center p-2 gap-y-1 mt-4">
                <p className="bg-miler_secondary_yellow_color font-semibold rounded-3xl w-[9rem] text-center">
                  {price.subscription}
                </p>
                <p className=" mt-2 leading-6 text-sm text-left">
                  {price.subscriptionType}
                </p>
              </section>
              <section className="flex flex-col justify-center items-center ">
                <p className="text-3xl font-extrabold">
                  {price.price} {""}{" "}
                  <span className="text-xl font-extrabold">
                    {selectedIndex === 3 ? "" : "/month"}
                  </span>
                </p>
                <p>{price.priceTye}</p>
              </section>
              <section className="ml-5 flex flex-col gap-y-3 mt-3">
                {price.subscriptionList.map((list, index) => {
                  return (
                    <section key={index} className="flex flex-row gap-x-1">
                      <CheckIcon className="w-4 text-green-500" />
                      <p
                        className={clsx(
                          " text-base",
                          price.subscription.includes("Miler Unify") &&
                            (index === 0 || index === 1) &&
                            "font-bold"
                        )}
                      >
                        {list.title}
                      </p>
                    </section>
                  );
                })}
              </section>
            </section>
            <section className="w-full flex flex-col justify-center items-center mb-4">
              <button
                className="bg-miler_button_blue_500 text-white w-[90%] text-center p-2 rounded-md"
                onClick={() => {
                  navigate("/registration", {
                    // state: { subscription: price.subscription, subscriptionSolution: price.subscriptionSolution },
                    state: { product: price.product, plan: price.plan },
                  });
                }}
              >
                Get Started
              </button>
            </section>
          </section>
        );
      })}
    </section>
  );
};

export default PricingCard;
