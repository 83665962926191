interface CustomFormData {
  id: number;
  label: string;
  name: string
  placeHolderText: string;
}

const formData: CustomFormData[] = [
  {
    id: 1,
    label: "Fleet Size",
    placeHolderText: "Fleet Size",
    name: "company"
  },
//   {
//     id: 2,
//     label: "DOT Number",
//     placeHolderText: "Dot Number",
//     name:"dotNumber",
//   },
//   {
//     id: 3,
//     label: "Power Units",
//     placeHolderText: "Power Units",
//     name: "powerUnits"
//   },
//   {
//     id: 4,
//     label: "Address Line",
//     placeHolderText: "Address Line",
//     name: "addressLine"
//   },
//   {
//     id: 5,
//     label: "City",
//     placeHolderText: "City",
//     name: "City"
//   },
//   {
//     id: 6,
//     label: "State",
//     placeHolderText: "State",
//     name: "state"
//   },
  
//   {
//     id: 7,
//     label: "Postal Code",
//     placeHolderText: "Postal Code",
//     name: "postalCode"
//   },
//   {
//     id: 8,
//     label: "Country",
//     placeHolderText: "Country",
//     name: "country"
//   },
];

export default formData;
