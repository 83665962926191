import { PhoneIcon } from "@heroicons/react/20/solid";
import { EnvelopeIcon } from "@heroicons/react/20/solid";
const ContactForm = () => {
  return (
    <section className="flex flex-row justify-center max-sm:hidden max-md:hidden  h-full py-10">
      <section className=" bg-miler_blue text-white p-12 w-[27rem] flex flex-col gap-y-4 max-lg:w-fit">
        <p className=" text-base font-semibold">Contact information</p>
        <p className="w-[21rem] font-extralight">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Iure non mp
          inventore eum nemo excepturi quis quibusdam labore perferendis dolore
          eligendi? martinnmwend ais th eking martin mwedsi martin mwendamis the
        </p>
        <section className="flex flex-row gap-x-2">
          <PhoneIcon className=" w-4" />
          <p>+1 (555) 123-4567</p>
        </section>
        <section className=" flex flex-row gap-x-2">
          <EnvelopeIcon className=" w-4" />
          <p>support@miler.com</p>
        </section>

        <section className="flex flex-row gap-x-3">
          <PhoneIcon className=" w-4" />
          <EnvelopeIcon className=" w-4" />
          <PhoneIcon className=" w-4" />
        </section>
      </section>

      <section className="bg-white w-[50rem] p-12 flex flex-col gap-y-4 max-sm:w-screen max-lg:w-full max-xl:w-full">
        <p>Send us a Message</p>
        <form className="grid grid-cols-2 gap-2 max-lg:grid-cols-1 max-lg:w-full">
          <></>
          <section className="w-[21rem] max-sm:w-full max-lg:w-full max-xl:w-full">
            <p>First Name</p>
            <input
              type="text"
              className="w-full border-miler_text_input rounded-md border-2 border-solid border-miler_text_input_color"
            />
          </section>
          <section className="w-[21rem] max-sm:w-full max-lg:w-full max-xl:w-full">
            <p>Last Name</p>
            <input
              type="text"
              className="w-full rounded-md border-2 border-solid border-miler_text_input_color"
            />
          </section>
          <section className="w-[21rem] max-sm:w-full max-lg:w-full max-xl:w-full">
            <p>Email</p>
            <input
              type="text"
              className="w-full rounded-md border-2 border-solid border-miler_text_input_color"
            />
          </section>
          <section className="w-[21rem] max-sm:w-full max-lg:w-full max-xl:w-full">
            <p>Phone</p>
            <input
              type="text"
              className="w-full rounded-md border-2 border-solid border-miler_text_input_color"
            />
          </section>
          <section className="w-[21rem] max-sm:w-full max-lg:w-full max-xl:w-full">
            <p>Company Name</p>
            <input
              type="text"
              className="w-full rounded-md border-2 border-solid border-miler_text_input_color"
            />
          </section>
          <section className="w-[21rem] max-sm:w-full max-lg:w-full max-xl:w-full">
            <p>Industry</p>
            <select className="w-full rounded-md border-2 border-solid border-miler_text_input_color">
              <option>Broker</option>
              <option>Carrier</option>
              <option>Provider</option>
            </select>
          </section>
        </form>
        <section>
          <p>Subject</p>
          <input
            type="text"
            className=" w-full rounded-md border-2 border-solid border-miler_text_input_color"
          />
        </section>
        <section>
          <p>Message</p>
          <input
            type="textarea"
            className="w-full p-4 rounded-md border-2 border-solid border-miler_text_input_color"
          />
        </section>
      </section>
    </section>
  );
};

export default ContactForm;
