import { useState } from "react";
import Footer from "../../components/footer/Footer";
import NavBar from "../../components/navBar/NavBar";
import Privacy from "../../components/privacy/privacy";
const PrivacyPage = () => {
  const [showSideBar, setShowSideBar] = useState(false);
  return (
    <section>
      <NavBar showSideBar={showSideBar} setShowSideBar={setShowSideBar} />
      <Privacy />
      <Footer />
    </section>
  );
};

export default PrivacyPage;
