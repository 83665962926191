import { useState } from "react";
import ContentSection from "../../components/contentSection/content";
import Footer from "../../components/footer/Footer";
import HeroSection from "../../components/heroSection/HeroSection";
import Integration from "../../components/intergration_v2/Intergration";
import NavBar from "../../components/navBar/NavBar";
import SolutionSection from "../../components/solutionsSection/Solution";
import WhySection from "../../components/whySection/WhySection";
import Section from "../../components/pagesSection/Section";
import EDIImage from "../../assets/images/EDI_Grid.png";
import MacBookProImage from "../../assets/images/Home_IMG_1.png";
function HomePage() {
  const [showSideBar, setShowSideBar] = useState(false);

  return (
    <div className="overflow-x-hidden">
      <NavBar showSideBar={showSideBar} setShowSideBar={setShowSideBar} />
      {!showSideBar && (
        <>
          <HeroSection
            title=" Your fully connected"
            subTitle="Digital Freight Ecosystem"
            description="Harness the power of a fully connected freight platform. Gain operational efficiency and visibility. Build and grow lasting relationships with your own private network of shippers, brokers, carriers or heavy-duty service providers."
            isHomePage
            image={MacBookProImage}
          />
          <SolutionSection />
          <Integration />
          <WhySection />
          <Section
            side="right"
            title="EDI Integration"
            mainTitle="Fully Managed and Seamless EDI Integration"
            description="Miler offers seamless cloud-based EDI solution for your external TMS or ERP connectivity."
            image={EDIImage}
          />
          <ContentSection />
          <Footer />
        </>
      )}
    </div>
  );
}

export default HomePage;
