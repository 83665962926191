import { useState } from "react";
import Footer from "../../footer/Footer";
import NavBar from "../../navBar/NavBar";
import Banner from "../../pagesSection/Banner";
import HeroPageSection from "../../pagesSection/HeroSection";
import MoreSection from "../../pagesSection/MoreSection";
import Section from "../../pagesSection/Section";
import EDIImage from "../../../assets/images/EDI_Grids.svg";
import carrierImage from "../../../assets/images/carrierInfo.svg";
import CarrierOnBoardImage from "../../../assets/images/Ideal Customers.svg";
import ImageSection from "../../imageSection/ImageSection";
import ProviderImage from "../../../assets/images/miler_licensed_image_truck_repair.webp";

const SolutionCarrier = () => {
  const [showSideBar, setShowSideBar] = useState(false);

  return (
    <>
      <section className="">
        <NavBar showSideBar={showSideBar} setShowSideBar={setShowSideBar} />
        {!showSideBar && (
          <>
            <section>
              <HeroPageSection
                title="Management Software"
                subscriptionPlan={"Carrier"}
                registerTitle="carrier"
                heroImage={carrierImage}
                description={
                  "Take your business to the cloud with performance-optimized dispatch, fleet management, accounting, safety & compliance, and fleet maintenance. Experience an all-in-one cloud-based TMS that will give you an edge in every aspect of your logistics and business operations."
                }
                plansOptions={[
                  {
                    title: "",
                  },
                  {
                    title: "",
                  },
                ]}
              />
            </section>

            <MoreSection
              type="right"
              title={`Carrier TMS`}
              subtitle={
                "Procure, plan and execute freight with fully integrated Transportation Management System"
              }
              description={
                "Miler is the leading provider of integrated carrier transportation management software (TMS) and carrier compliance management solutions"
              }
              descriptionTwo={
                "We combined best-in-class technology and industry expertise into one carrier management software to help you grow safely and profitably."
              }
              planTitle={
                "Connect your tools. Coordinate with your vendors and customers. Operate with clarity"
              }
              planOptions={[
                {
                  title: "Task-Driven Dispatch Systems",
                  description: [
                    "Leverage task-driven freight activity workflows and software automation to reduce repeatable tasks and increase operational focus.",
                    "Get 360-degree view and up-to-the-minute status of your shipments and fleet locations.",
                    "Empower your team to collaborate efficiently, reduce workload and increase revenue.",
                  ],
                },
                {
                  title: "Driver Mobile App",
                  description: [
                    "Allow your drivers to self-dispatch and self-report milestones.",
                    " Improve your OTP performance, ETA visibility, and customer communications.",
                    "Free up more time for your dispatch team chasing status and paperwork.",
                  ],
                },
                {
                  title: "Automated Billing & Settlement",
                  description: [
                    "Configure your driver pay, fuel card provider and factoring companies, and let the system do the heavy lifting for you and your team",
                    "Improve cash flow with automated billing",
                    "Avoid lost revenue due to inaccuracies and oversight.",
                  ],
                },
              ]}
            />
            <MoreSection
              type="left"
              title="Safety & Compliance"
              subtitle={
                "Comprehensive Solution for Carrier Safety and Compliance"
              }
              description={
                "With the right technology and leadership, your entire team can integrate safety and compliance into your organization's core business processes"
              }
              descriptionTwo={""}
              planTitle={""}
              planOptions={[
                {
                  title: "Keep documents, logs and reports, all in one place",
                  description: [
                    "Operate safely, stay compliant, and maintain organizational peace of mind.",
                    "Identify and mitigate risky drivers and unsafe equipments early and often.",
                    "Stand ready for any safety audit onsite or over the road.",
                    "Increase your safety score to lower your insurance and overall safety cost.",
                  ],
                },
                {
                  title: "Integrated with your TMS",
                  description: [
                    "Monitor and manage compliance and safety incidents.",
                    "Gain full visibility on safety issues, expirations, and violations.",
                    "Keep track of roadside inspections, their action items and remediations.",
                    "Track driver safety performance.",
                  ],
                },
              ]}
            />
            <ImageSection image={CarrierOnBoardImage} alt="customer_carrier" />
            <Section
              side="right"
              title="EDI Integration"
              mainTitle="Fully Managed and Seamless EDI Integration"
              description="Many enterprise shippers require EDI connectivity before partnering with carriers. Miler offers seamless EDI integration. Serve your customers better and gain competitive advantage with EDI connectivity."
              image={EDIImage}
            />
            <MoreSection
              type="left"
              title="Fleet Maintenance"
              subtitle="Integrated Fleet Maintenance"
              description="Miler’s Fleet Maintenance solution helps carriers drive efficiency, keep their costs down and increase their bottom line."
              descriptionTwo=""
              planTitle=""
              image={ProviderImage}
              planOptions={[
                {
                  title: "Service Repairs",
                  description: [
                    "Connect all the dots for your fleet maintenance in a single dashboard.",
                    "Manage service repairs (work orders).",
                    "Mark vehicles and trailers for repair shop to notify dispatch team.",
                  ],
                },
                {
                  title: "Service Reminders",
                  description: [
                    "Setup service tasks and assign them to fleet categories.",
                    "Create reminders for regular maintenance.",
                  ],
                },
                {
                  title: "Parts Inventory and Vendor Management",
                  description: [
                    "Manage parts and parts inventory.",
                    "Manage parts vendors.",
                  ],
                },
                {
                  title: "Purchase Order Management",
                  description: [
                    "Manage purchase orders and keep track of their balance sheet related to parts acquisition.",
                  ],
                },
              ]}
            />
            <Banner />
            <Footer />
          </>
        )}
      </section>
    </>
  );
};

export default SolutionCarrier;
