interface CardData {
  id: number;
  title: string;
  description: string;
}

const cardData: CardData[] = [
  {
    id: 1,
    title: "Operational Efficiency",
    description:
      "The biggest differentiator for success in the freight industry is a company’s operational efficiency that helps cut down unnecessary costs while increasing revenue. Miler’s fully integrated digital freight platform is designed to help you leverage operational efficiency to save you and your customers time and money.",
  },
  {
    id: 2,
    title: "Support",
    description:
      "With Miler, you get much more than a software solution. Our implementation engineers are ready to assist with data imports and with initial setup for new accounts. Our product support team offers our customers live or offline assistance. Our product and engineering teams are always listening to our customer needs and continuously improving the system.",
  },

  {
    id: 3,
    title: "360-Degree Visibility",
    description:
      "Miler's fully integrated digital freight platform is uniquely positioned to provide you the visibility and the operational awareness you need to stay ahead of operational bottlenecks and safety issues. Lead a performance-based organization and monitor your logistics operations, readiness, resource availability, safety and service performance.",
  },
  {
    id: 4,
    title: "Save Time and Money",
    description:
      "Our team has hundreds of years of combined experience in both tech and the freight industry, making them uniquely qualified to bring innovative solutions to many of the pressing challenges facing the freight industry. At Miler, you will find a team that are not strangers to your business operations.",
  },
];

export default cardData;
