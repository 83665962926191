import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/20/solid";
import { ArrowRightCircleIcon } from "@heroicons/react/24/outline";

import clsx from "clsx";

import { customerNavInfo, resourcesNavInfo } from "./data";
interface DropDownOptions {
  id: number;
  title: string;
  subTitle?: string;
  page: string;
}
interface MenuProps {
  title: string;
  type: "solution" | "resources" | "signin" | "mobile";
  dropDownOptions?: DropDownOptions[];
}
export default function Example(props: MenuProps) {
  const { title, type, dropDownOptions } = props;

  const [showDropDownOptions, setShowDropDownOptions] = useState(false);
  const [isMenuSelected, setMenuSelected] = useState(false);

  // Click outside detection
  const menuRef = useRef<HTMLDivElement | null>(null);

  // Click outside detection
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setMenuSelected(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuRef]);

  return (
    <div className="">
      {type === "solution" && (
        <Menu
          as="div"
          className="relative max-sm:hidden max-md:hidden max-lg:hidden font-medium miler_black_color text-[1.25rem]"
        >
          <div>
            <Menu.Button className="flex flex-row justify-center items-center">
              {title || " Options"}
              <ChevronDownIcon
                className="h-5 w-5 text-miler_text_color hover:text-violet-100"
                aria-hidden="true"
              />
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="shadow-xl drop-shadow-md rounded-md  absolute w-[52rem] bg-white mt-2">
              <div className="grid grid-cols-2 p-4 ">
                {customerNavInfo.map((customerNav, index) => {
                  return (
                    <React.Fragment key={index}>
                      <Menu.Item as="section">
                        {({ active }) => (
                          <button
                            className={clsx(
                              "flex flex-col hover:bg-miler_nav_bar hover:rounded",
                              {
                                "text-gray-900": active,
                              }
                            )}
                          >
                            <section className="flex flex-row p-2 gap-x-2">
                              <Link
                                to={
                                  customerNav.page.startsWith("customers/")
                                    ? `/${customerNav.page}`
                                    : `/solutions/${customerNav.page}`
                                }
                                className="flex flex-col  items-start gap-y-1"
                              >
                                <section className="flex flex-row items-center gap-x-2">
                                  {/* <Image
                                    src={BoltIon}
                                    width={40}
                                    height={10}
                                    alt="company_icon"
                                    className=""
                                  /> */}
                                  <p className="text-miler_blue font-medium text-base">
                                    {customerNav.title}
                                  </p>
                                </section>

                                <p className="text-sm text-left w-full text-miler_text_neutral">
                                  {customerNav.description}
                                </p>
                              </Link>
                            </section>
                          </button>
                        )}
                      </Menu.Item>
                    </React.Fragment>
                  );
                })}
              </div>
              <Menu.Item
                as="section"
                className="flex flex-col bg-miler_grey w-full h-[6rem] justify-center gap-y-1 "
              >
                <p className="ml-5 text-base text-miler_blue">Contact Us</p>
                <section className="flex flex-row justify-between">
                  <p className="ml-5 text-sm text-miler_text_neutral">
                    Get in touch with us and gain a better understanding of
                    Miler platform.
                  </p>
                  <Link to="/request-demo">
                    <p className="text-blue-900 text-sm mr-7 cursor-pointer">
                      Contact
                    </p>
                  </Link>
                </section>
              </Menu.Item>
            </Menu.Items>
          </Transition>
        </Menu>
      )}

      {type === "resources" && (
        <Menu
          as="div"
          className="relative max-sm:hidden max-md:hidden max-lg:hidden font-medium miler_black_color text-[1.25rem]"
        >
          <div>
            <Menu.Button className="flex flex-row justify-center items-center">
              {title || " Options"}
              <ChevronDownIcon
                className="h-5 w-5 text-miler_text_color"
                aria-hidden="true"
              />
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="shadow-xl drop-shadow-md rounded-md  absolute w-[20rem] bg-white mt-2">
              <div className="flex flex-col">
                {resourcesNavInfo.map((resourceNav, index) => {
                  return (
                    <React.Fragment key={index}>
                      <Menu.Item as="section">
                        {({ active }) => (
                          <button
                            className={clsx("flex flex-col w-[18rem] ", {
                              "text-gray-900": active,
                            })}
                          >
                            <section className="flex flex-row p-2 gap-x-2 w-[18rem]">
                              <Link
                                to={`/${resourceNav.page}`}
                                className="flex flex-col  items-start gap-y-1 hover:bg-miler_nav_bar hover:rounded p-2"
                              >
                                <section className="flex flex-row items-center gap-x-2">
                                  <p className="text-miler_blue font-medium">
                                    {resourceNav.title}
                                  </p>
                                </section>
                                <p className="text-sm text-left text-miler_text_neutral w-[18rem]">
                                  {resourceNav.description}
                                </p>
                              </Link>
                            </section>
                          </button>
                        )}
                      </Menu.Item>
                    </React.Fragment>
                  );
                })}
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      )}

      {type === "signin" && (
        <Menu
          as="div"
          className="relative max-sm:hidden max-md:hidden max-lg:hidden font-medium miler_black_color "
        >
          <div
            className={clsx(
              "border border-gray-500 border-solid text-[0.85rem] px-3 py-1 rounded flexx flex-col",
              {
                " bg-gray-600 text-white": isMenuSelected,
              }
            )}
          ref={menuRef}

            onClick={()=>setMenuSelected(true)}
          >
            <Menu.Button className="flex flex-row justify-center items-center text-center ">
              {title || " Options"}
              <ChevronDownIcon
                className={clsx("h-5 w-3 text-miler_text_color", {
                  "text-white": isMenuSelected,
                })}
                aria-hidden="true"
              />
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="shadow-xl w-[13rem] drop-shadow-md rounded-md  absolute bg-white mt-3 left-1">
              <div className="flex flex-col">
                {dropDownOptions?.map((resourceNav, index) => {
                  return (
                    <React.Fragment key={index}>
                      <Menu.Item as="section">
                        {({ active }) => (
                          <button
                            className={clsx("flex flex-col w-full ", {
                              "text-gray-900 w-full": active,
                            })}
                          >
                            <section className="flex flex-row p-2 gap-x-2 w-full">
                              <section className="flex flex-col w-full justify-center items-center gap-y-1 hover:w-full ">
                                <section className="flex flex-row items-center gap-x-2">
                                  {/* <img
                                    src={BoltIon}
                                    width={40}
                                    height={10}
                                    alt="company_icon"
                                    className=""
                                  /> */}
                                  <p className="text-miler_blue font-semibold text-sm">
                                    {resourceNav.title}
                                  </p>
                                </section>
                              </section>
                            </section>

                            <Link
                              to={resourceNav.page}
                              className={clsx(
                                "flex flex-col justify-center items-center w-full p-2 "
                              )}
                            >
                              <section className=" flex flex-row border border-gray-500 border-solid text-[0.85rem] p-2 rounded hover:bg-miler_nav_bar hover:rounded gap-x-1">
                                <p className=" ">{resourceNav.subTitle}</p>
                                <ArrowRightCircleIcon className=" w-[1rem]" />
                              </section>
                            </Link>
                            <p className="border-b border-gray-200 border-solid w-full"></p>
                          </button>
                        )}
                      </Menu.Item>
                    </React.Fragment>
                  );
                })}
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      )}
      {type === "mobile" && (
        <section className="w-screen">
          <section
            className={clsx(" flex flex-row justify-between", {
              "bg-miler_nav_bar w-screen h-[4rem] flex flex-row justify-between items-center":
                showDropDownOptions,
            })}
          >
            <p
              className={clsx(
                "font-semibold text-miler_blue antialiased text-xl ",
                {
                  "ml-2": showDropDownOptions,
                }
              )}
            >
              {title}
            </p>
            {showDropDownOptions ? (
              <ChevronUpIcon
                className={clsx("w-6 mr-3")}
                onClick={() => setShowDropDownOptions(false)}
              />
            ) : (
              <ChevronDownIcon
                className="w-6 mr-3"
                onClick={() => setShowDropDownOptions(true)}
              />
            )}
          </section>
          {showDropDownOptions ? (
            <section className="">
              {dropDownOptions?.map((options, index) => {
                return (
                  <section
                    key={index}
                    className={clsx(
                      "flex flex-col gap-y-3  p-2 cursor-pointer"
                    )}
                  >
                    <Link to={options.page} className="text-base antialiased">
                      {options.title}
                    </Link>
                  </section>
                );
              })}
            </section>
          ) : (
            <></>
          )}
        </section>
      )}
    </div>
  );
}
