import { FormFields } from "../../pages/getStarted/getStarted";
import getProductSolutionName from "../../utils/getProductSolutionName";
interface MilerPlanProps {
  plan: string;
  product: string;
  companyDetails: FormFields;
}

const MilerPlan = (props: MilerPlanProps) => {
  const { product, plan, companyDetails } = props;

  const { company, fleet, state, city, addressLine, postalCode } =
    companyDetails?.companyInfo;

  const renderMilerPlans = () => {
    switch (product) {
      case "a2f078838857b1df381599a5560baaf72d1433f07e0c1f74ad482bf477cf719e":
        return (
          <iframe
            src={`https://subscription.miler.com/subscribe/${product}/${plan}?company_name=${company}&cf_dot=${companyDetails.dotNumber}&quantity=${fleet}&billing_street=${addressLine}&billing_city=${city}&billing_zip=${postalCode}&billing_state=${state}`}
            width="100%"
            height="100%"
            title="Miler Registration"
          ></iframe>
        );

      case "4f09941115733a4e7609132dccc9891dcbf7b178e6af0857494e2c5ddd3f0051":
        return (
          <iframe
            src={`https://subscription.miler.com/subscribe/${product}/${plan}?company_name=${company}&cf_dot=${companyDetails.dotNumber}&billing_street=${addressLine}&billing_city=${city}&billing_zip=${postalCode}&billing_state=${state}`}
            width="100%"
            height="100%"
            title="Miler Registration"
          ></iframe>
        );

      case "4f09941115733a4e7609132dccc9891d64ca422e9690a35fb7d3f8995def816b":
        return (
          <iframe
            src={`https://subscription.miler.com/subscribe/${product}/${plan}?company_name=${company}&cf_dot=${companyDetails.dotNumber}&billing_street=${addressLine}&billing_city=${city}&billing_zip=${postalCode}&billing_state=${state}`}
            width="100%"
            height="100%"
            title="Miler Registration"
          ></iframe>
        );
      case "4f09941115733a4e7609132dccc9891d398d23d2f73003bf027cc62133d9423d":
        return (
          <iframe
            src={`https://subscription.miler.com/subscribe/${product}/${plan}?company_name=${company}&cf_dot=${companyDetails.dotNumber}&billing_street=${addressLine}&billing_city=${city}&billing_zip=${postalCode}&billing_state=${state}`}
            width="100%"
            height="100%"
            title="Miler Registration"
          ></iframe>
        );

      case "4f09941115733a4e7609132dccc9891d938a4aa64b216cc08e20721c6c09c65f":
        return (
          <iframe
            src={`https://subscription.miler.com/subscribe/${product}/${plan}`}
            width="100%"
            height="100%"
            title="Miler Registration"
          ></iframe>
        );
      case "4f09941115733a4e7609132dccc9891da6018b5446c1e5f3a3736e575b0b28dd":
        return (
          <iframe
            src={`https://subscription.miler.com/subscribe/${product}/${plan}`}
            width="100%"
            height="100%"
            title="Miler Registration"
          ></iframe>
        );
      case "4f09941115733a4e7609132dccc9891de191e0d78598307f897808dcdcab122b":
        return (
          <iframe
            src={`https://subscription.miler.com/subscribe/${product}/${plan}`}
            width="100%"
            height="100%"
            title="Miler Registration"
          ></iframe>
        );
      default:
        return <div></div>;
    }
  };

  return (
    <main className=" ">
      <section className=" w-full flex flex-row ">
        <section className="w-[40%] bg-miler_blue h-screen flex flex-col items-center justify-center gap-y-7">
          <p className="text-[2rem] text-white">
            {`Setting up your ${getProductSolutionName(product)} account`}
          </p>
        </section>
        <section className=" w-[60%]  flex flex-col">
          <section className="h-screen overflow-hidden w-full">
            {renderMilerPlans()}
          </section>
        </section>
      </section>
    </main>
  );
};

export default MilerPlan;
