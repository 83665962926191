const Alert = () => {
  return (
    <section className="border border-red-400  px-4 py-3 rounded bg-red-100 relative">
      <div
        className=" "
        role="alert"
      >
        <strong className="font-bold text-sm">
          {" "}
          Unable to complete business verification
        </strong>
      </div>
      <section>
        <p className=" text-sm">
          We are unable to verify your carrier details. You may enter a valid
          DOT or contact us for assistance.
        </p>
      </section>
    </section>
  );
};

export default Alert;
