/* eslint-disable react/no-unescaped-entities */
import { Link } from "react-router-dom";
import "../../index.css";
const ProviderTerms = () => {
  return (
    <main className="privacy_page">
      <section className="p-[5rem] font-[400] max-sm:p-[1rem] max-md:p-[1.5rem]">
        <h1 className="font-[500] text-4xl text-center max-sm:text-xl max-md:text-2xl">
        SERVICE MARKETPLACE PROVIDER TERMS OF SERVICE
        </h1>
        <section className="mt-3 text-[1rem]">
          Last Updated: July 17, 2022
        </section>
        <section className="text-[1.125rem] leading-8 font-[400] text-black">
          <p className="mt-4">
          These Terms of Service constitute a legally binding agreement (the “Agreement”) between you and Miler, LLC, a Delaware limited liability company, and its parents, subsidiaries, representatives, affiliates, officers and directors (collectively, “Miler,” “we,” “us” or “our”) governing your use of websites, mobile applications, content, products and services (collectively, the “Miler Software”).
          </p>
          <p className="mt-3">
          PLEASE BE ADVISED: THIS AGREEMENT CONTAINS PROVISIONS THAT GOVERN HOW CLAIMS BETWEEN YOU AND MILER CAN BE BROUGHT (SEE SECTION 14 BELOW). THESE PROVISIONS WILL, WITH LIMITED EXCEPTION, REQUIRE YOU TO SUBMIT CLAIMS YOU HAVE AGAINST MILER TO BINDING AND FINAL ARBITRATION ON AN INDIVIDUAL BASIS, NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY CLASS, GROUP OR REPRESENTATIVE ACTION OR PROCEEDING.
          </p>
          <p className="mt-3">
          By entering into this Agreement, and/or by using or accessing the Services you expressly acknowledge that you understand this Agreement (including the dispute resolution and arbitration provisions in Section 14) and accept all of its terms. IF YOU DO NOT AGREE TO BE BOUND BY THE TERMS AND CONDITIONS OF THIS AGREEMENT, YOU MAY NOT USE OR ACCESS THE MILER SOFTWARE.
          </p>
          <h4 className="mt-3">1. THE SERVICE MARKETPLACE</h4>
          <p className="p-2 ml-5">
          The “Service Marketplace” is a marketplace available through the Miler Software where, among other things, persons who seek service providers to perform services and/or provide goods necessary or desirable to support motor carrier operations (a “Customer”) can be matched with you, persons who provide such services or goods, (hereinafter “You,” “you,” or “Provider”). You and Customer are collectively referred to herein as “Users,” and the services and goods provided by You to Customers shall be referred to herein as the “Service Marketplace Services.” As a Provider, you authorize Miler to match you with Customers based on factors such as your location, the requested service location, the estimated time needed by Customer to complete the Service, the estimated time needed for You to complete the Service, User preferences, and platform efficiency, and to cancel an existing match and/or rematch you with a Customer based on the same considerations. Any decision by a User to offer or accept Service Marketplace Services is a decision made in such User’s sole discretion. Each Service provided by a Provider to a Customer shall constitute a separate agreement between such persons.
          </p>
          <h4 className="mt-3">2. MODIFICATION TO THE AGREEMENT</h4>
          <p className="p-2 ml-5">
          Miler reserves the right to modify the terms and conditions of this Agreement, and such modifications shall be binding on you only upon your acceptance of the modified Agreement. Miler reserves the right to modify any information on pages referenced in the hyperlinks from this Agreement from time to time, and such modifications shall become effective upon posting. Continued use of the Services after any such changes shall constitute your consent to such changes. Unless material changes are made to the arbitration provisions herein, you agree that modification of this Agreement does not create a renewed opportunity to opt out of arbitration (if applicable).
          </p>
          <h4 className="mt-3">3. ELIGIBILITY</h4>
          <p className="p-2 ml-5">
          The Service Marketplace may only be used by individuals or entities who have the right and authority to enter into this Agreement and are fully able and competent to satisfy the terms, conditions, and obligations herein. The Service Marketplace is not available to Users who have had their User account temporarily or permanently deactivated. If the User is an organization, multiple persons may be permitted to use the account if the User authorizes such use. You may not allow non-authorized persons to use your User account. To use the Service Marketplace, each User shall create a User account. Miler reserves the right to deactivate any additional or duplicate accounts. Your participation in certain Miler programs and use of certain Miler products or services may be subject to additional eligibility requirements as determined by Miler.
          </p>
          <p className="p-2 ml-5">
          By becoming a User, if you are an individual, you represent and warrant that you are at least 18 years old and if you are an organization, you represent and warrant that you are authorized on behalf of the organization to enter into this Agreement.
          </p>
          <h4 className="mt-3">4. PAYMENTS TO PROVIDER</h4>
          <div className="p-2 ml-5">
          <ol className="terms_page">
            <li><u>Provider Payments.</u> You are entitled to payment for Service Marketplace Services you perform for Customers.  The amount of the payment you will receive shall be the contractual amount agreed upon between you and Customer, less the fees due to Miler as further described in Section 4(d) of this Agreement.  Your right to payment shall occur immediately upon completion of the Service Marketplace Services and the uploading to the Service Marketplace of an invoice reflecting the sums due and owing to you for the performance of the Service Marketplace Services.</li>

            <li><u>Payment Collection, Adjustments and Settlement.</u> Miler will collect payments owed to you by Customers and other third parties as your limited payment collection agent and you agree that the receipt of such payments by Miler satisfies the Customer’s obligation to you.  Miler reserves the right to adjust or withhold all or a portion of the payment owed to you (i) if Miler believes that you have attempted to defraud or abuse Customers, Miler, or Miler’s payment systems, (ii) in order to resolve a Customer complaint, or (iii) if Miler believes that you did not complete the Service Marketplace Services, or did not complete the services in a manner customarily acceptable and commercially reasonable within your industry.  Miler’s decision to adjust or withhold payment in any way shall be exercised in a reasonable manner.  You acknowledge and agree that all payments owed to you shall not include any interest and will be net of any amounts that we are required to withhold by law.</li>
            
            <li><u>Customer Charges.</u> Miler will charge the Customer the amount agreed upon as between Provider and Customer for the performance of the Service Marketplace Services as reflected on the invoice provided by Provider to Miler via the Service Marketplace.  Provider shall include in the invoice for the Service Marketplace Services all state or local fees, surcharges or taxes imposed on the provision of Service Marketplace Services.  Provider’s failure to include any amounts due and owing to Provider on the invoice provided to Miler via the Service Marketplace, whether for the performance of the Service Marketplace Services, reimbursement for costs, fees, taxes, or any other amount, shall constitute a waiver of any claim for such unbilled amounts and neither Customer nor Miler shall have any responsibility for payment of amounts not included on Provider’s invoice.</li>

            <li><u>Platform Fee.</u> In exchange for facilitating the Service Marketplace Services that you provide to Customers, you agree to pay Miler (and permit Miler to retain) a fee based on each transaction in which you provide Service Marketplace Services (the “Platform Fee”). The Platform Fee shall be a variable amount based on a fixed percentage of the value of the Service you provide a Customer. The percentage Platform Fee will be disclosed to you at the outset of your registration with Miler to become a Provider.  The amounts of Platform Fee are determined on an individual basis, based on a number of factors including, but not limited to, (1) your industry, (2) your geographic location, (3) the nature of the Service Marketplace Services you desire to provide to Customers, (4) the expected per transaction charges for the Service Marketplace Services, and (5) the cumulative charges for all Service Marketplace Services you provide to all Customers via the Service Marketplace.  Miler may modify the percentage Platform Fee applicable to you at any time, in Miler’s sole discretion, provided that any modifications to the percentage Platform Fee shall be communicated to you in advance of the fee modifications taking effect.</li>

            <li><u>Payment Processing.</u> Payment processing services are provided by Stripe and subject to the Stripe Connected Account Agreement (available at <a href="https://stripe.com/us/connect-account/legal" target="_blank"  rel="noreferrer">https://stripe.com/us/connect-account/legal</a>), which includes the Stripe Services Agreement (available at <a href="https://stripe.com/us/legal" target="_blank" rel="noreferrer">https://stripe.com/us/legal</a>), collectively, the “Stripe Terms”.  By using the Service Marketplace to receive payment proceeds, you agree to be bound by the Stripe Terms, which may be modified from time to time. As a condition of Miler enabling payment processing services through Stripe, you authorize Miler to obtain all necessary access and perform all necessary activity on your Stripe Connected Account to facilitate your provision of Service Marketplace Services as contemplated by the Agreement and your relationship with Miler. You further agree to provide accurate and complete information about you and your business, and authorize Miler to share it and transaction information with Stripe for the purposes of facilitating the payment processing services provided by Stripe. Miler reserves the right to switch payment processing vendors or use alternate or backup vendors in its discretion.</li>

            <li><u>No Additional Amounts.</u> You acknowledge and agree that, for the mutual benefit of the parties, through advertising and marketing, Miler and its affiliates may seek to generate additional demand for your Service Marketplace Services from new and existing Customers. You acknowledge and agree such advertising or marketing does not entitle you to any additional monetary amounts beyond the amounts expressly set forth in the Agreement</li>
          </ol>
          </div>
          <h4 className="mt-3">5. MILER COMMUNICATIONS</h4>
          <p className="p-2 ml-5">
          By entering into this Agreement or using the Miler Software, you agree to receive communications from us or communications related to the Miler Software at any of the phone numbers provided to Miler by you or on your behalf, including via e-mail, text message, calls, and push notifications. You agree that texts, calls or prerecorded messages may be generated by automatic telephone dialing systems. Communications from Miler, its affiliated companies and/or Customers, may include but are not limited to: operational communications concerning your User account or use of the Miler Software or Service Marketplace, updates concerning new and existing features on the Miler Software or Service Marketplace, communications concerning marketing or promotions run by us or our third-party partners, and news concerning Miler and industry developments. If you change or deactivate the phone number you provided to Miler, you agree to update your account information to help prevent us from inadvertently communicating with anyone who acquires your old number. Standard text messaging charges applied by your cell phone carrier will apply to text messages we send.
          </p>
          <h4 className="mt-3">6. YOUR INFORMATION</h4>
          <p className="p-2 ml-5">
            Your Information is any information you provide, publish or post to or through the Miler Software or send to other Users (your “Information”). Our collection and use of the Information is as provided in Miler’s{" "}
            <Link
              to="/privacy"
              className="underline text-blue-600 cursor-pointer font-semibold"
            >
              Privacy Policy
            </Link>. Miler’s{" "}
            <Link
              to="/privacy"
              className="underline text-blue-600 cursor-pointer font-semibold"
            >
              Privacy Policy
            </Link> is incorporated in this Agreement by reference. By accepting this Agreement, you accept Miler’s{" "}
            <Link
              to="/privacy"
              className="underline text-blue-600 cursor-pointer font-semibold"
            >
              Privacy Policy
            </Link> in full. You consent to us using your Information to create a User account that will allow you to use the Service Marketplace and participate in the Service Marketplace Services. Our collection and use of personal information in connection with the Service Marketplace and Service Marketplace Services is as provided in Miler’s Privacy Policy located at https://www.miler.com/privacy. You are solely responsible for your Information and your interactions with other members of the public, and we act only as a passive conduit for your online posting of your Information. You agree to provide and maintain accurate, current and complete information and that we and other members of the public may rely on your Information as accurate, current and complete. To enable Miler to use your Information for the purposes described in the Privacy Policy and this Agreement, you grant to us a non-exclusive, worldwide, perpetual, irrevocable, royalty-free, transferable, sub-licensable (through multiple tiers) right and license to exercise the copyright, publicity, and database rights you have in your Information, and to use, copy, perform, display and distribute such Information to prepare derivative works, or incorporate into other works, such Information, in any media now known or not currently known.  Miler does not assert any ownership over your Information; rather, as between you and Miler, subject to the rights granted to us in this Agreement, you retain full ownership of all of your Information and any intellectual property rights or other proprietary rights associated with your Information.
          </p>
          <h4 className="mt-3">7. RESTRICTED ACTIVITIES</h4>
          <p className="p-2 ml-5">
          With respect to your use of the Services, you agree that you will not:
          </p>
          <div className="p-2 ml-5">
          <ol className="terms_page">
              <li>	impersonate any person or entity;</li>
              <li>stalk, threaten, or otherwise harass any person;</li>
              <li>violate any law, statute, rule, permit, ordinance or regulation;</li>
              <li>interfere with or disrupt the Service Marketplace, or the servers or networks connected to the Service Marketplace;</li>
              <li>post Information or interact with any person in a manner which is fraudulent, libelous, abusive, obscene, profane, sexually oriented, harassing, or illegal;</li>
              <li>use the Service Marketplace in any way that infringes any third party’s rights, including: intellectual property rights, copyright, patent, trademark, trade secret or other proprietary rights or rights of publicity or privacy;</li>
              <li>post, email or otherwise transmit any malicious code, files or programs designed to interrupt, damage, destroy or limit the functionality of the Service Marketplace, or any computer software or hardware or telecommunications equipment or surreptitiously intercept or expropriate any system, data or personal information;</li>
              <li>forge headers or otherwise manipulate identifiers in order to disguise the origin of any information transmitted through the Service Marketplace;</li>
              <li>“frame” or “mirror” any part of the Service Marketplace, without our prior written authorization or use meta tags or code or other devices containing any reference to us in order to direct any person to any other web site for any purpose;</li>
              <li>modify, adapt, translate, reverse engineer, decipher, decompile or otherwise disassemble any portion of the Service Marketplace;</li>
              <li>rent, lease, lend, sell, redistribute, license or sublicense the Service Marketplace or access to any portion of the Service Marketplace;</li>
              <li>use any robot, spider, site search/retrieval application, or other manual or automatic device or process to retrieve, index, scrape, “data mine”, or in any way reproduce or circumvent the navigational structure or presentation of the Service Marketplace or its contents;</li>
              <li>link directly or indirectly to any other web sites;</li>
              <li>transfer or sell your User account, password and/or identification, or any other User's Information to any other party;</li>
              <li>discriminate against or harass anyone on the basis of race, national origin, religion, gender, gender identity or expression, physical or mental disability, medical condition, marital status, age or sexual orientation; or</li>
              <li>cause any third party to engage in the restricted activities above.</li>

          </ol>
          </div>
          <h4 className="mt-3">8. PROVIDER REPRESENTATIONS, WARRANTIES AND AGREEMENTS</h4>
          <p className="p-2 ml-5">
          By providing Service Marketplace Services as a Provider on the Service Marketplace, you represent, warrant, and agree that:
          </p>
          <div className="p-2 ml-5">
          <ol className="terms_page">
            <li>You possess all licenses, permits, approvals, and authorities necessary to provide the Service Marketplace Services in any jurisdiction in which the Service Marketplace Services are provided; </li>

            <li>You will not attempt to defraud Miler or Customers on the Service Marketplace or in connection with your provision of Service Marketplace Services. If we suspect that you have engaged in fraudulent activity we may withhold applicable Charges or other payments for the Service Marketplace Services in question and take any other action against you available under the law.</li>

            <li>You have and will maintain, at all times this Agreement is in effect, a valid policy of liability insurance with policy limits customary for your industry.</li>

            <li>You will pay all applicable federal, state and local taxes based on your provision of Service Marketplace Services and any payments received by you.</li>

          </ol>
          </div>
          <h4 className="mt-3">
            9. INTELLECTUAL PROPERTY
          </h4>
          <p className="p-2 ml-5">
          All intellectual property rights in the Miler Software shall be owned by Miler absolutely and in their entirety. These rights include database rights, copyright, design rights (whether registered or unregistered), trademarks (whether registered or unregistered) and other similar rights wherever existing in the world together with the right to apply for protection of the same. All other trademarks, logos, service marks, company or product names set forth in the Miler Software are the property of their respective owners. You acknowledge and agree that any questions, comments, suggestions, ideas, feedback or other information (“Submissions”) provided by you to us are non-confidential and shall become the sole property of Miler. Miler shall own exclusive rights, including all intellectual property rights, and shall be entitled to the unrestricted use and dissemination of these Submissions for any purpose, commercial or otherwise, without acknowledgment or compensation to you.
          </p>
          <h4 className="mt-3">10. DISCLAIMERS</h4>
          <p className="p-2 ml-5">
          The following disclaimers are made on behalf of Miler, our affiliates, subsidiaries, parents, successors and assigns, and each of our respective officers, directors, employees, agents, and shareholders.
          </p>
          <div className="p-2 ml-5">
          <ol className="terms_page">
            <li>The Service Marketplace is provided on an “as is” basis and without any warranty or condition, express, implied or statutory. We do not guarantee and do not promise any specific results from use of the Service Marketplace and/or the Service Marketplace Services, including the ability to provide or receive Service Marketplace Services at any given location or time.  To the fullest extent permitted by law, we specifically disclaim any implied warranties of title, merchantability, fitness for a particular purpose and non-infringement. Some states do not allow the disclaimer of implied warranties, so the foregoing disclaimer may not apply to you.</li>

            <li>We do not warrant that your use of the Service Marketplace or Service Marketplace Services will be accurate, complete, reliable, current, secure, uninterrupted, always available, or error-free, or will meet your requirements, that any defects in the Service Marketplace will be corrected, or that the Service Marketplace is free of viruses or other harmful components. We disclaim liability for, and no warranty is made with respect to, connectivity and availability of the Service Marketplace or Service Marketplace Services.</li>
                    
            <li>We cannot guarantee that each Customer is who he or she claims to be. Please use common sense when using the Service Marketplace and Service Marketplace Services, including verifying the identity of the Customer you have matched with.  We encourage you to communicate directly with each potential Customer prior to engaging in any transaction.</li>

            <li>Miler is not responsible for the conduct, whether online or offline, of any User of the Service Marketplace or Service Marketplace Services. You are solely responsible for your interactions with other Users. We do not procure insurance for any losses you incur while using the Service Marketplace.  By using the Service Marketplace and participating in the Service Marketplace Services, you agree to accept all risks and agree that Miler is not responsible for the acts or omissions of Users on the Service Marketplace or participating in the Service Marketplace Services.</li>

            <li>You are responsible for the use of your User account and Miler expressly disclaims any liability arising from the unauthorized use of your User account. Should you suspect that any unauthorized party may be using your User account or you suspect any other breach of security, you agree to notify us immediately.</li>

            <li>It is possible for others to obtain information about you that you provide, publish or post to or through the Service Marketplace (including any profile information you provide), send to other Users, or share during the Service Marketplace Services, and to use such information to harass or harm you. We are not responsible for the use of any personal information that you disclose to other Users on the Service Marketplace or through the Service Marketplace Services. Please carefully select the type of information that you post on the Service Marketplace or through the Service Marketplace Services or release to others. We disclaim all liability, regardless of the form of action, for the acts or omissions of other Users (including unauthorized users, or “hackers”).</li>

            <li>Opinions, advice, statements, offers, or other information or content concerning Miler or made available through the Service Marketplace, but not directly by us, are those of their respective authors, and should not necessarily be relied upon. Such authors are solely responsible for such content. Under no circumstances will we be responsible for any loss or damage resulting from your reliance on information or other content posted by third parties, whether on the Service Marketplace or otherwise. We reserve the right, but we have no obligation, to monitor the materials posted on the Service Marketplace and remove any such material that in our sole opinion violates, or is alleged to violate, the law or this agreement or which might be offensive, illegal, or that might violate the rights, harm, or threaten the safety of users or others.</li>

            <li>This paragraph applies to any version of the Service Marketplace that you acquire from the Apple App Store. This Agreement is entered into between you and Miler.  Apple, Inc. (“Apple”) is not a party to this Agreement and shall have no obligations with respect to the Service Marketplace. Miler, not Apple, is solely responsible for the Service Marketplace and the content thereof as set forth hereunder. However, Apple and Apple’s subsidiaries are third-party beneficiaries of this Agreement. Upon your acceptance of this Agreement, Apple shall have the right (and will be deemed to have accepted the right) to enforce this Agreement against you as a third-party beneficiary thereof. This Agreement incorporates by reference Apple’s Licensed Application End User License Agreement, for purposes of which, you are “the end-user.” In the event of a conflict in the terms of the Licensed Application End User License Agreement and this Agreement, the terms of this Agreement shall control.</li>

            <li>Miler shall not be in breach of this Agreement nor liable for failure or delay in performing obligations under this Agreement if such failure or delay results from events, circumstances or causes beyond its reasonable control including (without limitation) natural disasters or acts of God; acts of terrorism; labor disputes or stoppages; war; government action; epidemic or pandemic; chemical or biological contamination; strikes, riots, or acts of domestic or international terrorism; quarantines; national or regional emergencies; or any other cause, whether similar in kind to the foregoing or otherwise, beyond the party’s reasonable control. All service dates under this Agreement affected by force majeure shall be tolled for the duration of such force majeure. The parties hereby agree, when feasible, not to cancel but reschedule the pertinent obligations as soon as practicable after the force majeure condition ceases to exist.</li>        
  </ol>
          </div>
          <h4 className="mt-3">11. INDEMNITY</h4>
          <p className="p-2 ml-5">
          You agree to defend, indemnify, and hold Miler including our affiliates, subsidiaries, parents, successors and assigns, and each of our respective officers, directors, employees, agents, or shareholders harmless from any claims, actions, suits, losses, costs, liabilities and expenses (including reasonable attorneys’ fees) relating to or arising out of your use of the Miler Software, Service Marketplace, and participation in the Service Marketplace Services, including: (1) your breach of this Agreement or the documents it incorporates by reference; (2) your violation of any law or the rights of a third party, including, Customers, as a result of your own interaction with such third party; (3) any allegation that any materials that you submit to us or transmit through the Service Marketplace or to us infringe or otherwise violate the copyright, trademark, trade secret or other intellectual property or other rights of any third party; (4) your ownership, use or operation of a motor vehicle or passenger vehicle, including your provision of Service Marketplace Services as a Provider; and/or (5) any other activities in connection with the Service Marketplace Services. This indemnity shall be applicable without regard to the negligence of any party, including any indemnified person.
          </p>
          <h4 className="mt-3">12. LIMITATION OF LIABILITY</h4>
          <p className="p-2 ml-5">
          IN NO EVENT WILL MILER, INCLUDING OUR AFFILIATES, SUBSIDIARIES, PARENTS, SUCCESSORS AND ASSIGNS, AND EACH OF OUR RESPECTIVE OFFICERS, DIRECTORS, EMPLOYEES, AGENTS, OR SHAREHOLDERS (COLLECTIVELY “MILER” FOR PURPOSES OF THIS SECTION), BE LIABLE TO YOU FOR ANY INCIDENTAL, SPECIAL, EXEMPLARY, PUNITIVE, CONSEQUENTIAL, OR INDIRECT DAMAGES (INCLUDING DAMAGES FOR DELETION, CORRUPTION, LOSS OF DATA, LOSS OF PROGRAMS, FAILURE TO STORE ANY INFORMATION OR OTHER CONTENT MAINTAINED OR TRANSMITTED BY THE SERVICE MARKETPLACE, SERVICE INTERRUPTIONS, OR FOR THE COST OF PROCUREMENT OF SUBSTITUTE SERVICES) ARISING OUT OF OR IN CONNECTION WITH THE SERVICE MARKETPLACE, SERVICE MARKETPLACE SERVICES, OR THIS AGREEMENT, HOWEVER ARISING INCLUDING NEGLIGENCE, EVEN IF WE OR OUR AGENTS OR REPRESENTATIVES KNOW OR HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. CERTAIN JURISDICTIONS MAY NOT ALLOW THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS, EXCLUSIONS OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MAY HAVE ADDITIONAL RIGHTS.
          </p>
          <h4 className="mt-3">13. TERM AND TERMINATION</h4>
          <p className="mt-3 p-2 ml-5">
          This Agreement is effective upon your acceptance of this Agreement. This Agreement may be terminated: a) by You, without cause, upon seven (7) days’ prior notice to Miler; or b) by either Party immediately, without notice, upon the other Party’s material breach of this Agreement. You may either submit you notice in writing, make a cancellation request online through the Service Marketplace, or call to speak with a Miler representative, or through a chat service, if applicable. In addition, Miler may terminate this Agreement or deactivate your User account immediately in the event: (1) you no longer qualify to provide Service Marketplace Services in Miler’s sole discretion or (2) Miler has the good faith belief that such action is necessary to protect the safety of the Miler community or third parties. For all other breaches of this Agreement, you will be provided notice and an opportunity to cure the breach. If the breach is cured in a timely manner and to Miler’s satisfaction, this Agreement will not be permanently terminated. Sections 8, 11-12, 14 and 15 shall survive any termination or expiration of this Agreement.
          </p>          
          <h4 className="mt-3">14. DISPUTE RESOLUTION</h4>
          <div className="p-2 ml-5">
          <ol className="terms_page">
            <li>Agreement to Binding Arbitration Between You and Miler.  YOU AND MILER MUTUALLY AGREE TO WAIVE OUR RESPECTIVE RIGHTS TO RESOLUTION OF DISPUTES IN A COURT OF LAW BY A JUDGE OR JURY AND AGREE TO RESOLVE ANY DISPUTE BY ARBITRATION, as set forth below.  Any dispute arising from or relating to the subject matter of this Agreement that cannot be resolved by the parties within a period of sixty (60) days after notice of a dispute has been given by one party hereunder to the other, shall be finally settled by arbitration in Columbus, Ohio, United States, using the English language in accordance with the rules promulgated by the American Arbitration Association (the “AAA Rules”) then in effect, by one or more commercial arbitrator(s) with substantial experience in resolving complex commercial contract disputes. The parties agree that such arbitrator(s) shall have full authority to award preliminary and permanent injunctive relief, damages, and any other relief available in law, at equity, or otherwise pursuant to applicable law and that any emergency arbitrator(s) appointed in accordance with the AAA Rules shall have authority to grant emergency relief in accordance with such rules.  ANY ARBITRATION UNDER THIS AGREEMENT WILL TAKE PLACE ON AN INDIVIDUAL BASIS; CLASS ARBITRATIONS AND CLASS ACTIONS ARE NOT PERMITTED. Except as expressly provided below, this Arbitration Agreement applies to all Claims (defined below) between you and Miler, including our affiliates, subsidiaries, parents, successors and assigns, and each of our respective officers, directors, employees, agents, or shareholders. This Arbitration Agreement also applies to claims between you and Miler’s Customers and affiliates, including but not limited to payment processors; and such Customers and affiliates shall be considered intended third-party beneficiaries of this Arbitration Agreement.</li>
            <li>Prohibition of Class Actions and Non-Individualized Relief.  YOU UNDERSTAND AND AGREE THAT YOU AND MILER MAY EACH BRING CLAIMS IN ARBITRATION AGAINST THE OTHER ONLY IN AN INDIVIDUAL CAPACITY AND NOT ON A CLASS, COLLECTIVE ACTION, OR REPRESENTATIVE BASIS (“CLASS ACTION WAIVER”). YOU UNDERSTAND AND AGREE THAT YOU AND MILER BOTH ARE WAIVING THE RIGHT TO PURSUE OR HAVE A DISPUTE RESOLVED AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS, COLLECTIVE OR REPRESENTATIVE PROCEEDING.  The arbitrator shall have no authority to consider or resolve any Claim or issue any relief on any basis other than an individual basis. The arbitrator shall have no authority to consider or resolve any Claim or issue any relief on a class, collective, or representative basis. The arbitrator may award declaratory or injunctive relief only in favor of the individual party seeking relief and only to the extent necessary to provide relief warranted by that party's individual claims.</li>
          </ol>
          </div>
          
          <h4 className="mt-3">15. CONFIDENTIALITY</h4>
          <p className="p-2 ml-5">
          You agree not to use any technical, financial, strategic and other proprietary and confidential information relating to Miler’s business, operations and properties, information about a User made available to you in connection with such User’s use of the Platform, which may include the User’s name, location, and contact information (“Confidential Information”) disclosed to you by Miler for your own use or for any purpose other than as contemplated herein. You shall not disclose or permit disclosure of any Confidential Information to third parties, and you agree not to store separate and outside of the Service Marketplace any User Information obtained from the Service Marketplace. You agree to take all reasonable measures to protect the secrecy of and avoid disclosure or use of Confidential Information of Miler in order to prevent it from falling into the public domain. Notwithstanding the above, you shall not have liability to Miler with regard to any Confidential Information which you can prove: was in the public domain at the time it was disclosed by Miler or has entered the public domain through no fault of yours; was known to you, without restriction, at the time of disclosure, as demonstrated by files in existence at the time of disclosure; is disclosed with the prior written approval of Miler; becomes known to you, without restriction, from a source other than Miler without breach of this Agreement by you and otherwise not in violation of Miler’s rights; or is disclosed pursuant to the order or requirement of a court, administrative agency, or other governmental body; provided, however, that You shall provide prompt notice of such court order or requirement to Miler to enable Miler to seek a protective order or otherwise prevent or restrict such disclosure.
          </p>
          <h4 className="mt-3">16. RELATIONSHIP WITH MILER</h4>
          <p className="mt-2 ml-6">
          As a Provider on the Service Marketplace, you acknowledge and agree that you and Miler are in a direct business relationship, and the relationship between the parties under this Agreement is solely that of independent contracting parties. You and Miler expressly agree that (1) this is not an employment agreement and does not create an employment relationship between you and Miler; and (2) no joint venture, franchisor-franchisee, partnership, or agency relationship is intended or created by this Agreement. You have no authority to bind Miler, and you undertake not to hold yourself out as an employee, agent or authorized representative of Miler.
          </p>
          <p className="mt-2 ml-6">
          Miler does not, and shall not be deemed to, direct or control you generally or in your performance under this Agreement specifically, including in connection with your provision of Service Marketplace Services, your acts or omissions, or your business. You retain the sole right to determine when, where, and for how long you will utilize the Service Marketplace. Miler does not, and shall not be deemed to, unilaterally prescribe specific dates, times of day, or any minimum number of hours for you to utilize the Service Marketplace. You retain the option to accept or to decline or ignore a Customer’s request for Service Marketplace Services via the Service Marketplace, or to cancel an accepted request for Service Marketplace Services via the Service Marketplace, subject to Miler’s then-current cancellation policies. Miler does not, and shall not be deemed to, require you to accept any specific request for Service Marketplace Services as a condition of maintaining access to the platform. You acknowledge and agree that you have complete discretion to provide Service Marketplace Services or otherwise engage in any other business or employment activities, including but not limited to providing services similar to the Service Marketplace Services to other companies, and that Miler does not, and shall not be deemed to, restrict you from engaging in any such activity.
          </p>
          
          <h4 className="mt-3">17. GENERAL TERMS</h4>
          <div className="p-2 ml-5">
          <ol className="terms_page">
            <li>Except as provided in Section 16, this Agreement and any action related thereto will be governed by the laws of the State of Ohio without regard to its conflict of laws provisions. Exclusive jurisdiction and venue for actions related to this Agreement or Your use of the Miler Software will be the state and federal courts located in Johnson Country, Kansas, United States, and both parties consent to the jurisdiction of such courts with respect to any such actions.</li>
            <li>If any provision of this Agreement is or becomes invalid or non- binding, the parties shall remain bound by all other provisions of this Agreement. In that event, the parties shall replace the invalid or non-binding provision with provisions that are valid and binding and that have, to the greatest extent possible, a similar effect as the invalid or non-binding provision, given the contents and purpose of this Agreement.</li>
            <li>You agree that this Agreement and all incorporated agreements may be automatically assigned by Miler, in our sole discretion by providing notice to you.</li>
            <li>Except as explicitly stated otherwise, any notices to Miler shall be given by certified mail, postage prepaid and return receipt requested to Miler, LLC, 10895 Lowell Avenue, Suite 205, Overland Park, Kansas 66210. Any notices to you shall be provided to you through the Miler Software or given to you via the email address or physical you provide to Miler during the registration process.</li>
            <li>Headings are for reference purposes only and in no way define, limit, construe or describe the scope or extent of such section. The words “include”, “includes” and “including” are deemed to be followed by the words “without limitation”.</li>
            <li>A party’s failure to act with respect to a breach by the other party does not constitute a waiver of the party’s right to act with respect to subsequent or similar breaches.</li>
            <li>This Agreement sets forth the entire understanding and agreement between you and Miler with respect to the subject matter hereof and supersedes all previous understandings and agreements between the parties, whether oral or written.</li>      
      </ol>
          </div>
          <h4 className=" mt-3">18. CONTACT INFORMATION</h4>
          <p className="p-2 ml-5">
          If you have any questions about this Agreement or wish to submit feedback regarding the Services, please contact Miler at compliance@miler.com or by mail at Attn: Compliance, 10895 Lowell Ave, Ste 205, Overland Park, KS 66210.
          </p>
          
        </section>
      </section>
    </main>
  );
};

export default ProviderTerms;
