import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../../components/footer/Footer";
import NavBar from "../../components/navBar/NavBar";

const PageNotFound = () => {
  const [showSideBar, setShowSideBar] = useState(false);

  const navigate = useNavigate();

  return (
    <section>
      <NavBar showSideBar={showSideBar} setShowSideBar={setShowSideBar} />
      {!showSideBar && (
        <>
          <section className=" bg-miler_light_blue flex flex-col justify-center items-center h-screen gap-y-3">
            <p className="text-4xl">404: Page Not Found</p>
            <p>Sorry, the page you&apos;re looking for doesn&apos;t exist.</p>
            <button
              onClick={() => navigate("/", { replace: true })}
              className=" bg-miler_blue text-white font-semibold w-[30rem] p-2 rounded-full max-sm:w-[90%] max-md:w-[90%] max-lg:w-[90%]"
            >
              Back Home
            </button>
          </section>
          <Footer />
        </>
      )}
    </section>
  );
};

export default PageNotFound;
