import { useState } from "react";
import ContactForm from "../../components/contact/contactForm/ContactForm";
import MobileFormScreen from "../../components/contact/contactForm/mobileForm";
import Footer from "../../components/footer/Footer";
import InfoSection from "../../components/infoSection/InfoSection";
import NavBar from "../../components/navBar/NavBar";

const ContactPage = () => {
  const [showSideBar, setShowSideBar] = useState(false);
  return (
    <>
      <NavBar showSideBar={showSideBar} setShowSideBar={setShowSideBar} />
      {!showSideBar && (
        <>
          <InfoSection
            title=""
            subtitle="We'd like to hear from you!"
            pageText=""
            description=""  
          />
          <ContactForm />
          <MobileFormScreen />
          <Footer />
        </>
      )}
    </>
  );
};

export default ContactPage;
