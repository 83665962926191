import React, { useEffect, useRef } from 'react';

const ScheduleDemo: React.FC = () => {
  const scriptRef = useRef<HTMLScriptElement | null>(null);
  const contentRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    if (!scriptRef.current && contentRef.current) {
      const scriptContainer = document.createElement('div');

      const script = document.createElement('script');
      script.src =
        'https://crm.zoho.com/crm/WebFormServeServlet?rid=f27a094f0127c422e89eac1142278a7de49641f6b8048b08c27c6f10aa78aa5bgid619df3bc479e045c237b2054d0fcb17a70fd818e88694068c212126e7ed935fb&script=$sYG';
      script.async = true;
      script.defer = true;

      scriptRef.current = script;

      contentRef.current.appendChild(scriptContainer);
      scriptContainer.appendChild(script);

      script.onload = () => {
        const elementToMove = document.getElementById('crmWebToEntityForm'); // The element to move
        
        if (elementToMove && contentRef.current) {
          contentRef.current.appendChild(elementToMove); // Move the injected content inside the div
        } else {
        }
      };

      script.onerror = () => {
      };
    }
  }, []);

  return (
    <section id="script_content" ref={contentRef}>
    </section>
  );
};

export default ScheduleDemo;
