import { Link, useNavigate } from "react-router-dom";
import Example from "./MenuItem";
import MilerButton from "../milerButton/MilerButton";
// import MilerLogo from "@/assets/logo/Logo.svg";
import MilerLogo from "../../assets/logo/logo_one.svg";
import { Bars3Icon } from "@heroicons/react/20/solid";
import { XMarkIcon } from "@heroicons/react/20/solid";
interface NavBarProps {
  showSideBar?: boolean;
  setShowSideBar: (value: boolean) => void;
}

const NavBar = (props: NavBarProps) => {
  const { showSideBar, setShowSideBar } = props;

  const navigate = useNavigate();

  return (
    <>
      {showSideBar ? (
        <section className="w-screen h-screen bg-white max-md:block lg:block lg:w-screen overflow-x-hidden ">
          <section className="flex flex-row justify-between items-center bg-white shadow-sm w-full p-2">
            <a href="/">
              <img
                src={MilerLogo}
                width={107}
                height={32}
                alt="company_logo"
                className=""
              />
            </a>

            <XMarkIcon className="w-6" onClick={() => setShowSideBar(false)} />
          </section>
          <section className="flex flex-col gap-y-7 p-2 mt-7">
            <Example
              type="mobile"
              title="Solutions"
              dropDownOptions={[
                {
                  id: 1,
                  title: "Carrier",
                  page: "/solutions/carrier",
                },
                {
                  id: 2,
                  title: "Brokers",
                  page: "/solutions/broker",
                },
                {
                  id: 3,
                  title: "Shippers",
                  page: "/solutions/shipper",
                },
                {
                  id: 4,
                  title: "Service Providers",
                  page: "/solutions/provider",
                },
              ]}
            />
            {/* <Link
              to="/solution"
              className="font-bold text-xl cursor-pointer antialiased max-sm:font-semibold"
            >
              Customers
            </Link> */}
            <Link
              to="/pricing"
              className="font-bold text-xl cursor-pointer antialiased max-sm:font-semibold "
            >
              Pricing
            </Link>
            <Example
              type="mobile"
              title="Resources"
              dropDownOptions={[
                {
                  id: 1,
                  title: "Blogs",
                  page: "/blogs",
                },
                {
                  id: 2,
                  title: "About Us",
                  page: "/about-us",
                },
              ]}
            />
            {/* <Link
              to="/contact"
              className="font-bold text-xl cursor-pointer antialiased max-sm:font-semibold"
            >
              Contact Us
            </Link> */}
            <Example
              type="mobile"
              title="Login"
              dropDownOptions={[
                {
                  id: 1,
                  title: "Carrier Login",
                  page: "https://app.miler.com",
                },
                {
                  id: 2,
                  title: "Broker Login",
                  page: "https://broker.miler.com",
                },
                {
                  id: 3,
                  title: "Shipper Login",
                  page: "https://shipper.miler.com",
                },
                {
                  id: 4,
                  title: "Provider Login",
                  page: "https://provider.miler.com",
                },
              ]}
            />
          </section>
          <section className="flex flex-col justify-end gap-y-3 p-2 mt-3 max-sm:font-semibold">
            <MilerButton size="mobile" title="Register" showIcon />
          </section>
        </section>
      ) : (
        <section className="flex flex-row justify-between items-center p-2 text-base  bg-miler_light_blue max-sm:w-screen max-md:p-1">
          <section className="flex  ml-[2.5rem] flex-row gap-x-10 items-center text-miler_text_color max-sm:ml-0 max-md:ml-0">
            <a href="/">
              <img
                src={MilerLogo}
                width={140}
                alt="company_logo"
                className=""
              />
            </a>
            <Example title="Solutions" type="solution" />
            {/* <Link
              to="/solution"
              className=" max-sm:hidden max-md:hidden max-lg:hidden font-medium miler_black_color text-[1.25rem]"
            >
              Customers
            </Link> */}
            <Link
              to="/pricing"
              className="max-sm:hidden max-md:hidden max-lg:hidden font-medium miler_black_color text-[1.25rem]"
            >
              Pricing
            </Link>
            <Example title="Resources" type="resources" />
            {/* <Link
              to="/contact"
              className="max-sm:hidden max-md:hidden max-lg:hidden font-medium miler_black_color text-[1.25rem]"
            >
              Contact Us
            </Link> */}
          </section>
          <section className="flex flex-row gap-x-4 justify-center items-center max-sm:hidden max-md:hidden max-lg:hidden">
            <Example
              title="Login"
              type="signin"
              dropDownOptions={[
                {
                  id: 1,
                  title: "Carrier Solution",
                  subTitle: "Carrier Login",
                  page: "https://app.miler.com/",
                },
                {
                  id: 2,
                  title: "Broker Solution",
                  subTitle: "Broker Login",
                  page: "https://broker.miler.com",
                },
                {
                  id: 3,
                  title: "Shipper Solution",
                  subTitle: "Shipper Login",
                  page: "https://shipper.miler.com/",
                },
                {
                  id: 3,
                  title: "Provider Solution",
                  subTitle: "Provider Login",
                  page: "https://provider.miler.com/",
                },
              ]}
            />

            <MilerButton
              title="Get Started"
              size="medium"
              showIcon
              handleClick={() => {
                navigate("/pricing");
              }}
            />
          </section>
          <section className="hidden max-sm:block max-md:block max-lg:block">
            {showSideBar ? (
              <XMarkIcon
                className="w-6"
                onClick={() => setShowSideBar(false)}
              />
            ) : (
              <Bars3Icon
                className="w-6 "
                onClick={() => setShowSideBar(true)}
              />
            )}
          </section>
        </section>
      )}
    </>
  );
};

export default NavBar;
