import axios from "axios";

const apiKey = `7SC2kwGOfB3c5bCBIYWmd55A4EphvvcNLUDnlBP8`;

const axiosInstance = axios.create({
  baseURL: "https://dev-api.miler.com",
  headers: {
    "Content-Type": "application/json",
    "X-api-key": `${apiKey}`,
    "X-Miler-UserId": "",
    "X-Miler-CarrierId": "",
  },
});

export default axiosInstance;
