/* eslint-disable react/no-unescaped-entities */
import { Link } from "react-router-dom";
import "../../index.css";
const Terms = () => {
  return (
    <main className="privacy_page">
      <section className="p-[5rem] font-[400] max-sm:p-[1rem] max-md:p-[1.5rem]">
        <h1 className="font-[500] text-4xl text-center max-sm:text-xl max-md:text-2xl">
          MILER LICENSE AGREEMENT
        </h1>
        <section className="mt-3 text-[1rem]">
          Last Updated: July 17, 2022
        </section>
        <section className="text-[1.125rem] leading-8 font-[400] text-black">
          <p className="mt-4">
            PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY. BY ACCESSING AND
            USING THE SERVICES YOU ACKNOWLEDGE AND AGREE THAT YOU HAVE READ
            THESE TERMS AND CONDITIONS CAREFULLY, UNDERSTAND THEM AND AGREE TO
            BE BOUND BY THEM. IF YOU DO NOT AGREE TO THESE TERMS, DO NOT ACCESS
            OR OTHERWISE USE OUR SERVICES.
          </p>
          <p className="mt-3">
            PLEASE BE ADVISED: THIS AGREEMENT CONTAINS PROVISIONS THAT GOVERN
            HOW CLAIMS BETWEEN YOU AND MILER CAN BE BROUGHT (SEE SECTION 20
            BELOW). THESE PROVISIONS WILL, WITH LIMITED EXCEPTION, REQUIRE YOU
            TO SUBMIT CLAIMS YOU HAVE AGAINST MILER TO BINDING AND FINAL
            ARBITRATION ON AN INDIVIDUAL BASIS, NOT AS A PLAINTIFF OR CLASS
            MEMBER IN ANY CLASS, GROUP OR REPRESENTATIVE ACTION OR PROCEEDING.
          </p>
          <p className="mt-3">
            By entering into this Agreement, you expressly acknowledge that you
            understand this Agreement (including the dispute resolution and
            arbitration provisions in Section 20) and accept all of its terms.
            IF YOU DO NOT AGREE TO BE BOUND BY THE TERMS AND CONDITIONS OF THIS
            AGREEMENT, YOU MAY NOT USE OR ACCESS THE SERVICES DESCRIBED UNDER
            THIS AGREEMENT.
          </p>
          <h4 className="mt-3">1. CHANGESCONTRACTUAL RELATIONSHIP</h4>
          <p className="p-2 ml-5">
            This MILER LICENSE AGREEMENT (the “Agreement”) pertains to the use
            of websites, mobile applications, content, products and services
            (collectively, the “Services”) made available by MILER, LLC, a
            Delaware limited liability company (“Miler”). This Agreement is a
            legal agreement between the individual or business entity using the
            Services (“You” or “Your”) and Miler, for the Services, which may
            include but are not limited to computer software and hosted software
            services, printed materials, and web-based services or electronic
            documentation. By accessing and using the Services You are agreeing
            to this Agreement. If You are using the Services for or on behalf of
            a business entity, then Your use means such business entity also
            accepts this Agreement. This Agreement remain in effect even if You
            or Your business entity stop using the Services.
          </p>
          <h4 className="mt-3">2. SERVICES</h4>
          <p className="p-2 ml-5">
            The Services shall constitute a comprehensive, fully integrated,
            cloud-based transportation management and fleet maintenance system
            (the “Miler Software”) and a service marketplace (the “Service
            Marketplace”) where, among other things, You may seek service
            providers to perform services and/or provide goods necessary or
            desirable to support motor carrier operations by being matched with
            persons who provide such services or goods (“Providers”). You may
            elect for Miler to provide you access to the Miler Software and
            Service Marketplace or to only one type of Service.
          </p>
          <h4 className="mt-3">3. THE SERVICE MARKETPLACE</h4>
          <p className="p-2 ml-5">
            The services and goods provided by Providers to You shall be
            referred to herein as the “Service Marketplace Services.” If You
            request Service Marketplace Services via the Service Marketplace,
            You authorize Miler to match you with Providers based on factors
            such as your location, the requested service location, the estimated
            time to needed by You to complete the Service Marketplace Services,
            the estimated time needed for Provider to complete the Service
            Marketplace Services, Your preferences, and platform efficiency, and
            to cancel an existing match and/or rematch You with a Provider based
            on the same considerations. Any decision by You to accept Service
            Marketplace Services is a decision made in Your sole discretion.
            Each Service Marketplace Services provided by a Provider to You
            shall constitute a separate agreement between such persons. The
            Service Marketplace may only be used by individuals or entities who
            have the right and authority to enter into this Agreement and are
            fully able and competent to satisfy the terms, conditions, and
            obligations herein. The Service Marketplace is not available to You
            if you have had Your account temporarily or permanently deactivated.
            If You are an organization, multiple persons may be permitted to use
            the account if You authorize such use. You may not allow
            non-authorized persons to use Your account. Miler reserves the right
            to deactivate any additional or duplicate accounts. Your
            participation in certain Miler programs and use of certain Miler
            products or services may be subject to additional eligibility
            requirements as determined by Miler. By requesting Marketplace
            Services via the Service Marketplace, if you are an individual, you
            represent and warrant that you are at least 18 years old and if you
            are an organization, you represent and warrant that you are
            authorized on behalf of the organization to enter into this
            Agreement.
          </p>
          <h4 className="mt-3">4. CHANGES TO THIS AGREEMENT OR SERVICES</h4>
          <p className="p-2 ml-5">
            Miler may modify this Agreement at any time, in our sole discretion.
            If Miler does so, Miler will inform you by posting the modified
            version of this Agreement to Miler’s website or through other
            communications with You. It’s important that you review this
            Agreement whenever Miler modifies it because if you continue to use
            the Services after Miler has posted or otherwise informed you of the
            modified Agreement, you are indicating to Miler that you agree to be
            bound by the modified Agreement. If you do not agree to be bound by
            the modified Agreement, then you may not continue to use the
            Services.
          </p>
          <h4 className="mt-3">5. PROVISION OF SERVICES</h4>
          <p className="p-2 ml-5">
            Subject to the terms and conditions specified in this Agreement,
            Miler agree to provide with access to the Services.
          </p>
          <h4 className="mt-3">6. RESTRICTIONS</h4>
          <p className="p-2 ml-5">
            You agree not to do or attempt to do any of the following without
            Miler’s written consent: (a) copy (except as set forth below),
            modify, distribute, sell, license, sublicense, assign, transmit,
            publish or republish, display, perform, edit, create derivative
            works from, transfer, lease, sell or license any part of the
            Services to a third party; (b) reverse engineer, dissemble, or
            decompile any technical limitations of the Services; (c) copy or
            retrieve data or other content provided by Miler to You, either
            manually or by use of automatic devices, for the purpose of creating
            or compiling, directly or indirectly, a collection, database or
            directory or creating products competitive to the Services; (d) use
            meta tags or other hidden text utilizing Miler’s name or trademarks
            or use framing techniques to enclose any portion of the Services; or
            (e) make copies of any part of the Services except as reasonably
            necessary for Your own internal business purposes. In addition to
            other prohibitions as set forth in this Section, You are prohibited
            from using the Services or its content: (a) for any unlawful
            purpose; (b) to solicit others to perform or participate in any
            unlawful acts; (c) to violate any international, federal, provincial
            or state regulations, rules, laws, or local ordinances; (d) to
            infringe upon or violate Miler’s intellectual property rights or the
            intellectual property rights of others; (e) to harass, abuse,
            insult, harm, defame, slander, disparage, intimidate, or
            discriminate based on gender, sexual orientation, religion,
            ethnicity, race, age, national origin, or disability; (f) to submit
            false or misleading information; (g) to upload or transmit viruses
            or any other type of malicious code that will or may be used in any
            way that will affect the functionality or operation of the Service
            or of any related website, other websites, or the internet; (h) to
            collect or track the personal information of others; (i) to spam,
            phish, pharm, pretext, spider, crawl, or scrape; (j) for any obscene
            or immoral purpose; or (k) to interfere with or circumvent the
            security features of the Service or any related website, other
            websites, or the Internet. Miler has the right to investigate
            violations of this Section or conduct that affects the Services and
            immediately suspend or terminate any or all of Your access to the
            Services if Miler reasonably suspects or determines that You have
            violated this Section. Miler may also consult and cooperate with law
            enforcement authorities to prosecute users who violate the law.
          </p>
          <h4 className="mt-3">7. CONFIDENTIALITY</h4>
          <p className="p-2 ml-5">
            Miler has Your permission to identify You as a customer of Miler or
            user of our Service and to reproduce Your name and logo on Miler’s
            website and in any other marketing materials. If You provide Miler
            any feedback, suggestions, or other information or ideas regarding
            the Service (“Feedback”), You hereby assign to Miler all rights in
            the Feedback and agree that Miler has the right to use such Feedback
            and related information in any manner it deems appropriate. Miler
            will treat any Feedback You provide as non-confidential and
            non-proprietary. You agree that You will not submit to Miler any
            information or ideas that You consider to be confidential or
            proprietary. If Miler provides You with confidential information
            relating in any way to its business operations, You shall keep all
            information strictly confidential, unless disclosure is required by
            law or judicial process or such information is publicly known or
            obtained by You without any breach of any confidentiality agreement.
          </p>
          <h4 className="mt-3">8. PROPRIETARY RIGHTS</h4>
          <p className="p-2 ml-5">
            The Services are protected by copyright laws and international
            copyright treaties, trademark laws, patent laws, as well as other
            intellectual property laws and treaties. Miler owns all rights,
            title, and interest in any and all copyright and other intellectual
            property within or made part of the Services. You may not remove,
            obscure or alter any copyright, trademark or other proprietary
            notices displayed on Miler’s Services. The Services are being
            licensed to You and/or Your business entity for Your personal use
            only, and nothing in this Agreement should be construed as a sale or
            transfer of ownership of any intellectual property rights. Neither
            this Agreement nor Your use of the Services convey or grant to You
            any rights: (a) in or related to the Services except for the limited
            license rights granted above; or (b) to use or reference in any
            manner the company names, logos, product and service names,
            trademarks or services marks of Miler or its licensors.
          </p>
          <h4 className="mt-3">
            9. MILER SOFTWARE SUBSCRIPTION TERM, RENEWAL, AND FEES
          </h4>
          <ul className="list-inside list-decimal p-2 ml-5">
            <li>
              <u>Payment.</u> You agree to pay Miler for use of the Miler
              Software in accordance with the fees, charges, applicable sales
              taxes and billing terms in effect at the time a fee or charge is
              due and payable. According to the nature of the Miler Software,
              fees may be required in advance of use as a pay-in-advance
              subscription (the “Subscription”). Subscriptions to the Miler
              Software are for an initial term of one (1) month. All fees must
              be paid in U.S. dollars unless specifically permitted otherwise by
              Miler. The Subscription is billed in advance on a monthly basis.
              The Subscription will automatically renew for successive one (1)
              month increments unless You provide Miler notice of Your intent to
              terminate the Subscription through the Miler software portal, in
              which case notice can by given to Miler at any time prior to the
              next billing period, or by any other form of notice, in which case
              such notice must be given at least five (5) days prior to the
              beginning of the next billing period. If timely notice is not
              received, you access to the Miler Software will continue through
              the next billing period.
            </li>
            <li>
              <u>Renewal.</u> We will automatically renew and charge Your credit
              card, withdraw from Your account or issue an invoice to You
              (whichever form of payment You authorized at the time You set up
              Your account) every month. Renewal charges for each service
              agreement will be equal to the then-current service fee, unless
              the parties mutually agreed otherwise. Fees for other services
              will be charged on an as-quoted basis. Unpaid fees will result in
              suspension of your access to the Miler Software. Once fees have
              been paid after suspension, You will be charged going forward at
              the then current rate for access to the Miler Software.
            </li>

            <li>
              <u>Refund.</u> For access to the Miler Software, payment is made
              in advance regardless of actual usage. Miler does not refund fees
              or pro-rate for partial usage. In the event that you terminate
              your subscription to the Miler Software, your forfeit any payments
              already made to Miler.
            </li>
          </ul>
          <h4 className="mt-3">10. SERVICE MARKETPLACE CHARGES</h4>
          <ol className=" list-decimal p-2 ml-5">
            <li>
              <u>Service Marketplace Charges.</u> Your agreement with Provider
              with respect to Service Marketplace Services will result in
              charges to You (the “Service Marketplace Charges”). Service
              Marketplace Charges shall reflect the agreed-upon charges between
              You and Provider. Pricing for the Service Marketplace Charges will
              vary based on the negotiated rate between You and Provider. You
              shall be responsible for reviewing the applicable Service
              Marketplace Charges and You shall be responsible for all Service
              Marketplace Charges incurred under your user account. Your
              agreement with a Provider shall govern with respect to any
              additional charges, such as cancellation charges, no-show charges,
              minimum charges, fuel surcharge, state or local taxes and fees,
              and any other charge or fee negotiated between You and Provider.
            </li>

            <li>
              <u>Facilitation of Service Marketplace Charges.</u> All Service
              Marketplace Charges are facilitated through third-party payment
              processor Stripe, Inc. Miler may replace its third-party payment
              processor without notice to you. Service Marketplace Charges shall
              only be made through the Service Marketplace. Payments directly
              from You to Provider are strictly prohibited. Your payment of
              Service Marketplace Charges to Miler satisfies Your payment
              obligation for the use of the Service Marketplace and the Service
              Marketplace Services. Certain Service Marketplace Charges may be
              collectively billed as a single purchase transaction to your
              selected payment method based on the payment frequency indicated
              in your settings.
            </li>

            <li>
              <u>Payment Dispute and Refund of Service Marketplace Charges.</u>{" "}
              Miler shall have no obligation to provide You with a refund of the
              Service Marketplace Charges in the event You are unsatisfied with
              a Provider’s Service Marketplace Services. Any disputes between
              You and Provider shall be resolved on terms agreed upon between
              You and Provider.
            </li>
          </ol>
          <h4 className="mt-3">11. UPDATES TO THE SERVICES</h4>
          <p className="p-2 ml-5">
            Miler may from time to time update the Services. Miler may change or
            discontinue all or any part of the Services, at any time and without
            notice, at Miler’s sole discretion. If Miler discontinues supporting
            the Services you have ordered from Miler in accordance with your
            Subscription without offering to replace them with an updated
            version or newer model, you may request a refund. Updates or
            upgrades may include security or bug fixes, performance
            enhancements, or new functionality, and may be issued with or
            without prior notification to You. You hereby consent to such
            automatic updates.
          </p>
          <h4 className="mt-3">12. MILER COMMUNICATIONS</h4>
          <p className="p-2 ml-5">
            By entering into this Agreement or using the Miler Software and
            Service Marketplace, you agree to receive communications from us or
            communications related to the Services at any of the phone numbers
            provided to Miler by you or on your behalf, including via e-mail,
            text message, calls, and push notifications. You agree that texts,
            calls or prerecorded messages may be generated by automatic
            telephone dialing systems. Communications from Miler, its affiliated
            companies and/or Providers, may include but are not limited to:
            operational communications concerning your user account or use of
            the Services, updates concerning new and existing features on the
            Miler Software and Service Marketplace, communications concerning
            marketing or promotions run by us or our third-party partners, and
            news concerning Miler and industry developments. If you change or
            deactivate the phone number you provided to Miler, you agree to
            update your account information to help prevent us from
            inadvertently communicating with anyone who acquires your old
            number. Standard text messaging charges applied by your cell phone
            carrier will apply to text messages we send.
          </p>
          <h4 className="mt-3">13. DISCLAIMER OF WARRANTIES</h4>
          <p className="mt-3 p-2 ml-5">
            Miler does not function as a transportation carrier or arrange for
            the movement of freight. All such arrangements are made by the users
            of the Services, and any terms and conditions of such freight
            movement, including without limitation any pricing or payment terms,
            are solely among the users of Miler’s Services. No agency,
            partnership, joint venture, employee–employer or
            franchisee–franchisor relationship is intended or created by Your
            use of the Services or by this Agreement. You use the Services at
            Your own risk. Miler makes no representation or warranty whatsoever:
            (a) regarding the reliability, timeliness, quality, suitability or
            availability of the Services; (b) that the Services will be
            uninterrupted or error-free; or (c) regarding the quality,
            qualifications, suitability, safety, accident history, vehicle
            history, criminal or civil liability history, creditworthiness or
            experience of any of the users of the Services. In addition, by
            using the Services, You acknowledge and agree that Miler does not,
            and has no duty to, conduct any investigations or evaluations
            relating to any of the foregoing. You fully and completely assume
            all risk of using the Services and any content, materials or
            information provided to You by Miler or any other user of the
            Services. MILER DISCLAIMS ALL OTHER WARRANTIES AND REPRESENTATIONS,
            WHETHER EXPRESS, IMPLIED, OR OTHERWISE, INCLUDING THE WARRANTIES OF
            MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE. WITHOUT
            LIMITING THE FOREGOING, AND TO THE FULL EXTENT PERMISSIBLE BY
            APPLICABLE LAW, ALL SERVICES AND ANY OTHER MATERIALS OR INFORMATION
            PROVIDED TO YOU BY MILER ARE DELIVERED AS IS AND AS AVAILABLE,
            WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING
            BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY,
            FITNESS FOR A PARTICULAR PURPOSE, RELIABILITY, ABILITY TO MEET YOUR
            NEEDS OR NONINFRINGEMENT.
          </p>
          <p className="p-2 ml-5">
            MILER DOES NOT WARRANT THAT THE SERVICES OR ANY OF ITS SERVERS ARE
            FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS THAT MAY INFECT OR
            DAMAGE YOUR HARDWARE OR OTHER PROPERTY AS A RESULT OF YOUR
            ACCESSING, BROWSING OR USING THE SERVICES, DOWNLOADING INFORMATION
            OR PRINTING INFORMATION FROM THE SERVICES.
          </p>
          <h4 className="mt-3">14. SERVICE MARKETPLACE DISCLAIMERS</h4>
          <p className="p-2 ml-5">
            The following disclaimers are made on behalf of Miler, our
            affiliates, subsidiaries, parents, successors and assigns, and each
            of our respective officers, directors, employees, agents, and
            shareholders.
          </p>
          <ol type="a" className=" list-decimal p-2 ml-5">
            <li>
              Miler does not provide the Service Marketplace Services. It is up
              to the Provider to decide whether or not to offer Service
              Marketplace Services to You, and it is up to You to decide whether
              or not to request or accept Service Marketplace Services from any
              Provider contacted through the Service Marketplace. We cannot
              ensure that a Provider will complete an arranged service. We have
              no control over the manner the Service Marketplace Services are
              provided.
            </li>

            <li>
              The Service Marketplace is provided on an “as is” basis and
              without any warranty or condition, express, implied or statutory.
              We do not guarantee and do not promise any specific results from
              use of the Service Marketplace and/or the Service Marketplace
              Services, including the ability to provide or receive Service
              Marketplace Services at any given location or time. To the fullest
              extent permitted by law, we specifically disclaim any implied
              warranties of title, merchantability, fitness for a particular
              purpose and non-infringement. Some states do not allow the
              disclaimer of implied warranties, so the foregoing disclaimer may
              not apply to you.
            </li>

            <li>
              We do not warrant that your use of the Service Marketplace or
              Service Marketplace Services will be accurate, complete, reliable,
              current, secure, uninterrupted, always available, or error-free,
              or will meet your requirements, that any defects in the Service
              Marketplace will be corrected, or that the Service Marketplace is
              free of viruses or other harmful components. We disclaim liability
              for, and no warranty is made with respect to, connectivity and
              availability of the Service Marketplace or Service Marketplace
              Services.
            </li>
            <li>
              We cannot guarantee that each Provider is who he or she claims to
              be. Please use common sense when using the Service Marketplace and
              Service Marketplace Services, including verifying the identity of
              the Provider you have matched with. We encourage you to
              communicate directly with each potential Provider prior to
              engaging in any transaction.
            </li>
            <li>
              Miler is not responsible for the conduct, whether online or
              offline, of any user of the Service Marketplace or Service
              Marketplace Services. You are solely responsible for your
              interactions with other users. We do not procure insurance for any
              losses you incur while using the Service Marketplace. By using the
              Service Marketplace and participating in the Service Marketplace
              Services, you agree to accept all risks and agree that Miler is
              not responsible for the acts or omissions of users on the Service
              Marketplace or participating in the Service Marketplace Services.
            </li>
            <li>
              You are responsible for the use of your account and Miler
              expressly disclaims any liability arising from the unauthorized
              use of your account. Should you suspect that any unauthorized
              party may be using your account or you suspect any other breach of
              security, you agree to notify us immediately.
            </li>
            <li>
              It is possible for others to obtain information about you that you
              provide, publish or post to or through the Service Marketplace
              (including any profile information you provide), send to other
              users, or share during the Service Marketplace Services, and to
              use such information to harass or harm you. We are not responsible
              for the use of any personal information that you disclose to other
              users on the Service Marketplace or through the Service
              Marketplace Services. Please carefully select the type of
              information that you post on the Service Marketplace or through
              the Service Marketplace Services or release to others. We disclaim
              all liability, regardless of the form of action, for the acts or
              omissions of other users (including unauthorized users, or
              “hackers”).
            </li>
            <li>
              Opinions, advice, statements, offers, or other information or
              content concerning Miler or made available through the Service
              Marketplace, but not directly by us, are those of their respective
              authors, and should not necessarily be relied upon. Such authors
              are solely responsible for such content. Under no circumstances
              will we be responsible for any loss or damage resulting from your
              reliance on information or other content posted by third parties,
              whether on the Service Marketplace or otherwise. We reserve the
              right, but we have no obligation, to monitor the materials posted
              on the Service Marketplace and remove any such material that in
              our sole opinion violates, or is alleged to violate, the law or
              this agreement or which might be offensive, illegal, or that might
              violate the rights, harm, or threaten the safety of users or
              others.
            </li>
            <li>
              This paragraph applies to any version of the Service Marketplace
              that you acquire from the Apple App Store. This Agreement is
              entered into between you and Miler. Apple, Inc. (“Apple”) is not a
              party to this Agreement and shall have no obligations with respect
              to the Service Marketplace. Miler, not Apple, is solely
              responsible for the Service Marketplace and the content thereof as
              set forth hereunder. However, Apple and Apple’s subsidiaries are
              third-party beneficiaries of this Agreement. Upon your acceptance
              of this Agreement, Apple shall have the right (and will be deemed
              to have accepted the right) to enforce this Agreement against you
              as a third-party beneficiary thereof. This Agreement incorporates
              by reference Apple’s Licensed Application End User License
              Agreement, for purposes of which, you are “the end-user.” In the
              event of a conflict in the terms of the Licensed Application End
              User License Agreement and this Agreement, the terms of this
              Agreement shall control.
            </li>
            <li>
              Miler shall not be in breach of this Agreement nor liable for
              failure or delay in performing obligations under this Agreement if
              such failure or delay results from events, circumstances or causes
              beyond its reasonable control including (without limitation)
              natural disasters or acts of God; acts of terrorism; labor
              disputes or stoppages; war; government action; epidemic or
              pandemic; chemical or biological contamination; strikes, riots, or
              acts of domestic or international terrorism; quarantines; national
              or regional emergencies; or any other cause, whether similar in
              kind to the foregoing or otherwise, beyond the party’s reasonable
              control. All service dates under this Agreement affected by force
              majeure shall be tolled for the duration of such force majeure.
              The parties hereby agree, when feasible, not to cancel but
              reschedule the pertinent obligations as soon as practicable after
              the force majeure condition ceases to exist.
            </li>
          </ol>
          <h4 className="mt-3">15. LIMITATION OF LIABILITY</h4>
          <p className="p-2 ml-5">
            NEITHER MILER NOR ANY OTHER PARTY INVOLVED IN THE CREATION,
            DISTRIBUTION OR DISPLAY OF THE SERVICES OR ANY OTHER MATERIALS OR
            INFORMATION PROVIDED TO YOU BY MILER (COLLECTIVELY, “SUPPLIERS”) IS
            LIABLE FOR ANY LOST PROFITS OR ANY DIRECT, INDIRECT, PUNITIVE,
            INCIDENTAL, SPECIAL OR CONSEQUENTIAL DAMAGES ARISING OUT OF OR THAT
            RESULT FROM: (A) YOUR USE, INABILITY TO ACCESS OR USE OR RELIANCE ON
            THE SERVICES OR ANY OTHER MATERIALS PROVIDED TO YOU BY US, EVEN IN
            THE EVENT OF OUR NEGLIGENCE; OR (B) ANY TRANSACTION OR RELATIONSHIP
            BETWEEN YOU AND ANY OTHER USER OF THE SERVICES, EVEN IF WE HAVE BEEN
            ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. TO THE EXTENT PERMITTED
            BY LAW, THE TOTAL LIABILITY OF MILER AND ITS SUPPLIERS FOR ANY
            CLAIMS ARISING OUT OF YOUR USE OF THE SERVICES OR THIS AGREEMENT
            SHALL BE LIMITED TO THE AMOUNT YOU PAID US TO USE THE SERVICES. THE
            FOREGOING LIMITATIONS ON LIABILITY ARE INTENDED TO APPLY TO THE
            WARRANTIES AND DISCLAIMERS ABOVE AND ALL OTHER ASPECTS OF THIS
            AGREEMENT.
          </p>
          <h4 className="mt-3">16. RESTRICTED ACTIVITIES</h4>
          <p className="mt-2 ml-6">
            With respect to your use of the Services, you agree that you will
            not:
          </p>
          <ol className=" list-decimal p-2 ml-10 mt-2">
            <li> impersonate any person or entity;</li>
            <li>stalk, threaten, or otherwise harass any person;</li>
            <li>
              violate any law, statute, rule, permit, ordinance or regulation;
            </li>
            <li>
              interfere with or disrupt the Miler Software, Service Marketplace,
              or the servers or networks connected to the Miler Software and
              Service Marketplace;
            </li>
            <li>
              post Information or interact with any person in a manner which is
              fraudulent, libelous, abusive, obscene, profane, sexually
              oriented, harassing, or illegal;
            </li>
            <li>
              use the Miler Software or Service Marketplace in any way that
              infringes any third party’s rights, including: intellectual
              property rights, copyright, patent, trademark, trade secret or
              other proprietary rights or rights of publicity or privacy;
            </li>
            <li>
              post, email or otherwise transmit any malicious code, files or
              programs designed to interrupt, damage, destroy or limit the
              functionality of the Miler Software, Service Marketplace, or any
              computer software or hardware or telecommunications equipment or
              surreptitiously intercept or expropriate any system, data or
              personal information;
            </li>
            <li>
              forge headers or otherwise manipulate identifiers in order to
              disguise the origin of any information transmitted through the
              Miler Software or Service Marketplace
            </li>
            <li>
              “frame” or “mirror” any part of the Miler Software or Service
              Marketplace, without our prior written authorization or use meta
              tags or code or other devices containing any reference to us in
              order to direct any person to any other web site for any purpose;
            </li>
            <li>
              modify, adapt, translate, reverse engineer, decipher, decompile or
              otherwise disassemble any portion of the Miler Software or Service
              Marketplace;
            </li>
            <li>
              rent, lease, lend, sell, redistribute, license or sublicense the
              Miler Software or Service Marketplace or access to any portion of
              the Miler Software or Service Marketplace;
            </li>
            <li>
              use any robot, spider, site search/retrieval application, or other
              manual or automatic device or process to retrieve, index, scrape,
              “data mine”, or in any way reproduce or circumvent the
              navigational structure or presentation of the Miler Software or
              Service Marketplace or its contents;
            </li>
            <li>link directly or indirectly to any other web sites;</li>
            <li>
              transfer or sell your User account, password and/or
              identification, or any other User's Information to any other
              party;
            </li>
            <li>
              discriminate against or harass anyone on the basis of race,
              national origin, religion, gender, gender identity or expression,
              physical or mental disability, medical condition, marital status,
              age or sexual orientation; or
            </li>
            <li>
              cause any third party to engage in the restricted activities
              above.
            </li>
          </ol>
          <h4 className="mt-3">17. CUSTOMER DATA AND INFORMATION</h4>
          <p className="mt-3 p2 ml-5">
            Your Information is any information you provide, publish or post to
            or through the Service Marketplace or send to other users or any
            data generated from your use of the Miler Software (your
            “Information”). Our collection and use of the Information is as
            provided in Miler’s{" "}
            <Link
              to="/privacy"
              className="underline text-blue-600 cursor-pointer font-semibold"
            >
              Privacy Policy
            </Link>
            . Miler’s{" "}
            <Link
              to="/privacy"
              className="underline text-blue-600 cursor-pointer font-semibold"
            >
              Privacy Policy
            </Link>
            is incorporated in this Agreement by reference. By accepting this
            Agreement, you accept Miler’s{" "}
            <Link
              to="/privacy"
              className="underline text-blue-600 cursor-pointer  font-semibold"
            >
              Privacy Policy
            </Link>{" "}
            in full. You consent to us using your Information to create a user
            account that will allow you to use the Miler Software and Service
            Marketplace, and participate in the Services. With respect to the
            Service Marketplace, You are solely responsible for your Information
            and your interactions with other members of the public, and we act
            only as a passive conduit for your online posting of your
            Information. You agree to provide and maintain accurate, current and
            complete information and that we and other members of the public may
            rely on your Information as accurate, current and complete. To
            enable Miler to use your Information for the purposes described in
            the Privacy Policy and this Agreement, you grant to us a
            non-exclusive, worldwide, perpetual, irrevocable, royalty-free,
            transferable, sub-licensable (through multiple tiers) right and
            license to exercise the copyright, publicity, and database rights
            you have in your Information, and to use, copy, perform, display and
            distribute such Information to prepare derivative works, or
            incorporate into other works, such Information, in any media now
            known or not currently known. Miler does not assert any ownership
            over your Information; rather, as between you and Miler, subject to
            the rights granted to us in this Agreement, you retain full
            ownership of all of your Information and any intellectual property
            rights or other proprietary rights associated with your Information.
          </p>
          <h4 className=" mt-3">18. THIRD PARTY CONTENT AND SERVICES</h4>
          <p className="p-2 ml-5">
            The Services may be made available or accessed in connection with
            third party services and content that is not under Miler’s control
            and in no event will Miler be responsible or liable for any
            products, services or content of such third-party providers. You
            acknowledge that different terms of use and privacy policies may
            apply to Your use of such third-party services and content. MILER
            PROVIDES ANY THIRD-PARTY LINK OR INTERGRATION WITHIN THE SERVICES
            “AS IS” WITHOUT WARRANTY OF ANY KIND AND ONLY AS A CONVENIENCE. You
            acknowledge sole responsibility for and assumes all risk arising
            from its use of any third party websites, resources, products and/or
            services and any links or integrations made available thereto.
          </p>
          <h4 className="mt-3">19. INDEMNIFICATION</h4>
          <p className="p-2 ml-5">
            You agree to defend, indemnify and hold Miler and its employees,
            contractors, officers and managers harmless from all liabilities,
            claims and expenses, including without limitation attorneys’ fees,
            that arise out of or result from: (a) Your use or misuse of the
            Services or any other materials or information provided by Miler to
            You (whether such material or information originates from Miler or
            from a third party, including without limitation other users of the
            Services); (b) Your breach of this Agreement; (c) Miler’s use of any
            content submitted by You in connection with the Services; or (d)
            Your violation of any law or the rights of a third party.
          </p>
          <h4 className="mt-3">20. DISPUTE RESOLUTION</h4>
          <ol type="a" className="list-decimal p-2 ml-5">
            <li>
              <u>Agreement to Binding Arbitration Between You and Miler</u>. YOU
              AND MILER MUTUALLY AGREE TO WAIVE OUR RESPECTIVE RIGHTS TO
              RESOLUTION OF DISPUTES IN A COURT OF LAW BY A JUDGE OR JURY AND
              AGREE TO RESOLVE ANY DISPUTE BY ARBITRATION, as set forth below.
              Any dispute arising from or relating to the subject matter of this
              Agreement that cannot be resolved by the parties within a period
              of sixty (60) days after notice of a dispute has been given by one
              party hereunder to the other, shall be finally settled by
              arbitration in Johnson County, Kansas, United States, using the
              English language in accordance with the rules promulgated by the
              American Arbitration Association (the “AAA Rules”) then in effect,
              by one or more commercial arbitrator(s) with substantial
              experience in resolving complex commercial contract disputes. The
              parties agree that such arbitrator(s) shall have full authority to
              award preliminary and permanent injunctive relief, damages, and
              any other relief available in law, at equity, or otherwise
              pursuant to applicable law and that any emergency arbitrator(s)
              appointed in accordance with the AAA Rules shall have authority to
              grant emergency relief in accordance with such rules. ANY
              ARBITRATION UNDER THIS AGREEMENT WILL TAKE PLACE ON AN INDIVIDUAL
              BASIS; CLASS ARBITRATIONS AND CLASS ACTIONS ARE NOT PERMITTED.
              Except as expressly provided below, this Arbitration Agreement
              applies to all Claims (defined below) between you and Miler,
              including our affiliates, subsidiaries, parents, successors and
              assigns, and each of our respective officers, directors,
              employees, agents, or shareholders. This Arbitration Agreement
              also applies to claims between you and Miler’s service providers,
              including but not limited to background check providers and
              payment processors; and such service providers shall be considered
              intended third-party beneficiaries of this Arbitration Agreement.
            </li>
            <li>
              <u>Prohibition of Class Actions and Non-Individualized Relief</u>.
              YOU UNDERSTAND AND AGREE THAT YOU AND MILER MAY EACH BRING CLAIMS
              IN ARBITRATION AGAINST THE OTHER ONLY IN AN INDIVIDUAL CAPACITY
              AND NOT ON A CLASS, COLLECTIVE ACTION, OR REPRESENTATIVE BASIS
              (&ldquo;CLASS ACTION WAIVER&rdquo;). YOU UNDERSTAND AND AGREE THAT
              YOU AND MILER BOTH ARE WAIVING THE RIGHT TO PURSUE OR HAVE A
              DISPUTE RESOLVED AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED
              CLASS, COLLECTIVE OR REPRESENTATIVE PROCEEDING. The arbitrator
              shall have no authority to consider or resolve any Claim or issue
              any relief on any basis other than an individual basis. The
              arbitrator shall have no authority to consider or resolve any
              Claim or issue any relief on a class, collective, or
              representative basis. The arbitrator may award declaratory or
              injunctive relief only in favor of the individual party seeking
              relief and only to the extent necessary to provide relief
              warranted by that party's individual claims.
            </li>
          </ol>
          <h4 className="mt-3 font-semibold">21. TERMINATION</h4>
          <p className="p-2 ml-5">
            You may request to cancel the Services at any time. You may either
            make a cancellation request online through the Miler portal, by
            calling to speak with a Miler representative, or through a chat
            service, if applicable. Simply canceling the credit/debit card
            associated with a Miler account will not cancel the account.
            Cancellations will take effect immediately. No portion of a payment
            will be refunded or prorated at the time a cancellation request is
            made. You will remain liable for Service Marketplace Charges due and
            owing for Marketplace Services performed prior to termination.
            Customers are encouraged to keep records of all communications
            regarding cancellation. Cancellation of an account does not resolve
            outstanding amounts owed or nullify previously agreed upon charges
            or fees, such as payments charged in installments or annual contract
            charges, portions of which may not yet have been invoiced at the
            time of Your cancellation request. At the time of cancellation, any
            outstanding amounts owed to Miler must be settled. Miler may
            terminate Your use of the Services upon seven (7) days’ prior notice
            to You. Miler may terminate Your use of the Services immediately in
            the event that (a) You breach this Agreement or (b) Miler has the
            good faith belief that such action is necessary to protect the
            safety of the Miler community or third parties.
          </p>
          <h4 className="mt-3">22.GOVERNING LAW</h4>
          <p className="p-2 ml-5">
            This Agreement and any action related thereto will be governed by
            the laws of the State of Kansas without regard to its conflict of
            laws provisions. Exclusive jurisdiction and venue for actions
            related to this Agreement or Your use of the Services will be the
            state and federal courts located in Johnson County, Kansas, United
            States, and both parties consent to the jurisdiction of such courts
            with respect to any such actions.
          </p>
          <h4 className="mt-3">23. FORCE MAJEURE</h4>
          <p className="p-2 ml-5">
            Miller is not liable or responsible, nor shall be deemed to have
            defaulted under or breached this Agreement, for any failure to
            perform or delay in performing its obligations under this Agreement
            due to an event of force majeure. An event of force majeure is any
            event or circumstance beyond Miler’s reasonable control, such as
            war, hostilities, act of God, earthquake, flood, fire, or other
            natural disaster, strike or labor conditions, material shortage,
            pandemic (including the COVID-19 pandemic), epidemic, disease,
            government action, or failure of utilities, transportation
            facilities, or communication or electronic systems.
          </p>
          <h4 className="mt-3">24. GENERAL TERMS</h4>
          <p className="ml-5 p-2">
            This Agreement constitutes the entire and exclusive understanding
            and agreement between Miler and You regarding the Services, and this
            Agreement supersedes and replaces any and all prior oral or written
            understandings or agreements between Miler and You regarding the
            Services. If for any reason a court of competent jurisdiction finds
            any provision of this Agreement invalid or unenforceable, that
            provision will be enforced to the maximum extent permissible and the
            other provisions of this Agreement will remain in full force and
            effect. You may not assign or transfer this Agreement, by operation
            of law or otherwise, without Miler’s prior written consent, except
            in the case of a merger, acquisition, or sale of all or
            substantially all assets of Your company. Any attempt by You to
            assign or transfer this Agreement, without such consent, will be
            null. Miler may freely assign or transfer this Agreement without
            restriction. Subject to the foregoing, this Agreement will bind and
            inure to the benefit of the parties, their successors and permitted
            assigns. Any notices or other communications provided by Miler under
            this Agreement, including those regarding modifications to this
            Agreement, will be given in Miler’s sole discretion: (i) via email;
            (ii) by posting to Miler’s website; or (iii) by posting to the
            Services. Except as explicitly stated otherwise, any notices to
            Miler shall be given by certified mail, postage prepaid and return
            receipt requested to Miler, LLC, 10895 Lowell Avenue, Suite 205,
            Overland Park, Kansas 66210. Either party’s failure to enforce any
            right or provision of this Agreement will not be considered a waiver
            of such right or provision. The waiver of any such right or
            provision will be effective only if in writing and signed by a duly
            authorized representative of both parties. Except as expressly set
            forth in this Agreement, the exercise by either party of any of its
            remedies under this Agreement will be without prejudice to its other
            remedies under this Agreement or otherwise.
          </p>
          <h4 className="mt-3">25. CONTACT INFORMATION</h4>
          <p className="ml-5 p-2">
            If you have any questions about this Agreement or wish to submit
            feedback regarding the Services, please contact Miler at
            compliance@miler.com or by mail at Attn: Compliance, 10895 Lowell
            Ave, Ste 205, Overland Park, KS 66210.
          </p>
        </section>
      </section>
    </main>
  );
};

export default Terms;
