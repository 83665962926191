import { useState } from "react";
import NavBar from "../../../components/navBar/NavBar";
import Terms from "../../../components/terms/ProviderTerms";
import Footer from "../../../components/footer/Footer";

const ProviderTerms = () => {
  const [showSideBar, setShowSideBar] = useState(false);

  return (
    <>
      <NavBar showSideBar={showSideBar} setShowSideBar={setShowSideBar} />
       <Terms />
      <Footer />
    </>
  );
};

export default ProviderTerms;
