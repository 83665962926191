import { useState } from "react";
import Footer from "../../footer/Footer";
import NavBar from "../../navBar/NavBar";
import Banner from "../../pagesSection/Banner";
import HeroPageSection from "../../pagesSection/HeroSection";
import MoreSection from "../../pagesSection/MoreSection";
import Section from "../../pagesSection/Section";
import EDIImage from "../../../assets/images/EDI_Grid.png";
import SectionImage from '../../../assets/blog/optimization_licensed_2080661035.jpg'
import ShipperHeroImage from "../../../assets/images/Shippers Hero Image (1).png"
const ShipperSolution = () => {
  const [showSideBar, setShowSideBar] = useState(false);

  return (
    <>
      <section className="">
        <NavBar showSideBar={showSideBar} setShowSideBar={setShowSideBar} />
        {!showSideBar && (
          <>
            <section>
              <HeroPageSection
                title="Integrated TMS for "
                subscriptionPlan=""
                description="Simplify your domestic shipping operations with integrated tools for increased real- time visibility, streamlined business process, and cost reduction"
                subTitle=""
                registerTitle="shipper"
                heroImage={ShipperHeroImage}
                plansOptions={[
                  {
                    title: "",
                  },
                  {
                    title: "",
                  },
                ]}
              />
            </section>

            <MoreSection
              type="right"
              title="Shipper TMS"
              subtitle="All-in-One Transportation Management Platform"
              description="Revolutionize and take control of your shipping operations with Miler’s fully connected and cloud-based Transportation Management Software."
              descriptionTwo=""
              planTitle=""
              image={SectionImage}
              planOptions={[
                {
                  title: "Revolutionize Your RFQ Process",
                  description: [
                    "Miler streamlines your entire RFQ process, providing shippers with an effortless, user-friendly platform to create, distribute, and manage RFQs from one central hub. Stay ahead with real-time updates, swiftly review and compare bids, and select the best carrier with ease. Enjoy a more efficient and cost-effective shipping strategy today.",
                  ],
                },
                {
                  title:
                    "Seamless Interaction Across Your People, Brokers, and Carriers",
                  description: [
                    "Our connected platform facilitates seamless collaboration, allowing interaction not only within your team but also with partner carriers and brokers.",
                  ],
                },
                {
                  title: "Track & Trace Visibility",
                  description: [
                    "Miler platform offers tracking capabilities, providing real-time updates on shipment status, location, estimated time of arrival (ETA), and alert notifications for exceptions or delays.",
                  ],
                },
                {
                  title: "The Power of Cloud-Based Flexibility and Security",
                  description: [
                    "Experience the freedom and flexibility of a cloud-based platform, allowing you to access your data and applications from anywhere, at any time. With seamless scalability and enhanced security measures, it's the ideal solution for modern businesses looking to stay agile and competitive in today's digital landscape.",
                  ],
                },
              ]}
            />
            {/* <MoreSection
              type="left"
              title="Safety & Compliance"
              subtitle={
                "Comprehensive Solution for Carrier Safety and Compliance"
              }
              description={
                "With the right technology and leadership, your entire team can integrate safety and compliance into your organization's core business processes"
              }
              descriptionTwo={""}
              planTitle={""}
              planOptions={[
                {
                  title: "Keep documents, logs and reports, all in one place",
                  description: [
                    "Operate safely, stay compliant, and maintain organizational peace of mind.",
                    "Identify and mitigate risky drivers and unsafe equipments early and often.",
                    "Stand ready for any safety audit onsite or over the road.",
                    "Increase your safety score to lower your insurance and overall safety cost.",
                  ],
                },
                {
                  title: "Integrated with your TMS",
                  description: [
                    "Monitor and manage compliance and safety incidents.",
                    "Gain full visibility on safety issues, expirations, and violations.",
                    "Keep track of roadside inspections, their action items and remediations.",
                    "Track driver safety performance.",
                  ],
                },
              ]}
            /> */}

            {/* <Section
              side="right"
              title="EDI Integration"
              mainTitle="Fully Managed and Seamless EDI Integration"
              description="Many enterprise shippers require EDI connectivity before partnering with carriers. Miler offers seamless EDI integration. Serve your customers better and gain competitive advantage with EDI connectivity."
              image={EDIImage}
            /> */}
            {/* <MoreSection
              type="left"
              title="Fleet Maintenance"
              subtitle="Integrated Fleet Maintenance"
              description="Miler’s Fleet Maintenance solution helps carriers drive efficiency, keep their costs down and increase their bottom line."
              descriptionTwo=""
              planTitle=""
              planOptions={[
                {
                  title: "Service Repairs",
                  description: [
                    "Connect all your maintenance dots in a single dashboard.",
                    "Manage service repairs (work orders).",
                    "Mark vehicles and trailers for repair shop to notify dispatch team.",
                  ],
                },
                {
                  title: "Service Reminders",
                  description: [
                    "Setup service tasks and assign them to fleet categories.",
                    "Create reminders for regular maintenance.",
                  ],
                },
                {
                  title: "Parts Inventory and Vendor Management",
                  description: [
                    "Manage parts and parts inventory.",
                    "Manage parts vendors.",
                  ],
                },
                {
                  title: "Purchase Order Management",
                  description: [
                    "Manage purchase orders and keep track of their balance sheet related to parts acquisition.",
                  ],
                },
              ]}
            /> */}

            <Section
              side="right"
              title="EDI Integration"
              mainTitle="Fully Managed and Seamless EDI Integration"
              description="Miler offers seamless cloud-based EDI solution for your external TMS or ERP connectivity."
              image={EDIImage}
            />
            <Banner title="Ready to dive in?" />

            <Footer />
          </>
        )}
      </section>
    </>
  );
};

export default ShipperSolution;
