import React from "react";
import baseURL from "../config/Config";

const Sitemap: React.FC = () => {
  const urls: { url: string; changefreq: string; priority: string }[] = [
    { url: "/", changefreq: "monthly", priority: "1.0" },
    { url: "/solutions/carrier", changefreq: "monthly", priority: "0.9" },
    { url: "/solutions/broker", changefreq: "monthly", priority: "0.9" },
    { url: "/solutions/shipper", changefreq: "monthly", priority: "0.9" },
    { url: "/solutions/provider", changefreq: "monthly", priority: "0.9" },
    { url: "/about-us", changefreq: "monthly", priority: "0.8" },
    { url: "/request-demo", changefreq: "monthly", priority: "0.8" },
    { url: "/pricing", changefreq: "monthly", priority: "0.8" },
    { url: "/samsara-eld-tms", changefreq: "monthly", priority: "0.8" },
    { url: "/motive-eld", changefreq: "monthly", priority: "0.8" },
    { url: "/verizon-eld-tms", changefreq: "monthly", priority: "0.8" },
    { url: "/privacy", changefreq: "monthly", priority: "0.8" },
    { url: "/terms", changefreq: "monthly", priority: "0.8" },
    { url: "/sla", changefreq: "monthly", priority: "0.8" },
    { url: "/blogs", changefreq: "monthly", priority: "0.8" },
  ];

  const generateSitemapXML = (): string => {
    const urlSet = urls
      .map(
        ({ url, changefreq, priority }) => `
        <url>
          <loc>${baseURL}${url}</loc>
          <changefreq>${changefreq}</changefreq>
          <priority>${priority}</priority>
        </url>
      `
      )
      .join("");

    return `<?xml version="1.0" encoding="UTF-8"?>
    <urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">
      ${urlSet}
    </urlset>`;
  };

  return <pre>{generateSitemapXML()}</pre>;
};

export default Sitemap;
