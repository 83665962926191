interface InfoProps {
  title: string;
  subtitle: string;
  pageText: string;
  description: string;
}

const InfoSection = (props: InfoProps) => {
  const { title, subtitle, description, pageText } = props;
  return (
    <section className="bg-miler_light_blue h-[21rem] flex flex-col justify-center items-center max-sm:bg-miler_light_blue max-sm:w-screen max-md:w-screen ">
      <section className="flex flex-col justify-center items-center gap-y-2">
        <p className="text-miler_yellow text-base font-medium">{title}</p>
        <p className="text-5xl font-extrabold max-sm:text-xl max-sm:text-center max-md:text-2xl max-lg:text-3xl max-xl:text-4xl">
          {subtitle || "A short heading for"}{" "}
          <span className="text-5xl font-extrabold text-miler_yellow max-sm:text-xl">
            {pageText}
          </span>
        </p>
        <p className="w-[36rem] text-center text-miler_text_neutral max-sm:w-full max-sm:p-2">
          {description}
        </p>
      </section>
    </section>
  );
};

export default InfoSection;
