import cardData from "./data";

const Card = () => {
  return (
    <section className="grid grid-cols-2 w-full  place-items-center mt-2 gap-8 p-[3.5rem] max-sm:w-full bg-slate-50 max-sm:p-0 max-sm:mt-[1rem] max-sm:grid max-sm:grid-cols-1 max-md:grid max-md:grid-cols-1 max-lg:grid max-lg:grid-cols-2">
      {cardData.map((cardInfo, index) => {
        return (
          <section
            key={index}
            className="shadow-miler_card_shadow rounded-2xl flex flex-col justify-center items-center  bg-white p-3  max-sm:w-[90%] max-sm:p-2 max-lg:w-fit max-lg:p-2 max-xl:w-fit "
          >
            <p className="font-bold text-xl max-sm:text-base text-center">
              {cardInfo.title}
            </p>
            <p className="text-base antialiased leading-8 tracking-normal p-2 text-justify font-medium miler_black_color">
              {cardInfo.description}
            </p>
          </section>
        );
      })}
    </section>
  );
};

export default Card;
