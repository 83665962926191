import { useState } from "react";
import ScheduleDemo from "../../components/scheduleDemo/ScheduleDemo";
import Footer from "../../components/footer/Footer";
import NavBar from "../../components/navBar/NavBar";
const RequestDemoPage = () => {
  const [showSideBar, setShowSideBar] = useState(false);

  return (
    <>
      <NavBar showSideBar={showSideBar} setShowSideBar={setShowSideBar} />
      {!showSideBar && (
        <>
          <section className="grid grid-cols-2 w-full p-[5rem] max-sm:grid max-sm:grid-cols-1 max-smbg-slate-50 max-sm:p-[2rem] max-sm:leading-9 max-sm:tracking-normal max-md:grid max-md:grid-cols-1 max-sm:gap-10 max-lg:grid max-lg:grid-cols-1 max-xl:grid max-xl:grid-cols-2 ">
            <section className="flex flex-col gap-y-2 mt-">
              <p className="text-miler_blue text-4xl font-semibold antialiased">
                Schedule a demo
              </p>
              <p className="text-3xl leading-10 tracking-normal text-miler_text_color w-[35rem] antialiased max-sm:w-full max-sm:text-2xl max-md:w-full max-lg:w-full max-xl:w-[25rem]">
                Request a personalized demo and see how you can earn time,
                money, and freedom.
              </p>
            </section>

            <section className="shadow-md  rounded-sm">
              <ScheduleDemo />
            </section>
          </section>
          <Footer />
        </>
      )}
    </>
  );
};

export default RequestDemoPage;
