import clsx from "clsx";
import { ArrowRightIcon } from "@heroicons/react/20/solid";

interface button {
  title: string;
  size: "medium" | "large" | "small" | "mobile" | "sidebar" | "rounded" ;
  showIcon?: boolean;
  handleClick?: () => void;
}
const MilerButton = (props: button) => {
  const { title, size, showIcon, handleClick } = props;
  return (
    <section> 
      <button
        onClick={handleClick}
        className={clsx(
          "bg-miler_blue text-white antialiased flex flex-row justify-center items-center gap-x-2",
          {
            "px-3 w-full py-[0.3rem] rounded text-[0.85rem] text-left font-medium":
              size === "medium",
            "p-2 rounded w-[13rem]": size === "large",
            "p-3  rounded-full font-semibold text-[0.9rem]": size === "rounded",
            "w-[7.6rem] rounded-md p-2 h-[2.6rem]": size === "small",
            "w-fit bg-white text-black font-semibold": size === "mobile",
            "bg-miler_blue p-2 w-full text-white antialiased flex flex-row justify-center items-center gap-x-2":
              size === "sidebar",
          }
        )}
      >
        <p
          className={clsx("cursor-pointer", {
            "w-fit text-black font-bold text-xl": size === "mobile",

          })}
        >
          {title}
        </p>
        {showIcon && (
          <ArrowRightIcon
            className={clsx("w-4", {
              " text-black w-5": size === "mobile",
            })}
          />
        )}
      </button>
    </section>
  );
};

export default MilerButton;
