interface SectionProps {
  title: string;
  mainTitle: string;
  description: string;
  side: "right" | "left";
  image?: string;
}

const Section = (props: SectionProps) => {
  const { title, mainTitle, description, side, image } = props;
  return (
    <section>
      {side === "right" && (
        <section className="flex  flex-row mt-[2rem] px-[3.5rem] justify-left p-5 max-sm:grid max-sm:grid-cols-1 max-sm:gap-2 max-sm:px-[2rem] max-md:flex max-md:flex-col max-md:w-full max-lg:flex max-lg:flex-col">
          <section className="w-[50%] flex flex-col gap-y-2 max-sm:w-full max-md:w-full max-lg:w-full">
            <p className="text-miler_yellow text-base font-bold">{title}</p>
            <p className="font-bold text-2xl antialiased text-miler_blue max-sm:text-[0.9rem]">
              {mainTitle}
            </p>
            <p className="leading-8 antialiased w-[90%] text-left   max-sm:text-sm max-sm:leading-7 max-sm:w-full max-md:w-full max-lg:w-full">
              {description}
            </p>
          </section>
          <section className="w-[50%] max-md:w-full max-lg:w-full ">
            <img
              src={image}
              width={400}
              height={350}
              className="object-contain w-[80%] rounded max-sm:hidden max-md:w-[70%] max-lg:w-[70%]"
              alt="productimage"
            />
          </section>
        </section>
      )}

      {side === "left" && (
        <section className="flex flex-row justify-evenly p-5 max-sm:grid max-sm:grid-cols-1 max-sm:gap-2 ">
          <section>
            <p className="w-[38rem] h-[20rem] bg-miler_light_blue rounded max-sm:w-full"></p>
          </section>

          <section className="w-[38rem] flex flex-col gap-y-2 max-sm:w-full max-sm:mt-3">
            <p className="text-miler_yellow text-sm">{title}</p>
            <p className=" font-bold text-2xl antialiased text-miler_blue">
              {mainTitle}
            </p>
            <p className="antialiased text-miler_text_neutral lg:w-full xl:w-[90%]">
              {description}
            </p>
          </section>
        </section>
      )}
    </section>
  );
};

export default Section;
