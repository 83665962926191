import integrationData from "./data";
import { Link } from "react-router-dom";
const Integration = () => {
  return (
    <section className="bg-[#374151]">
      {integrationData.map((integration, index) => {
        return (
          <section key={index}>
            <section className="text-white flex flex-col justify-center items-center text-center gap-y-5 ">
              <p className="mt-10 antialiased font-bold text-4xl max-md:text-3xl">
                {integration.title}
              </p>
              <p className="w-[50%] antialiased leading-7 tracking-normal text-base max-sm:w-full max-sm:p-2 max-md:w-full max-md:px-[3rem]">
                {integration.description}
              </p>
            </section>
            <section className="flex flex-row justify-center items-center gap-x-6 flex-wrap w-[70%] m-auto max-sm:w-full max-sm:gap-3 max-md:mt-5 max-md:w-full">
              {integration.icon.map((icon, index) => {
                return (
                  <Link
                    to={icon.link && `/${icon.link}`}
                    key={index}
                    className="max-sm:mt-5 cursor-default"
                  >
                    <img
                      src={icon.icon}
                      width={160}
                      height={70}
                      alt={String(icon.title)}
                      className={
                        index === 0 || index === 8 || index === 3
                          ? `${
                              icon.link && "cursor-pointer"
                            } bg-white rounded-full h-[4.5rem] object-cover  max-sm:h-[3.4rem] `
                          : `${
                              icon.link &&
                              "cursor-pointer max-sm:object-contain   max-sm:h-fit"
                            }`
                      }
                    />
                  </Link>
                );
              })}
            </section>
          </section>
        );
      })}
    </section>
  );
};

export default Integration;
