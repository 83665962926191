import facebookLogo from "../../assets/logo/facebook.svg";
import xLogo from "../../assets/logo/x.svg";
import instagramLogo from "../../assets/logo/instagram.svg";
import linkedInLogo from "../../assets/logo/linkedin.svg";
interface subtitleData {
  title: string;
  link: string;
}

interface data {
  title: string;
  subtitle: subtitleData[];
}

interface socials {
  image: string;
  configureSize?: boolean;
  linkUrl: string;
}
const footerData: data[] = [
  {
    title: "Solutions",
    subtitle: [
      {
        title: "Carrier Solution",
        link: "Carrier",
      },
      {
        title: "Broker Solution",
        link: "Broker",
      },
      {
        title: "Shipper Solution",
        link: "Shipper",
      },
      {
        title: "Provider Solution",
        link: "Provider",
      },
      {
        title: "Pricing",
        link: "Pricing",
      },
    ],
  },

  // {
  //   title: "",
  //   subtitle: [
  //     {
  //       title: "Carriers",
  //       link: "Carrier",
  //     },
  //     {
  //       title: "Brokers",
  //       link: "Broker",
  //     },
  //     {
  //       title: "Shippers",
  //       link: "Shipper",
  //     },
  //     {
  //       title: "Service Providers",
  //       link: "ServiceProvider",
  //     },
  //   ],
  // },
  {
    title: "Resources",
    subtitle: [
      {
        title: "Blogs",
        link: "Blogs",
      },
      {
        title: "Help Center",
        link: "https://support.miler.com",
      },
    ],
  },
  {
    title: "Company",
    subtitle: [
      {
        title: "About Miler",
        link: "about-us",
      },

      // {
      //   title: "Contact",
      //   link: "Contact",
      // },
      {
        title: "Privacy Policy",
        link: "Privacy",
      },
      {
        title: "Terms of Use",
        link: "Terms",
      },
      {
        title: "SLA",
        link: "sla",
      },
    ],
  },
];

export const socialIcons: socials[] = [
  {
    image: facebookLogo,
    configureSize: true,
    linkUrl: "https://www.facebook.com/milersoftware/",
  },
  {
    image: xLogo,
    linkUrl: "https://twitter.com/milersoftware",
  },
  {
    image: linkedInLogo,
    linkUrl: "https://www.linkedin.com/company/milersoftware/",
  },
  {
    image: instagramLogo,
    linkUrl: "https://www.instagram.com/milersoftware/",
  },
];

export default footerData;
