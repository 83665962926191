import { useState } from "react";
import { Outlet } from "react-router-dom";
import Footer from "../footer/Footer";
import InfoSection from "../infoSection/InfoSection";
import NavBar from "../navBar/NavBar";
import Banner from "../pagesSection/Banner";
import MoreSection from "../pagesSection/MoreSection";
import Section from "../pagesSection/Section";
import ItemSection from "../ItemSection";
import SolutionCard from "../SolutionCard";

const Broker = () => {
  const [showSideBar, setShowSideBar] = useState(false);

  return (
    <section>
      <NavBar showSideBar={showSideBar} setShowSideBar={setShowSideBar} />
      {!showSideBar && (
        <>
          <InfoSection
            title=""
            description="Lorem ipsum dolor sit amet consectetur adipisicing elit. Tenetur, temporibus?"
            subtitle="A short heading for"
            pageText="solution"
          />

          {/* <TabSection hidePanel={true} /> */}
          <Section
            side="right"
            title="Problem"
            mainTitle="Describe the problems we solve"
            description=" Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsum harum, quae eum saepe exercitationem ratione?"
          />
          <Section
            side="left"
            title="Problem"
            mainTitle="Describe the problems we solve"
            description=" Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsum harum, quae eum saepe exercitationem ratione?"
          />

          <MoreSection
            type="left"
            title="Miler® Rectify+"
            subtitle="Simplified Solutions for Fleet Maintenance Service Providers"
            description="Lorem ipsum dolor sit amet consectetur adipisicing elit. In, nam!"
            planTitle="Innovative solutions to help you maximize ROI and bring you closer to your customers."
            planOptions={[
              {
                title: "Realtime Service Lead Matching and Notification",
                description: [
                  " Manage your onsite or mobile fleet maintenance service centers, dispatch or assign service technicians through our responsive web application or Miler Provider mobile application, and have a greater control of your operations and business workflow.",
                ],
              },
              {
                title: "Onsite and Mobile Service Centers",
                description: [
                  " Manage your onsite or mobile fleet maintenance service centers, dispatch or assign service technicians through our responsive web application or Miler Provider mobile application, and have a greater control of your operations and business workflow.",
                ],
              },
              {
                title: "Complete Customer Billing and Payment Platform",
                description: [
                  " Manage your onsite or mobile fleet maintenance service centers, dispatch or assign service technicians through our responsive web application or Miler Provider mobile application, and have a greater control of your operations and business workflow.",
                ],
              },
              {
                title:
                  "Comprehensive, fully integrated, cloud-based fleet maintenance system",
                description: [
                  " Manage your onsite or mobile fleet maintenance service centers, dispatch or assign service technicians through our responsive web application or Miler Provider mobile application, and have a greater control of your operations and business workflow.",
                ],
              },
            ]}
          />
          <SolutionCard
            side="right"
            heading="Proposition heading 1"
            itemList={[
              {
                title: "Short Heading",
                description:
                  "Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat, corrupti",
              },
              {
                title: "Short Heading",
                description:
                  "Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat, corrupti",
              },
              {
                title: "Short Heading",
                description:
                  "Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat, corrupti",
              },
            ]}
          />
          <SolutionCard
            side="left"
            heading="Proposition heading 2"
            itemList={[
              {
                title: "Short Heading",
                description:
                  "Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat, corrupti",
              },
              {
                title: "Short Heading",
                description:
                  "Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat, corrupti",
              },
              {
                title: "Short Heading",
                description:
                  "Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat, corrupti",
              },
            ]}
          />
          <ItemSection
            item={[
              {
                title: "Share team inboxes",
                description:
                  "Whether you have a team of 2 or 200, our shared team inboxes keep everyone on the same page and in the loop.",
              },
              {
                title: "Deliver instant answers",
                description:
                  "An all-in-one customer service platform that helps you balance everything your customers need to be happy.",
              },
              {
                title: "Manage your team with reports",
                description:
                  "Measure what matters with Untitled’s easy-to-use reports. You can filter, export, and drilldown on the data in a couple clicks.",
              },
              {
                title: "Connect with customers",
                description:
                  "Solve a problem or close a sale in real-time with chat. If no one is available, customers are seamlessly routed to email without confusion.",
              },
              {
                title: "Connect the tools you already use",
                description:
                  "Explore 100+ integrations that make your day-to-day workflow more efficient and familiar. Plus, our extensive developer tools.",
              },
              {
                title: "Our people make the difference",
                description:
                  "We’re an extension of your customer service team, and all of our resources are free. Chat to our friendly team 24/7 when you need help.",
              },
            ]}
          />
          <Banner />
          <Footer />
        </>
      )}
      <Outlet />
    </section>
  );
};

export default Broker;
