import { CheckIcon } from "@heroicons/react/20/solid";
import truck from "../../assets/images/miler_lic_customer_1687157581v2.jpg";
import CarrierTMS from "../../assets/images/4-devices.png";
interface options {
  title: string;
  description: string[];
}
interface MoreProps {
  type: "right" | "left";
  title: string;
  subtitle: string;
  description: string;
  descriptionTwo?: string;
  planTitle: string;
  planOptions: options[];
  image?: string;
}

const MoreSection = (props: MoreProps) => {
  const {
    title,
    subtitle,
    description,
    descriptionTwo,
    planTitle,
    planOptions,
    type,
    image,
  } = props;
  return (
    <>
      {subtitle && (
        <section className=" flex flex-col justify-center items-center gap-y-2 mt-[3rem] bg-white max-sm:mt-10">
          {title && (
            <section className="bg-miler_secondary_yellow_color w-fit p-1  text-center rounded-2xl text-sm font-semibold max-sm:text-sm max-sm:p-2">
              <p>{title}</p>
            </section>
          )}
          <p className=" text-[1.2rem] mt-3 text-center font-bold text-miler_blue max-sm:w-full max-sm:text-sm max-sm:px-[2rem]">
            {subtitle}
          </p>
          <section className="flex flex-col justify-center items-center text-left gap-y-2 max-sm:w-full max-sm:text-sm max-sm:px-[2rem] max-sm:leading-6 max-md:px-[2rem] max-md:leading-6">
            <p>{description}</p>
            <p>{descriptionTwo}</p>
          </section>
        </section>
      )}
      {type === "right" && (
        <section className="bg-white mt-[2rem] w-full max-sm:w-full max-sm:mt-[1rem] py-5 max-md:w-full">
          <section className="flex flex-row  max-sm:grid  max-sm:grid-cols-1 max-sm:px-[2rem] max-md:flex max-md:flex-col max-md:gap-8">
            <section className="flex  flex-col gap-y-3 w-[50%] px-[3.5rem] max-sm:p-0 max-sm:w-full max-sm:text-[0.9rem] max-md:w-full max-md:px-[2rem] ">
              <p className="font-bold text-miler_blue text-[1.2rem] w-full  max-sm:w-full max-sm:text-sm">
                {planTitle}
              </p>
              {planOptions.map((options, index) => {
                return (
                  <section key={index} className="flex flex-col gap-y-2">
                    <section className=" flex flex-row gap-x-1">
                      <CheckIcon className="w-4 " />
                      <p className="font-semibold text-miler_blue max-sm:w-full max-sm:text-sm">
                        {options.title}
                      </p>
                    </section>
                    <ul className="list-disc flex flex-col gap-y-1 w-full">
                      {options.description.map((content, index) => {
                        return (
                          <li
                            key={index}
                            className="leading-8 p-1 ml-10 text-left max-sm:w-[90%] max-sm:ml-8 max-sm:leading-7 "
                          >
                            {content}
                          </li>
                        );
                      })}
                    </ul>
                  </section>
                );
              })}
            </section>
            <section className="px-[3.5rem] w-[50%] max-md:w-full">
              <img
                src={image || CarrierTMS}
                alt="truck_image"
                width={650}
                height={800}
                className="max-sm:hidden object-cover max-md:m-auto"
              />
            </section>
          </section>
        </section>
      )}

      {type === "left" && (
        <section className="bg-white  w-full mt-10 max-sm:px-[2rem] max-sm:mt-5 py-5 max-md:py-0">
          <section className="flex flex-row  max-sm:grid  max-sm:grid-cols-1 max-md:flex max-md:flex-col">
            <section className=" flex flex-col gap-y-3 w-[43%] px-[3.5rem] max-md:px-0 ">
              <img
                src={image || truck}
                alt="truck_image"
                width={750}
                height={400}
                className="max-sm:hidden object-contain max-md:hidden"
              />
            </section>
            <section className="flex flex-col gap-y-3 w-[55%] px-[3.5rem] max-sm:p-0 max-sm:w-full max-sm:text-[0.9rem] max-md:w-full max-md:p-[2rem]">
              <p className="font-bold text-miler_blue text-[1.2rem] w-full  max-sm:w-full max-sm:text-sm">
                {planTitle}
              </p>
              {planOptions.map((options, index) => {
                return (
                  <section key={index} className="flex flex-col gap-y-2">
                    <section className=" flex flex-row gap-x-1">
                      <CheckIcon className="w-4 text-green-500" />
                      <p className="font-semibold text-miler_blue max-sm:w-full max-sm:text-sm">
                        {options.title}
                      </p>
                    </section>
                    <ul className="list-disc flex flex-col gap-y-1 w-full">
                      {options.description.map((content, index) => {
                        return (
                          <li
                            key={index}
                            className=" p-1 ml-10 text-left max-sm:w-[90%] max-sm:ml-8 max-sm:leading-7 "
                          >
                            {content}
                          </li>
                        );
                      })}
                    </ul>
                  </section>
                );
              })}
            </section>
            <section className=" flex flex-col gap-y-3 w-[43%] px-[3.5rem] max-md:w-full max-lg:hidden max-xl:hidden max-xxl:hidden ">
              <img
                src={image || truck}
                alt="truck_image"
                width={450}
                height={400}
                className="max-sm:hidden object-contain max-md:h-[25rem] max-lg:hidden max-xl:hidden max-xxl:hidden "
              />
            </section>
          </section>
        </section>
      )}
    </>
  );
};

export default MoreSection;
