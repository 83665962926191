import { useState } from "react";
import NavBar from "../../components/navBar/NavBar";
import HeroPageSection from "../../components/pagesSection/HeroSection";
import MoreSection from "../../components/pagesSection/MoreSection";
import ProviderImage from "../../assets/images/miler_licensed_image_truck_repair.webp";
import ProviderImage2 from '../../assets/images/miler_service_provider.png'
import ServiceProvider from "../../assets/images/miler_service_provider.png";
import Footer from "../../components/footer/Footer";
import Banner from "../../components/pagesSection/Banner";
import FleetMaintainceImage from '../../assets/images/Fleet Maintenance (1).png'

const Provider = () => {
  const [showSideBar, setShowSideBar] = useState(false);

  return (
    <>
      <NavBar showSideBar={showSideBar} setShowSideBar={setShowSideBar} />
      <HeroPageSection
        title="Simplified Solutions for Fleet Maintenance"
        subTitle="A fully integrated heavy-duty maintenance service scheduling and marketplace"
        subscriptionPlan=""
        registerTitle="heavy-duty maintenance service provider"
        description={""}
        heroImage={FleetMaintainceImage}
        plansOptions={[
          {
            title: "Get connected to your fleet service customers.",
          },
          {
            title: "Gain more service leads and more customers for life.",
          },
          {
            title: "Get paid immediately after service completion.",
          },
        ]}
      />
      <MoreSection
        type="left"
        title="Service Marketplace"
        subtitle="Miler Rectify Service Marketplace"
        description="Miler Rectify Service Marketplace is a first of its kind, innovative solution to bring heavy-duty service providers closer to their customers."
        descriptionTwo=""
        planTitle=""
        image={ProviderImage}
        planOptions={[
          {
            title: "Realtime Service Lead Matching and Notification",
            description: [
              "Improve operational efficiency and maximize your fleet maintenance business capacity and results with real-time visibility on nearby service requests.",
            ],
          },
          {
            title: "Onsite and Mobile Service Centers",
            description: [
              "Manage your onsite or mobile fleet maintenance service centers, dispatch or assign service technicians through our responsive web application or Miler Provider mobile application, and have a greater control of your operations and business workflow.",
            ],
          },
          {
            title: "Complete Customer Billing and Payment Platform",
            description: [
              "Miler partnered with Stripe to bring you a comprehensive payment platform that is easy and convenient for service providers and fleet owners.",
            ],
          },
          {
            title:
              "Comprehensive, fully integrated, cloud-based fleet maintenance system",
            description: [
              "Manage your repair orders, service appointments, and service technicians. Track your revenue, manage your accounting with simplified tax reporting. Invoice customers, and request payment.",
            ],
          },
        ]}
      />
      <MoreSection
        type="right"
        title=""
        subtitle="Miler Rectify Service Marketplace gives you leverage and discovers customers for you at the time of their need for your services"
        description=""
        descriptionTwo=""
        planTitle=""
        image={ProviderImage2 || ServiceProvider}
        planOptions={[
          {
            title: "Only In-Proximity, Relevant and Ready to Buy Customers",
            description: [
              "You will only be matched to service leads from pre-registered carriers and fleet owners who have ligitimate service & repair needs of your services for their in-proximity fleet. This means less wasted time, less phone calls for your and your customers.",
            ],
          },
          {
            title: "Increased Efficiency",
            description: [
              "Carriers and fleet owners spend considerable time and effort with hundreds of phone minutes trying to locate service providers during vehicle breakdown. Less of that is more time for you and your customers doing productive business..",
            ],
          },
          {
            title: "Connected Systems",
            description: [
              "Carriers and fleet owners make their service requests through the Miler platform. Depending on their level of access, they can view service history, invoices, and fleet maintenance costs.",
            ],
          },
        ]}
      />

      {/* <section className=" flex flex-row bg-slate-100 mt-10 px-[3.5rem] gap-x-5 py-8 max-sm:flex max-sm:flex-col max-sm:w-full">
        <section className="w-[50%] flex flex-col gap-y-4 leading-8  text-base max-sm:w-full max-sm:text-left">
          <p className="w-[90%] text-3xl font-semibold text-miler_blue max-sm:w-full max-md:w-full">
            You want to leverage technology to scale
          </p>
          <p className="">
            Whether a small or medium mobile fleet maintenance or corner garage.
            Miler Rectify Service Marketplace gives you all the ingriendients to
            scale your heavy-duty fleet maintenance operations.
          </p>

          <p className=" font-semibold text-miler_blue">
            Complete and <span className=" underline">Free</span> Service Center
            Management Software
          </p>

          <p className="font-semibold text-miler_blue">Technician Mobile App</p>

          <p className="font-semibold text-miler_blue">
            Increased Visibility into your Service Center Operations
          </p>

          <p className="font-semibold text-miler_blue">
            Scalable business process and maintenance service workflow
          </p>
        </section>

        <section className=" w-[50%] flex flex-col gap-y-6 ml-10 max-sm:w-full max-sm:ml-0 max-sm:mt-4 ">
          <p className="text-3xl font-semibold text-miler_blue">
            You want to maximize your ROI
          </p>

          <p className="text-miler_blue">
            For established full-shop service centers, Miler Rectify Service
            Service Marketplace can be a revenue multiplier.
          </p>

          <p className="font-semibold text-miler_blue">
            Additional source of ready-to-buy service leads
          </p>

          <p className="font-semibold text-miler_blue">
            Continuously Increasing customer base and customer life-time values
          </p>

          <p className="font-semibold text-miler_blue">
            Quick and convenient payment platform for your customers.
          </p>

          <p className="font-semibold text-miler_blue">
            Explore our integration capabilities (APIs) for possible integration
            with your system
          </p>
        </section>
      </section> */}

      {/* <section className=" flex flex-row bg-[#102E69] max-sm:flex max-sm:flex-col">
        <section className=" flex flex-col w-[50%] text-white gap-y-2 p-[3.55rem] mt-[10rem] leading-7 max-sm:w-full max-sm:text-left max-sm:p-[2.5rem] max-sm:mt-0">
          <p className=" font-semibold text-3xl">Get Started for Free!</p>
          <p className=" font-normal">
            Start receiving service lead matches from heavy-duty fleet owners
            and operators in your area once you complete your registration and
            onboarding.
          </p>
          <p>
            It’s free to get started. You’ll only get charged a platform fee
            after service requests are successfully matched and accepted by you
            through our platform.
          </p>
        </section>
        <section className=" w-[50%] overflow-hidden max-sm:w-full max-sm:overflow-y-hidden ">
          <iframe
            className="bg-transparent w-full border-0  overflow-y-hidden"
            width="768"
            height="850"
            src="https://subscription.miler.com/subscribe/4f09941115733a4e7609132dccc9891de191e0d78598307f897808dcdcab122b/MRPV-301"
          ></iframe>
        </section>
      </section> */}
      <Banner />
      <Footer />
    </>
  );
};

export default Provider;
