import React from 'react';

interface WrapperComponentProps {
  children: React.ReactNode;
  className?: string;
}

const WrapperComponent: React.FC<WrapperComponentProps> = ({ children, className = '' }) => {
  return (
    <div className={`p-[3.5rem] m-4  max-sm:p-2 max-sm:ml-4 max-sm:h-full ${className}`}>
      {children}
    </div>
  );
};

export default WrapperComponent;
