import { EnvelopeIcon } from "@heroicons/react/20/solid";

interface ItemList {
  title: string;
  description: string;
}

interface Item {
  item: ItemList[];
}
const ItemSection = (props: Item) => {
  const { item } = props;
  return (
    <section className="flex flex-col justify-center items-center gap-y-2 bg-miler_light_blue mt-5">
      <section className=" flex flex-col justify-center items-center mt-5 gap-y-2">
        <p className="text-miler_yellow text-sm">HIGHLIGHT</p>
        <p className="w-[56rem] text-center text-miler_blue antialiased text-2xl max-sm:w-[70%] max-sm:text-base max-sm:font-semibold">
          All-in-one platform for your entire team to work through one system
        </p>
        <p className="w-[48rem] text-miler_text_neutral antialiased text-center max-sm:w-fit">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Non eius
          cumque rerum facere ipsum laudantium?
        </p>
      </section>

      <section className="grid grid-cols-3 place-items-center gap-4 p-4 mt-4 max-sm:grid-cols-1 max-lg:grid-cols-2 max-xl:grid-cols-2">
        {item.map((list, index) => {
          return (
            <section
              key={index}
              className="flex flex-col items-center justify-center w-[24rem]"
            >
              <section className="flex flex-col gap-y-2 justify-center items-center ">
                <EnvelopeIcon className="w-4 text-miler_yellow" />
                <p className="font-semibold text-miler_blue">{list.title}</p>
              </section>
              <p className="w-[24rem] antialiased text-miler_text_neutral text-sm p-2 leading-7 tracking-normal">
                {list.description}
              </p>
            </section>
          );
        })}
      </section>
    </section>
  );
};

export default ItemSection;
