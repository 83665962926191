import { Link } from "react-router-dom";
import { ArrowRightIcon } from "@heroicons/react/20/solid";
import { Blog } from "../../App";

interface BlogsProps {
  blogs: Blog[];
}

const trimText = (description: string, maxLength = 80) => {
  if (description.length <= maxLength) return description;

  const trimmedDescription = description.slice(0, maxLength);
  const lastSpaceIndex = trimmedDescription.lastIndexOf(" ");

  // Ensure we don't cut off in the middle of a word
  return `${trimmedDescription.slice(0, lastSpaceIndex)}...`;
};

const BlogCard: React.FC<BlogsProps> = ({ blogs }) => {
  return (
    <section className="grid grid-cols-4 p-[3.5rem] place-items-center pb-5 max-sm:grid-cols-1 max-md:grid max-md:p-2 max-md:grid-cols-2 max-md:mt-5 max-lg:grid-cols-2 max-xl:grid-cols-3 max-xl:gap-4">
      {blogs.map((blog) => (
        <Link
          to={`/blog/${blog.slug.current}`}
          key={blog._id}
          className="flex flex-col shadow-md rounded bg-white w-[19rem] h-[25rem] cursor-pointer"
        >
          <img
            src={blog.mainImage?.asset?.url}
            alt="blog_image"
            width={300}
            height={100}
            className="object-cover w-full h-[12rem]"
          />
          <section className="flex-1 p-2 flex flex-col gap-y-2">
            <p className="font-semibold text-miler_blue">{blog.title}</p>
            <p className="text-miler_text_neutral">
              {trimText(blog.description)}
            </p>
          </section>
          <section className="flex flex-row gap-x-2 p-2">
            <p className="text-miler_blue text-sm antialiased font-bold">
              Read More
            </p>
            <ArrowRightIcon className="w-4" />
          </section>
        </Link>
      ))}
    </section>
  );
};

export default BlogCard;
