import { Link } from "react-router-dom";
import "../../index.css";
const ServiceLeveLAgreement = () => {
  return (
    <main className="privacy_page text-[1.125rem] font-[400] text-justify">
      <section className="p-[5rem] font-[400] max-sm:p-[1rem] max-md:p-[1.5rem]">
        <p className="font-[500]  text-center leading-7 tracking-wide text-3xl max-sm:text-xl max-md:text-2xl">
          MILER SOFTWARE SERVICE LEVEL AGREEMENT
        </p>
        <section className="mt-10">
          <span className="font-[400]">THIS SERVICE LEVEL AGREEMENT</span>{" "}
          (hereinafter referred to as the “SLA”), sets forth Miler’s obligations
          and Customer&#39;s rights with respect to the performance of Miler
          Software. This SLA is subject to and part of the{" "}
          <Link to={"/terms"} className="mt-3 underline text-[#0000FF]">
            {" "}
            Terms of Use
          </Link>{" "}
          (&quot;Terms&quot;) governing Customer&#39;s use of Miler Software,
          which, unless otherwise agreed between Customer and Miler, are
          Miler&#39;s standard{" "}
          <Link to={"/terms"} className="mt-3 underline text-[#0000FF]">
            {" "}
            Terms of Use
          </Link>
          . All capitalized terms used but not defined in this SLA have the
          meaning set forth in the Terms.
        </section>

        <section className="mt-3">
          <p>
            Miler Software is designed to provide reliable service to our
            customers. We have invested in technology and security
            infrastructure that meets or exceeds industry standards to safeguard
            customer data and to prevent downtime. Miler Software runs on a
            secure, scalable and redundant cloud computing infrastructure used
            by the world’s largest enterprises.
          </p>
        </section>
        <section className="mt-3">
          <p>
            We are pleased to offer our customers (each a &quot;Customer&quot;)
            the following 99.99% uptime SLA:
          </p>
        </section>

        <section>
          <p className="font-[400] mt-3">
            <span>1.</span> Definitions
          </p>
          <p className="mt-4">
            For purposes of this SLA, the following terms have the meaning
            ascribed to each term below:
          </p>
          <p className="mt-4">
            <span className=" font-[400]"> “Downtime”</span> means when the
            Customer is unable to log into the Miler Software dashboard due to
            failure(s) in Miler Software, as confirmed by both Customer and
            Miler. Please note external failures outside of Miler Software that
            may impact individual services within Miler Software are not
            considered Downtime.
          </p>
          <p className=" mt-4">
            <span className=" font-[400]">
              “Attributable Monthly Subscription Fee”
            </span>{" "}
            means the base monthly fee or one-twelfth of the base annual fee for
            the Services.
          </p>
          <p className=" mt-4">
            <span className=" font-[400]">“Monthly Uptime Percentage”</span>{" "}
            means the total number of minutes in a calendar month minus the
            number of minutes of Downtime suffered in a calendar month, divided
            by the total number of minutes in a calendar month.
          </p>
          <p className=" mt-4">
            <span className=" font-[400]">“Service Credit”</span> means the
            percentage of the Attributable Monthly Subscription Fees that Miler
            will credit to Customer in the form of a monetary credit applied to
            Customer’s invoice after receipt of timely written notice of Miler’s
            failure to meet the Service Level Warranty, as required by Section 3
            herein.
          </p>
        </section>

        <section className="mt-4">
          <p className=" font-[400] mt-3">
            <span className=" no-mt-3">2.</span> Service Level Warranty
          </p>
          <p className=" mt-4">
            During each calendar month, Miler Software will have a Monthly
            Uptime Percentage of at least 99.99% in any calendar month (the
            “Service Level Warranty”). If the Monthly Uptime Percentage does not
            meet the Service Level Warranty in any calendar month, and if
            Customer is in compliance with its obligations under the Terms and
            this SLA, then Customer will be eligible to receive a Service Credit
            as follows:
          </p>
          <table className="min-w-[40%]  border-collapse border border-gray-300 font-[400] mt-5">
            <thead className=" ">
              <tr className="bg-miler_light_blue">
                <th className="p-2 border border-gray-300 text-left font-[400]">
                  Monthly Uptime Percentage
                </th>
                <th className="p-2 border border-gray-300 text-left font-[400]">
                  Service Credit
                </th>
              </tr>
            </thead>
            <tbody>
              <tr className="border-b  border-r  ">
                <td className="p-2">&lt; 99.99% and ≥ 99.5%</td>
                <td className="p-2 border-l border-gray-300  ">10%</td>
              </tr>
              <tr className="border-b border-r">
                <td className="p-2">&lt; 99.5% and ≥ 99.0%</td>
                <td className="border-l border-gray-300 p-2 ">20%</td>
              </tr>
              <tr className="border-b">
                <td className="p-2">&lt; 99.0% and ≥ 90.0%</td>
                <td className="border-l border-gray-300 p-2">50%</td>
              </tr>
              <tr>
                <td className="p-2">&lt; 90.0%</td>
                <td className="border-l border-gray-300 p-2 ">100%</td>
              </tr>
            </tbody>
          </table>
        </section>
        <section className="mt-5">
          <p className="font-[400] mt-3">
            <span>3.</span> Customer Must Request Service Credit
          </p>
          <p className=" mt-4">
            In order to receive any of the Service Credits described above,
            Customer must notify Miler in writing within thirty (30) days from
            the time Customer becomes eligible to receive a Service Credit.
            Failure to comply with this requirement will forfeit Customer’s
            right to receive a Service Credit.
          </p>
        </section>
        <section className="mt-4">
          <p className="font-[400] mt-3">
            <span>4.</span> Maximum Service Credit
          </p>
          <p className=" mt-4">
            In order to receive any of the Service Credits described above,
            Customer must notify Miler in writing within thirty (30) days from
            the time Customer becomes eligible to receive a Service Credit.
            Failure to comply with this requirement will forfeit Customer’s
            right to receive a Service Credit.
          </p>
        </section>

        <section className="mt-4">
          <p className="font-[400] mt-3">
            <span>5.</span> Exclusions
          </p>
          <p className="mt-4">
            The Service Level Warranty does not apply to any Services that
            expressly exclude this Service Level Warranty (as stated in the
            Documentation for such Services) or any Downtime caused in part or
            in full by any of the following: (i) strikes (other than strikes of
            party’s own employees), shortages, riots, insurrection, fires,
            flood, storm, explosions, acts of God, war, governmental action,
            labor conditions (other than with respect to a party’s own
            employees), earthquakes, material shortages, epidemic, disease,
            failure of utilities or communication or electronic systems, or any
            other causes that are beyond the reasonable control of a party so
            long as the parties use commercially reasonable efforts, including
            the implementation of business continuity measures, to mitigate the
            effects of such force majeure; (ii) Customer and/or third party
            Equipment, systems, networks, or infrastructure (not within the
            primary control of Miler); (iii) Customer’s breach of the Terms or
            this SLA or improper use of the Services; (iv) a third party
            cloud-hosting, cellular, or internet service provider; (v) any
            actions or inactions of Customer or any other third party not under
            the direct control of Miler, (vi) attacks (i.e. hacks, denial of
            service attacks, malicious introduction of viruses and disabling
            devices) caused by third parties, or (vii) any interruption of five
            (5) consecutive minutes or less.
          </p>
        </section>

        <section>
          <p className="font-[400] mt-3">
            {" "}
            <span>6.</span> Exclusive Remedy
          </p>
          <p className="mt-4">
            This SLA states Customer’s sole and exclusive remedy for any failure
            by Miler to meet the Service Level Warranty.
          </p>
        </section>
        <section className="mt-4">
          <p className="font-[400] mt-3">
            <span>7.</span> Support Services
          </p>
          <p className="mt-4">
            Miler is committed to providing excellent customer support services
            to assist Customer in resolving issues in Miler Software (“Support
            Services“). Support Services do not include: (a) physical
            installation of the Miler Software and any Documentation; (b) visits
            to Customer’s site; (c) any professional services associated with
            the Services, including, without limitation, any custom development,
            data modeling, training and knowledge transfer; or (d) the set-up,
            configuration and use of the Services
          </p>
        </section>

        <section className="mt-4">
          <p className="font-[400] mt-3">
            <span>8.</span> Support Ticket Prioritization and Response Time
          </p>
          <p className="mt-4">
            Any Miler Software issues reported by Customer to Miler and accepted
            by the Miler support team will be classified as an Issue or a
            Feature Request and assigned a priority. Priority levels and their
            respective response times are listed below:
          </p>
          <table className="min-w-[42%]  border-collapse border border-gray-300 font-[400] mt-5">
            <thead>
              <tr className="bg-miler_light_blue ">
                <th className=" py-2 border border-gray-300 text-left p-3 font-[400]">
                  Priority
                </th>
                <th className="py-2 border border-gray-300 text-left p-3 font-[400]">
                  Description
                </th>
                <th className="py-2 border border-gray-300 text-left p-3 font-[400]">
                  Response Time
                </th>
              </tr>
            </thead>
            <tbody className="p-3">
              <tr className="border-b  border-r-2">
                <td className="border-l border-gray-300 p-3">
                  P0 - Business Critical
                </td>
                <td className="border-l border-gray-300 p-3">
                  Site down – no workaround
                </td>
                <td className="border-l border-gray-300 p-3">30 Minutes</td>
              </tr>
              <tr className="border-b  border-r-2">
                <td className="border-l border-gray-300 p-3">P1 - Urgent</td>
                <td className="border-l border-gray-300 p-3">
                  Major functionality is severely impaired
                </td>
                <td className="border-l border-gray-300 p-3">1 Hour</td>
              </tr>
              <tr className="border-b  border-r">
                <td className="border-l border-gray-300 p-3">
                  P2 - System Impaired
                </td>
                <td className="border-l border-gray-300 p-3">
                  Partial, non-critical loss of functionality
                </td>
                <td className="border-l border-gray-300 p-3">4 Hours</td>
              </tr>
            </tbody>
          </table>
        </section>
        <p className=" mt-4">
          Miler is not obligated to provide support if: (a) the Services have
          not been used in a manner consistent with the documentation; (b) the
          Services have been altered or modified by any party other than Miler;
          (c) an upgrade, update or patch that Miler has recommended and made
          available has not been installed due to Customer’s actions or
          inactions; or (d) Miler is not able, after commercially reasonable
          efforts, to replicate an error or problem in the Miler Software. Miler
          does not provide support for third-party software or hardware.
        </p>
      </section>
    </main>
  );
};

export default ServiceLeveLAgreement;
