interface SubscriptionItems {
  title: string;
}

interface PricingData {
  id: number;
  subscription: string;
  subscriptionType: string;
  price: string;
  priceTye: string;
  subscriptionList: SubscriptionItems[];
  subscriptionSolution?: string;
  product?: string;
  plan?: string;
}

const priceInfo: PricingData[] = [
  {
    id: 1,
    subscription: "Miler Rectify+",
    subscriptionType: "Maintenance",
    price: "$7",
    priceTye: "per truck",
    subscriptionSolution: "Carrier",
    product: "a2f078838857b1df381599a5560baaf72d1433f07e0c1f74ad482bf477cf719e",
    plan: "MLRA-32001",
    subscriptionList: [
      {
        title: "Maintenance Dashboard",
      },
      {
        title: "Unlimited Users",
      },
      {
        title: "Unlimited Trailers",
      },
      {
        title: "Equipment Inspections",
      },
      {
        title: "Service Request",
      },
      {
        title: "Service Repair Orders",
      },
      {
        title: "Service Reminder Workflow",
      },
      {
        title: "Parts Management",
      },
      {
        title: "Vendor Management",
      },
      {
        title: "Fleet Management",
      },
      {
        title: "Free Support",
      },
    ],
  },
  {
    id: 2,
    subscription: "Miler Amplify",
    subscriptionType: "TMS",
    price: "$19.99",
    priceTye: "per truck",
    subscriptionSolution: "Carrier",
    product: "a2f078838857b1df381599a5560baaf72d1433f07e0c1f74ad482bf477cf719e",
    plan: "MLRA-31001",
    subscriptionList: [
      {
        title: "Dispatch system",
      },
      {
        title: "Unlimited Users",
      },
      {
        title: "Unlimited Trailers",
      },
      {
        title: "Customer Management",
      },

      {
        title: "Shipment Management",
      },

      {
        title: "Fleet Management",
      },

      {
        title: "Shipment and Fleet Tracking",
      },
      {
        title: "Billing and Settlement",
      },
      {
        title: "Notifications",
      },
      {
        title: "Service Request",
      },
      {
        title: "Powerful Insights",
      },
      {
        title: "Performance Management",
      },
      {
        title: "Free Support",
      },
    ],
  },
  {
    id: 3,
    subscription: "Miler Unify",
    subscriptionType: "TMS + Maintenance + Safety & Compliance",
    price: "$28",
    priceTye: "per truck",
    subscriptionSolution: "Carrier",
    product: "a2f078838857b1df381599a5560baaf72d1433f07e0c1f74ad482bf477cf719e",
    plan: "MLRA-33001",
    subscriptionList: [
      {
        title: "Everything in Miler Rectify+",
      },
      {
        title: "Everything in Miler Amplify",
      },
      {
        title: "Safety Overview Dashboard",
      },
      {
        title: "Safety Expiration Monitoring",
      },
      {
        title: "Safety Incident Management",
      },
      {
        title: "Compliance Documents",
      },
      {
        title: "Roadside Inspections",
      },
      {
        title: "Driver Logs",
      },
      {
        title: "Driver Drug & Alcohol",
      },
    ],
  },
];

export const brokerPricingPlan: PricingData[] = [
  {
    id: 1,
    subscription: "Starter",
    subscriptionType:
      "This plan is for growing brokers leveraging technology and private network to reach the next level.",
    price: "$145",
    priceTye: " ",
    subscriptionSolution: "Broker",
    product: "4f09941115733a4e7609132dccc9891dcbf7b178e6af0857494e2c5ddd3f0051",
    plan: "M3PL-301",
    subscriptionList: [
      {
        title: "100 Included Shipments",
      },
      {
        title: "$4.95 Additional Shipment Charge",
      },
      {
        title: "Unlimited Users",
      },
      {
        title: "Unlimited Carrier Portals",
      },
      {
        title: "Complete Dispatch System",
      },
      {
        title: "Customer Relationship Management",
      },
      {
        title: "Carrier Profile Management",
      },
      {
        title: "Secure Document Store",
      },
      {
        title: "Shipment Management",
      },
      {
        title: "Shipment Track & Tracing",
      },
      {
        title: "Shipment Marketplace",
      },
      {
        title: "Tender Management",
      },
      {
        title: "RFQ Management",
      },
      {
        title: "System Notifications",
      },
      {
        title: "Reports and Insights",
      },
      {
        title: "Integrations",
      },
      {
        title: "Free Support",
      },
    ],
  },
  {
    id: 2,
    subscription: "Standard",
    subscriptionType:
      "This plan is for established brokers leveraging technology and private network with the intent to scale.",
    price: "$950",
    priceTye: "",
    subscriptionSolution: "Broker",
    product: "4f09941115733a4e7609132dccc9891d64ca422e9690a35fb7d3f8995def816b",
    plan: "MLRB-61001",
    subscriptionList: [
      {
        title: "500 Included Shipments",
      },
      {
        title: "$3.95 Additional Shipment Charge",
      },
      {
        title: "Unlimited Users",
      },
      {
        title: "Unlimited Carrier Portals",
      },
      {
        title: "Complete Dispatch System",
      },
      {
        title: "Customer Relationship Management",
      },
      {
        title: "Carrier Profile Management",
      },
      {
        title: "Secure Document Store",
      },
      {
        title: "Shipment Management",
      },
      {
        title: "Shipment Track & Tracing",
      },
      {
        title: "Shipment Marketplace",
      },
      {
        title: "Tender Management",
      },
      {
        title: "RFQ Management",
      },
      {
        title: "System Notifications",
      },
      {
        title: "Reports and Insights",
      },
      {
        title: "Integrations",
      },
      {
        title: "Free Support",
      },
    ],
  },
  {
    id: 3,
    subscription: " Enterprise",
    subscriptionType:
      "This plan is for enterprise brokers wanting org-wide adoption for increased operational visibility and cost efficiency.",
    price: "$2,750",
    priceTye: " ",
    subscriptionSolution: "Broker",
    product: "4f09941115733a4e7609132dccc9891d398d23d2f73003bf027cc62133d9423d",
    plan: "MLRB-66001",
    subscriptionList: [
      {
        title: "1000 Included Shipments",
      },
      {
        title: "$2.95 Additional Shipment Charge",
      },
      {
        title: "Unlimited Users",
      },
      {
        title: "Unlimited Carrier Portals",
      },
      {
        title: "Complete Dispatch System",
      },
      {
        title: "Customer Relationship Management",
      },
      {
        title: "Carrier Profile Management",
      },
      {
        title: "Secure Document Store",
      },
      {
        title: "Shipment Management",
      },
      {
        title: "Shipment Track & Tracing",
      },
      {
        title: "Shipment Marketplace",
      },
      {
        title: "Tender Management",
      },
      {
        title: "RFQ Management",
      },
      {
        title: "System Notifications",
      },
      {
        title: "Reports and Insights",
      },
      {
        title: "Integrations",
      },
      {
        title: "Free Support",
      },
    ],
  },
];

export const shipperPricingPlan: PricingData[] = [
  {
    id: 1,
    subscription: "Starter",
    subscriptionType:
      "This plan is for growing shippers and 3PLs leveraging technology and private network to reach the next level.",
    price: "$125",
    priceTye: " ",
    subscriptionSolution: "Shipper",
    product: "4f09941115733a4e7609132dccc9891da6018b5446c1e5f3a3736e575b0b28dd",
    plan: "M1PL-7101",
    subscriptionList: [
      {
        title: "100 Included Shipments",
      },
      {
        title: "$4.95 Additional Shipment Charge",
      },
      {
        title: "Unlimited Users",
      },
      {
        title: "Unlimited Carrier Portals",
      },
      {
        title: "Complete Dispatch System",
      },

      {
        title: "Carrier Profile Management",
      },
      {
        title: "Secure Document Store",
      },
      {
        title: "Shipment Management",
      },
      {
        title: "Shipment Track & Tracing",
      },
      {
        title: "Shipment Marketplace",
      },
      {
        title: "Tender Management",
      },
      {
        title: "RFQ Management",
      },
      {
        title: "System Notifications",
      },
      {
        title: "Reports and Insights",
      },
      {
        title: "Integrations",
      },
      {
        title: "Free Support",
      },
    ],
  },
  {
    id: 2,
    subscription: " Enterprise",
    subscriptionType:
      "This plan is for enterprise shippers and 3PLs wanting org-wide adoption for increased operational visibility and cost efficiency.",
    price: "$2,250",
    priceTye: "",
    subscriptionSolution: "Shipper",
    product: "4f09941115733a4e7609132dccc9891d938a4aa64b216cc08e20721c6c09c65f",
    plan: "M1PL-7701",
    subscriptionList: [
      {
        title: "1000 Included Shipments",
      },
      {
        title: "$2.95 Additional Shipment Charge",
      },
      {
        title: "Unlimited Users",
      },
      {
        title: "Unlimited Carrier Portals",
      },
      {
        title: "Complete Dispatch System",
      },
      {
        title: "Secure Document Store",
      },
      {
        title: "Shipment Management",
      },
      {
        title: "Shipment Track & Tracing",
      },
      {
        title: "Shipment Marketplace",
      },
      {
        title: "Tender Management",
      },
      {
        title: "RFQ Management",
      },
      {
        title: "System Notifications",
      },
      {
        title: "Reports and Insights",
      },
      {
        title: "Integrations",
      },
      {
        title: "Free Support",
      },
    ],
  },
];

export const serviceProviderPricingPlan: PricingData[] = [
  {
    id: 1,
    subscription: "Miler Rectify",
    subscriptionType:
      "Start receiving service lead matches from heavy-duty fleet owners in your area.",
    price: "Join for Free",
    priceTye: " ",
    subscriptionSolution: "Service Provider",
    product: "4f09941115733a4e7609132dccc9891de191e0d78598307f897808dcdcab122b",
    plan: "MRPV-301",
    subscriptionList: [
      {
        title: "Access to Miler Rectify Service Marketplace",
      },
      {
        title: "Service Center Management Software",
      },
      {
        title: "Realtime Service Lead Matching",
      },
      {
        title: "Customer Billing",
      },
      {
        title: "Secure Payment Platform",
      },

      {
        title: "Customer Management",
      },
      {
        title: "Service Dispatch Management",
      },
      {
        title: "Technician Mobile App",
      },
    ],
  },
];
export default priceInfo;
