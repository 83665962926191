import { useState } from "react";
import Footer from "../../components/footer/Footer";
import InfoSection from "../../components/infoSection/InfoSection";
import NavBar from "../../components/navBar/NavBar";
import TabSection from "../../components/tabSection/TabSection";

const Pricing = () => {
  const [showSideBar, setShowSideBar] = useState(false);
  return (
    <section className="bg-gray-100">
      <NavBar showSideBar={showSideBar} setShowSideBar={setShowSideBar} />

      {!showSideBar && (
        <>
          <InfoSection
            title=""
            description=""
            subtitle="Find the right plan for your needs"
            pageText=""
          />
          <TabSection />

          <Footer />
        </>
      )}
    </section>
  );
};

export default Pricing;
