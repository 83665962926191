import CarrierImage from "../../assets/images/Carriers Updated (2).svg";
import BrokerImage from "../../assets/images/brokers.png";
import ShippersImage from "../../assets/images/shippers.png";
import ServiceProvidersImage from "../../assets/images/Service Providers Updated (2).svg";
import SolutionCard from "./SolutionCard";

const SolutionSection = () => {
  return (
    <section className="p-3 max-sm:w-screen w-full">
      <section className="flex flex-col mt-2 gap-y-2 justify-center items-center max-md:gap-y-2 max-lg:gap-y-2">
        <p className="text-miler_blue font-bold text-3xl leading-9 text-center antialiased max-sm:w-full max-sm:text-[1.3rem] max-md:text-[1.3rem]">
          Frictionless Freight, Flexible Solution, Faster Growth
        </p>
        <p className="antialiased leading-7  max-w-[700px] lg:max-w-[700px] md:max-w-[700px] max-sm:w-full  text-left  font-medium max-sm:text-center max-sm:leading-8 max-md:text-center">
          Unlock market opportunities with Miler’s cloud-native solution,
          meticulously engineered to give you a competitive edge and accelerate
          your service performance.
        </p>
      </section>

      <section className="">
        <SolutionCard
          showSide="right"
          title=""
          subtitle="Carriers"
          description="Simplify complex carrier operations and turn your first-time customers to repeat customers with Miler's end-to-end transportation and fleet maintenance platform. Effortlessly oversee your organizational safety performance with our comprehensive safety and compliance software."
          image={CarrierImage}
        />
        <SolutionCard
          showSide="left"
          title=""
          subtitle="Brokers"
          description="Miler’s broker TMS provides the workflows and visibility required to plan and execute shipments at an elite level. Easily manage your tenders and RFQs, track freight, and leverage your trusted network of carriers to drive the best results for your customers."
          image={BrokerImage}
        />
        <SolutionCard
          showSide="right"
          title=""
          subtitle="Shippers and 3pls"
          image={ShippersImage}
          description="Leverage your own preferred network or Miler's extensive network of freight brokers and carriers to haul your freight. Tender to your logistics partners directly and track your freight with one click. Gain end-to-end visibility of your freight shipment status from the moment of RFQ to delivery."
        />
        <SolutionCard
          showSide="left"
          title=""
          subtitle="Service Providers"
          description="Tap into Miler’s carrier and owner operator network and find and service customers in your area. Easily find your next jobs through Miler's service marketplace, get paid, and cut out expensive lead generation services and advertising."
          image={ServiceProvidersImage}
        />
      </section>
    </section>
  );
};

export default SolutionSection;
