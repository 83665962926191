import FreshBooksImage from "../../assets/intergration/FreshBooks.svg";
import MotiveImage from "../../assets/intergration/motive-removebg-preview.png";
import SageImage from "../../assets/intergration/Sage.svg";
import SamsaraImage from "../../assets/intergration/Samsara_logo_primary_horizontal_wht.png";
import XeroImage from "../../assets/intergration/Xero.svg";
import VerizonConnectImage from "../../assets/intergration/verizon-connect3355-removebg-preview.png";
import TriumphImage from "../../assets/intergration/triumph-logo-removebg-preview.png";
import ComDataImage from "../../assets/intergration/comdata-removebg-preview.png";
import QuickBooksImage from "../../assets/intergration/quickbooks-removebg-preview.png";
import EFSImage from "../../assets/intergration/efs-removebg-preview.png";

interface Icons {
  title: string;
  icon: string;
  link?: any;
}
interface IntergrationData {
  title: string;
  description: string;
  icon: Icons[];
}

const integrationData: IntergrationData[] = [
  {
    title: "Integrations",
    description:
      "Take advantage of our third party integrations with your electronic logging devices (ELD) for your fleet, fuel card solutions, and accounting systems.",
    icon: [
      {
        title: "FreshBooks Logo",
        icon: TriumphImage,
      },
      {
        title: "Samsara Logo",
        icon: SamsaraImage,
        link: "samsara-eld-tms",
      },
      {
        title: "Motive Logo",
        icon: MotiveImage,
        link: "motive-eld",
      },
      {
        title: "Verizon Connect",
        icon: VerizonConnectImage,
        link: "verizon-eld-tms",
      },
      {
        title: "Verizon Connect",
        icon: QuickBooksImage,
      },

      {
        title: "Sage Logo",
        icon: SageImage,
      },
      {
        title: "FreshBooks Logo",
        icon: FreshBooksImage,
      },

      {
        title: "Xero Logo",
        icon: XeroImage,
      },
      {
        title: "FreshBooks Logo",
        icon: EFSImage,
      },
      {
        title: "FreshBooks Logo",
        icon: ComDataImage,
      },
    ],
  },
];

export default integrationData;
