/* eslint-disable react/no-unescaped-entities */
import "../../index.css";
const Privacy = () => {
  return (
    <section
      className={`privacy_page flex flex-col  leading-7 text-justify not-italic text-black`}
    >
      <section className="flex flex-col p-[5rem] max-sm:p-[1rem] max-md:p-[1.5rem]">
        <h1 className="text-center text-4xl font-[500] max-sm:text-xl max-md:text-2xl">
          Miler Privacy Policy
        </h1>
        <span className="text-base  mt-3">
          Effective as of September 30, 2021
        </span>
        <section className="font-[400] text-[1.125rem]">
          <p className=" mt-3 ">
            Miler, LLC and its affiliates (“Miler”, “we”, “us” and/or “our”)
            collect and maintain certain personal data. This is Miler’s Privacy
            Policy, which explains how we obtain, use and protect this personal
            data in relation to your use of Miler’s website and software
            products. Your privacy and the security of your personal data
            matters to us, so please get to know our privacy practices and
            contact us should you have any questions about your personal data.
          </p>
          <h4 className="mt-3 font-[400] not-italic">
            1.THE INFORMATION WE COLLECT
          </h4>
          <ul className=" mt-2 list-disc p-2 ml-5">
            <li>
              <em className="underline">
                Personal data you provide directly to us.
              </em>
              We will collect personal data that you provide directly, such as
              when you access our website, use our software, establish a user
              account with us, or register our products. Depending on your
              interaction with us, we may collect personal identifiers, such as
              your real name, alias, postal address, unique personal identifier,
              online identifier, government-issued identifiers, email address,
              phone number, account name, or other similar identifiers. We may
              also collect Internet activity information, such as Internet
              protocol (IP) address, device and advertising identifiers, browser
              type, operating system, Internet service provider, the web page
              that directed you to our services, the date and time of your
              visit, information about how you engage with us (e.g., the links
              or buttons you click and pages you view of our website or
              applications), the fact of whether an email was opened, and other
              standard server log information. We may also collect commercial
              information, such as records of products or services purchased,
              obtained, or considered, or other purchasing or consumption
              histories or tendencies, and information regarding method of
              purchase, such as credit/debt card information, bank account
              numbers, and other account numbers used to facilitate the payment
              and receipt of monies. When you contact Miler, we may also keep a
              record of your communication to better support you in the future.
              From time to time, we may offer referral programs to refer
              prospective customers to us. Please refer a prospective customer
              and provide their personal data to us only if you have their
              permission. If you later find out you did not have such consent,
              please inform us as soon as possible.
            </li>
            <li>
              <u>
                <em>Personal data we collect automatically.</em>
              </u>
              We may automatically collect information about how you use and
              engage with our business (e.g., via our websites and/or software),
              products and/or services over time, and some of this may be
              personal data. In addition, we may use cookies and other
              technologies to function effectively.
            </li>
            <li>
              <u>
                <em>Personal data we collect from other sources.</em>
              </u>
              We may supplement your personal data that we have collected with
              information from third parties or other publicly available
              sources.
            </li>
          </ul>
          <br></br>
          <h4>
            <section className="">
              2. HOW WE USE THE INFORMATION WE COLLECT
            </section>
          </h4>
          <p className="p-2 ml-5">
            We use the data we collect from you to: (a) provide and operate our
            software; (b) address and respond to service, security, and customer
            support needs; (c) detect, prevent, or otherwise address fraud,
            security, unlawful, or technical issues; (d) comply with applicable
            laws and administrative requests, protect our rights, assert and
            defend against claims; (e) fulfill contracts; (f) maintain and
            improve our software; (g) provide analysis or valuable data back to
            our customers and users; (h) assess the needs of your business to
            determine and promote other Miler products which we believe may be
            helpful to you; (i) provide product updates, marketing
            communications, and service data; (j) conduct research and analysis
            for business planning and product development; and (k) to the extent
            permitted by law, we may combine, correct, and enrich personal data
            that we receive from you with data about you from other sources,
            including publicly available databases or from third parties to
            update, expand, and analyze our records, identify new prospects for
            marketing, and provide products and software that may be of interest
            to you.
          </p>
          <h4>
            <section className="mt-4">
              3. DISCLOSURE OF PERSONAL DATA TO OTHERS
            </section>
          </h4>
          <section className="p-2 ml-5 text-[1rem]">
            Protecting your personal data is important to us and we neither
            rent, sell, or otherwise provide your personal data to anyone that
            is not affiliated with Miler. The only instances where we may
            disclose your personal data are:
          </section>
          <ul className="list-disc ml-8">
            <li>
              to a buyer or other successor in the event of a merger,
              acquisition, divestiture, restructuring, reorganization,
              dissolution or other sale or transfer of some or all of our
              assets;
            </li>
            <li>
              to our subsidiaries, affiliates, agents, contractors, service
              providers and other third parties we use to support our business
              and/or collaborate with; provided always, they are bound by
              contractual obligations to keep such personal data confidential
              and only use it for the purposes of providing the services for, or
              with, us based upon your chosen settings, configurations, and/or
              purchases;
            </li>
            <li>
              to comply with any court order, law or legal process, including to
              respond to any government or regulatory request;
            </li>
            <li>
              to enforce or apply our Terms of Service and/or any other
              agreement that governs the sale, use or purchases of our products
              and/or services; and
            </li>
            <li>
              to protect the rights, property or security of Miler, our
              employees, our users and/or others.
            </li>
          </ul>
          <br></br>
          <h4>
            <section>4.HOW WE KEEP YOUR INFORMATION SECURE</section>
          </h4>
          <p className="p-2 ml-3 text-[1rem] leading-6 tracking-normal">
            We are committed to keeping personal data secure, and we have
            implemented technical and organizational measures to protect
            personal data against its unauthorized access, use, and disclosure.
            Such measures have been implemented taking into account the state of
            the art of the technology, their cost of implementation, the risks
            presented by the processing and the nature of the personal data.
          </p>
          <p className="ml-5">
            Nevertheless, please be aware that no method of transmitting
            information over the Internet or storing information is completely
            secure. Although we have tried to make our products and services
            secure and reliable, the confidentiality of any communication or
            material transmitted to or from us cannot be guaranteed. We
            therefore urge you to exercise caution when conveying any personal
            data over the internet. As when disclosing any information on the
            internet, you should remain mindful that such information might be
            accessible by the public and, consequently, may be collected and
            used by others without your consent. You should also recognize that
            your use of the internet and any other means of communication with
            us is solely at your own risk.
          </p>
          <h4 className="mt-4">
            <section>5. YOUR RIGHTS AND HOW TO EXERCISE</section>
          </h4>

          <p className="p-2 ml-3">
            You may have a right to access and to obtain a copy of your personal
            data as processed by Miler. If you believe that any personal data we
            hold about you is incorrect or incomplete, you may also request the
            correction of it. You may also have the right to object to the
            processing of your personal data, request the deletion of your
            personal data, request a restriction on the processing of your
            personal data, and/or withdraw your consent where Miler obtained
            your consent to process personal data (without this withdrawal
            affecting the lawfulness of any processing that took place prior to
            the withdrawal).
          </p>
          <p className="ml-5">
            Miler will honor such requests for access, correction, deletion or
            restriction and/or any withdrawal or objection as required under the
            applicable data protection laws and regulations. However, please
            note that such rights are not absolute: they do not always apply,
            and exemptions may be applicable. Miler will usually, in response to
            any such request, ask you to verify your identity and/or provide
            information that helps us to better understand your request. If we
            do not comply with your request, we will explain why.
          </p>
          <p className="ml-5 mt-3">
            To exercise the above rights with Miler, you may send an email{" "}
            <a
              className="text-blue-600 underline cursor-pointer"
              href="mailto:privacy@miler.com"
            >
              privacy@miler.com
            </a>
            . If you are not satisfied with how we process your personal data,
            please let us know and we will investigate your concern.
          </p>
          <h4 className="mt-4">
            <section>6.COOKIES</section>
          </h4>
          <p className="p-2 ml-3">
            Cookies are small text files placed on your computer, smartphone or
            other device and are commonly used on the internet. We use cookies
            and similar technologies to, collect information that will help us
            understand visitors' browsing habits on our website, compile
            statistical reports on website activity, e.g. number of visitors and
            the pages they visit, temporarily store any information which you
            may enter in tools, such as calculators or demonstrations on our
            website; and in some cases, remember information about you when you
            visit our site. We may need to do this to provide some of our
            services.
          </p>
          <p className="mt-2">
            We use cookies to enable us to perform our contract with you and for
            our legitimate interests (e.g. to help us improve our service).
            We’ll also ask your consent for non-essential cookies.
          </p>
          <h4 className="mt-3">
            <section>7.CALIFORNIA DO NOT TRACK DISCLOSURE.</section>
          </h4>
          <p className="p-2 ml-3">
            Miler does not track its customers over time and across third party
            websites to provide targeted advertising and therefore does not
            respond to Do Not Track (DNT) signals. Third parties cannot collect
            any other personally identifiable information from Miler’s websites
            or software unless you provide it to them directly.
          </p>
          <h4 className="mt-3">
            <section>8.NOTIFICATION OF CHANGES</section>
          </h4>
          <p className="p-2 ml-4">
            We may amend our privacy policy from time to time to reflect changes
            in our practices, technologies, legal requirements and other
            factors. We therefore encourage you to revisit this page
            periodically to read the current version of it to stay informed
            about our collection, processing and sharing of your personal data.
            Please note should we amend our privacy policy we will update the
            “effective date” at the top of this privacy policy. If you have any
            concerns or questions about this privacy policy or if you would like
            to lodge a complaint, please contact{" "}
            <a
              className="text-blue-600 underline font-medium"
              href="mailto:privacy@miler.com"
            >
              privacy@miler.com
            </a>
            .
          </p>
          <h4 className="mt-3">
            <section>9.HOW LONG DO WE STORE YOUR DATA?</section>
          </h4>
          <p className="p-2 ml-4">
            We will retain personal data only for as long as necessary to fulfil
            the purpose for which it was collected or to comply with legal,
            regulatory or internal policy requirements. To determine the
            appropriate retention period for personal data, we consider the
            amount, nature, and sensitivity of the personal data, the potential
            risk of harm from unauthorized use or disclosure of your personal
            data, the purposes for which we process your personal data and
            whether we can achieve those purposes through other means, and the
            applicable legal requirements.
          </p>
          <h4 className="mt-3">
            <section>10. CHILDREN</section>
          </h4>
          <p className="p-2 ml-4">
            Our Service are not intended for anyone under the age of 18
            (“Children”). We do not knowingly collect personally identifiable
            information from anyone under the age of 18. If we become aware that
            we have collected personal data from children, we will take steps to
            remove that information from our servers.
          </p>

          <h4 className="mt-3">
            <section>11. ADDITIONAL CALIFORNIA PRIVACY RIGHTS</section>
          </h4>
          <p className="p-2 ml-4">
            If you reside in California, you may request certain details about
            our disclosure of your Personal Information to third parties for
            direct marketing purposes during the preceding year. If you are a
            California resident and would like to make a request for this
            information, please reach out to us at the contact information
            listed below.
          </p>
          <h4 className="mt-3">
            <section>12. CONTACT US</section>
          </h4>
          <p className="p-2 ml-4">
            If you have any questions regarding our privacy practices or would
            like to review or update your personal data, you may email us at{" "}
            <a
              className="text-blue-600 underline font-medium"
              href="mailto:privacy@miler.com"
            >
              privacy@miler.com
            </a>
            . You can also contact us by writing to us at the following address:
            Attn: Privacy, 10895 Lowell Ave, Ste 205, Overland Park, KS 66210.
          </p>
        </section>
      </section>
    </section>
  );
};

export default Privacy;
