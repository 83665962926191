import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import NavBar from "../../../components/navBar/NavBar";
import Footer from "../../../components/footer/Footer";
import { Blog } from "../../../App";
import { PortableText } from "@portabletext/react";

interface PortableTextListProps {
  children: React.ReactNode;
}

interface PortableTextListItemProps {
  children: React.ReactNode;
}
interface ParagraphProps {
  children: React.ReactNode;
}
// Define custom components
const BulletList: React.FC<PortableTextListProps> = ({ children }) => (
  <ul className="list-disc  p-[1rem] ml-5 flex flex-col gap-y-4 max-sm:ml-3">
    {children}
  </ul>
);

const NumberedList: React.FC<PortableTextListProps> = ({ children }) => (
  <ol className="list-decimal  p-[1rem] ml-5 flex flex-col gap-y-4 max-sm:ml-3">
    {children}
  </ol>
);

const CheckmarkList: React.FC<PortableTextListProps> = ({ children }) => (
  <ol className="m-auto text-lg">{children}</ol>
);

const ListItem: React.FC<PortableTextListItemProps> = ({ children }) => (
  <li>{children}</li>
);
const Paragraph: React.FC<ParagraphProps> = ({ children }) => (
  <p className="mt-4">{children}</p> // Add margin-top for paragraphs
);
interface BlogDetailsProps {
  blogs: Blog[];
}

const BlogDetails: React.FC<BlogDetailsProps> = ({ blogs }) => {
  const [showSideBar, setShowSideBar] = useState(false);

  const { blog } = useParams<{ blog: string }>();
  const blogInfo = blogs.find((info) => info.slug.current === blog);

  const navigate = useNavigate();
  useEffect(() => {
    if (!blogInfo) {
      navigate("/blogs");
    }
  }, [blogInfo, navigate]);

  const components: any = {
    list: {
      bullet: BulletList,
      number: NumberedList,
      checkmarks: CheckmarkList,
    },
    listItem: ListItem,
    block: {
      normal: Paragraph,
    },
  };

  return (
    <section>
      <NavBar showSideBar={showSideBar} setShowSideBar={setShowSideBar} />
      {!showSideBar && (
        <>
          <section className="relative -z-10">
            <section className="relative">
              <img
                src={blogInfo?.mainImage?.asset.url}
                className="w-full object-cover h-[70vh] bg-black"
                alt="Blog"
              />
              <div className="absolute inset-0 bg-gradient-to-t from-black via-transparent"></div>
              <p className="absolute z-10 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 font-bold text-white text-3xl max-sm:text-[1rem] max-sm:w-full max-sm:text-center max-sm:p-3 max-md:text-2xl">
                {blogInfo?.title}
              </p>
            </section>

            {/* Conditional content */}
            {/* {blogInfo?.link === "tms" && <TMSContent />}
            {blogInfo?.link === "features-tms" && <FeatureTMS />}
            {blogInfo?.link === "logistics-tms" && <LogisticsTMS />}
            {blogInfo?.link === "state-of-freight" && <StateFreight />} */}

            <section className="w-full p-[5rem] text-justify leading-8 tracking-normal text-[1.2rem] font-medium max-sm:text-justify max-sm:p-[1.5rem] max-sm:w-full max-md:w-full max-md:p-[1.5rem]">
              <PortableText
                value={blogInfo?.content as any}
                components={components}
              />
            </section>
          </section>
        </>
      )}
      <Footer />
    </section>
  );
};

export default BlogDetails;
