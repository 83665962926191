import { useState } from "react";
import BlogCard from "../../components/blogCard/blogCard";
import Footer from "../../components/footer/Footer";
import InfoSection from "../../components/infoSection/InfoSection";
import NavBar from "../../components/navBar/NavBar";
import { Blog } from "../../App";
interface BlogsProps {
  blogs: Blog[];
}

const Blogs: React.FC<BlogsProps> = ({ blogs }) => {
  const [showSideBar, setShowSideBar] = useState<boolean>(false);

  return (
    <section>
      <NavBar showSideBar={showSideBar} setShowSideBar={setShowSideBar} />
      {!showSideBar && (
        <>
          <InfoSection
            title=""
            description=""
            subtitle="Latest Blogs On Fleet Management Topics"
            pageText=""
          />

          <BlogCard blogs={blogs} />
          <Footer />
        </>
      )}
    </section>
  );
};
export default Blogs;
