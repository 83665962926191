import { ChangeEvent, useState} from "react";
import FirstStep from "../../components/register/FirstStep";
import SecondStep from "../../components/register/SecondStep";
import axiosInstance from "../../utils/api/axiosConfig";
import { useLocation } from "react-router-dom";
import MilerPlan from "../../components/plan/Plan";
interface FormSteps {
  component: React.ReactNode;
}

interface CompanyInfo {
  company: string;
  addressLine: string;
  fleet: string;
  postalCode: string;
  state: string;
  city: string;
}
export interface FormFields {
  dotNumber: string;
  companyInfo: CompanyInfo;
}
const GetStarted = () => {
  const formFields = {
    dotNumber: "",
    companyInfo: {
      company: "",
      addressLine: "",
      fleet: "1",
      postalCode: "",
      state: "",
      city: "",
    },
  };
  const [activeStep, setActiveStep] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState(false);

  const [companyDetails, setCompanyDetails] = useState<FormFields>(formFields);

  const location = useLocation();
  //   const subscription = location.state?.subscription;
  //   const subscriptionSolution = location.state?.subscriptionSolution;

  const plan = location.state?.plan;
  const product = location.state?.product;

  const [error, showError] = useState(false);
  const nextStep = () => {
    if (!companyDetails.dotNumber) {
      showError(true);
      return;
    }

    if (activeStep === 0) {
      fetchCompanyDetails();
    }

    if (activeStep === 1) {
      setActiveStep((prev) => prev + 1);
    }
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target;
    showError(false);
    setApiError(false);
    if (name === "dotNumber") {
      setCompanyDetails({
        ...companyDetails,
        dotNumber: value,
      });
    } else {
      setCompanyDetails({
        ...companyDetails,
        companyInfo: {
          ...companyDetails.companyInfo,
          [name]: value,
        },
      });
    }
  };

  const fetchCompanyDetails = () => {
    setLoading(true);
    setApiError(false);
    axiosInstance
      .get(
        `/carrier/integration/misc/fmcsa/registration?env=live&dot_number=${companyDetails.dotNumber}`
      )
      .then((response) => {
        const data = response?.data?.data || [];
        if (response.status) {
          setCompanyDetails({
            ...companyDetails,
            dotNumber: data[0].dot_number,
            companyInfo: {
              ...companyDetails.companyInfo,
              company: data[0]?.company,
              addressLine: data[0]?.address_line_1,
              fleet: data[0]?.fleet?.power_units,
              postalCode: data[0]?.postal_code,
              state: data[0]?.state,
              city: data[0]?.city,
            },
          });
          setLoading(false);
          setActiveStep((prev) => prev + 1);
        }
      })
      .catch((error) => {
        if (error) {
          setLoading(false);
          setApiError(true);
        }
      });
  };

  const carrierStepsData: FormSteps[] = [
    {
      component: (
        <FirstStep
          nextStep={nextStep}
          handleChange={handleChange}
          companyDetails={companyDetails}
          error={error}
          product={product}
          loading={loading}
          apiError={apiError}
        />
      ),
    },

    {
      component: (
        <SecondStep
          companyDetails={companyDetails}
          handleChange={handleChange}
          nextStep={nextStep}
          product={product}
        />
      ),
    },
    {
      component: (
        <MilerPlan
          plan={plan}
          product={product}
          companyDetails={companyDetails}
        />
      ),
    },
  ];

  const brokerStepsData: FormSteps[] = [
    {
      component: (
        <FirstStep
          nextStep={nextStep}
          handleChange={handleChange}
          companyDetails={companyDetails}
          error={error}
          product={product}
          loading={loading}
          apiError={apiError}
        />
      ),
    },
    {
      component: (
        <MilerPlan
          plan={plan}
          product={product}
          companyDetails={companyDetails}
        />
      ),
    },
  ];

  const shipperStepsData: FormSteps[] = [
    {
      component: (
        <MilerPlan
          plan={plan}
          product={product}
          companyDetails={companyDetails}
        />
      ),
    },
  ];
  const serviceProviderStepsData: FormSteps[] = [
    {
      component: (
        <MilerPlan
          plan={plan}
          product={product}
          companyDetails={companyDetails}
        />
      ),
    },
  ];
  const renderMilerPlansSteps = () => {
    switch (product) {
      case "a2f078838857b1df381599a5560baaf72d1433f07e0c1f74ad482bf477cf719e":
        return carrierStepsData[activeStep].component;

      case "4f09941115733a4e7609132dccc9891dcbf7b178e6af0857494e2c5ddd3f0051":
        return brokerStepsData[activeStep].component;
      case "4f09941115733a4e7609132dccc9891d64ca422e9690a35fb7d3f8995def816b":
        return brokerStepsData[activeStep].component;
      case "4f09941115733a4e7609132dccc9891d398d23d2f73003bf027cc62133d9423d":
        return brokerStepsData[activeStep].component;
      case "4f09941115733a4e7609132dccc9891da6018b5446c1e5f3a3736e575b0b28dd":
        return shipperStepsData[activeStep].component;
      case "4f09941115733a4e7609132dccc9891d938a4aa64b216cc08e20721c6c09c65f":
        return shipperStepsData[activeStep].component;
      case "4f09941115733a4e7609132dccc9891de191e0d78598307f897808dcdcab122b":
        return serviceProviderStepsData[activeStep].component;
      default:
        return <></>;
    }
  };

  return <main>{renderMilerPlansSteps()}</main>;
};

export default GetStarted;
