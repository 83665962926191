const MobileFormScreen = () => {
  return (
    <section className="bg-white hidden max-sm:block max-md:block rounded-sm p-3 max-lg:hidden">
      <section className="flex flex-col gap-y-2 max-sm:p-3 ">
        <h3 className="font-semibold text-xl antialiased">
          Talk to our sales team
        </h3>
        <p className="max-sm:w-[90%]">
          Find out how Miler can help your company or get a product demo. We&apos;ll
          be in touch shortly
        </p>
      </section>
      <form className="flex flex-col p-2 bg-gray-100 rounded gap-y-3 mt-7 max-sm:p-3">
        <p>First Name</p>
        <input type="text" />
        <p>Last Name</p>
        <input type="text" />
        <p>Email</p>
        <input type="email" />
        <p>Phone</p>
        <input type="text" />
        <p>Company Name</p>
        <input type="text" />
        <p>Industry</p>
        <select>
          <option>Carrier</option>
          <option>Broker</option>
          <option>Fleet Owner Operator</option>
          <option>Service Provider</option>
        </select>
        <p>Subject</p>
        <input type="text" />
        <p>Message</p>
        <input type="text" />
        <button className="bg-miler_blue w-full text-white p-2 text-base font-normal">
          Contact
        </button>
      </form>
    </section>
  );
};

export default MobileFormScreen;
