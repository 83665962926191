import { useNavigate } from "react-router-dom";
interface BannerProps {
  title?: string;
}
const Banner = (props: BannerProps) => {
  const { title } = props;

  const navigate = useNavigate();
  return (
    <section className="bg-slate-100 p-[3.5rem] flex flex-col justify-center mt-[2rem] w-full max-sm:px-[2rem]">
      <section className=" flex flex-row justify-between sm:flex-col max-sm:justify-center max-sm:items-center">
        <section className=" flex flex-col gap-y-2 max-sm:flex">
          <p className="text-miler_dark_blue font-bold text-2xl max-sm:text-base">
            {title ||
              "Ready to dive in with an integrated software tool that covers your logistics, compliance, fleet management and maintenance?"}
          </p>
          <section className=" flex flex-row justify-between max-sm:flex-col max-sm:gap-y-3">
            <p className="text-miler_blue text-2xl w-full font-bold max-sm:text-[0.9rem]">
              Register Now for immediate ROI
            </p>
            <button onClick={()=>{
              navigate('/pricing')
            }} className="bg-miler_blue w-[12rem] p-2 h-[2.5rem] rounded-md text-white max-sm:text-[0.9rem] max-sm:w-fit">
              Get Started
            </button>
          </section>
        </section>
      </section>
    </section>
  );
};

export default Banner;
