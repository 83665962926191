import { useState } from "react";
import Footer from "../../components/footer/Footer";
import NavBar from "../../components/navBar/NavBar";
import Terms from "../../components/terms/Terms";

const TermsPage = () => {
  const [showSideBar, setShowSideBar] = useState(false);

  return (
    <>
      <NavBar showSideBar={showSideBar} setShowSideBar={setShowSideBar} />
      <Terms />
      <Footer />
    </>
  );
};

export default TermsPage;
