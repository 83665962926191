import { Link } from "react-router-dom";
import MilerLogo from "../../assets/logo/Logo.svg";
import footerData, { socialIcons } from "./data";
const Footer = () => {
  return (
    <section className="bg-miler_footer flex flex-col justify-center p-[3.5rem] h-full w-full max-sm:p-1  max-md:p-3  ">
      <section className="flex flex-row gap-x-20 w-full justify-between  items-center max-sm:mt-10 max-sm:block max-md:grid max-md:grid-cols-1 max-md:gap-[1rem] max-md:place-items-start max-lg:grid max-lg:grid-cols-1  max-lg:place-items-start max-xl:grid max-xl:grid-cols-1">
        <section className="text-gray-400 max-sm:flex max-sm:flex-col max-sm:gap-y-3 max-md:flex max-md:flex-col max-md:gap-y-3 max-sm:p-2">
          <Link to="/">
            <img
              src={MilerLogo}
              width={100}
              height={40}
              alt="miler_logo"
              className="w-[130px]"
            />
          </Link>
          <section className="mt-4 antialiased leading-7 tracking-normal p-2">
            <p>10895 Lowerell Avenue, Suite 205</p>
            <p>Overland Park, Kansas 66210</p>
            <p>866-497-2063</p>
          </section>
          <section className="flex flex-row gap-x-3 mt-3 items-center max-sm:p-2 max-md:px-2 max-lg:px-2 max-xl:p-2">
            {socialIcons.map((social, index) => {
              return (
                <a
                  href={social.linkUrl}
                  target="_blank"
                  rel="noreferrer"
                  key={index}
                >
                  <img
                    src={social.image}
                    width={social.configureSize ? 15 : 25}
                    height={20}
                    alt="social_icons"
                  />
                </a>
              );
            })}
          </section>
        </section>
        <section className="grid grid-cols-4 p-3 place-content-center w-full mt-5 text-miler_footer_text text-base gap-[12rem] max-sm:w-full max-sm:grid max-sm:grid-cols-2 max-sm:mt-10 max-sm:gap-6 max-md:grid max-md:grid-cols-3 max-md:gap-4 max-lg:grid-cols-3 max-lg:gap-[5rem] max-xl:grid-cols-4">
          {footerData.map((footer, index) => {
            return (
              <section key={index}>
                <p className="text-miler_yellow font-semibold text-[1.2rem]">
                  {footer.title}
                </p>
                <section className="mt-3">
                  {footer.subtitle.map((info, index) => {
                    return (
                      <section key={index}>
                        <Link
                          to={
                            footer.title.toLowerCase() ===
                              "Solutions".toLowerCase() &&
                            info.link.toLowerCase() !== "pricing"
                              ? `/solutions/${info.link.toLowerCase()}`
                              : ([
                                  "carrier",
                                  "broker",
                                  "provider",
                                  "shipper",
                                ].includes(info.link.toLowerCase())
                              ? `/customers/${info.link.toLowerCase()}`
                              :
                              (info.title.toLowerCase() ===
                              "Help Center".toLowerCase()
                              ? `${info.link.toLowerCase()}`
                              : `/${info.link.toLowerCase()}`))
                          }
                          key={index}
                        >
                          <p className="mt-2 max-xl:w-[15rem] max-xxl:w-[15rem] max-sm:w-full max-md:text-[.9rem]">
                            {info.title}
                          </p>
                        </Link>
                      </section>
                    );
                  })}
                </section>
              </section>
            );
          })}
          {/* <Link href="/" prefetch={false} className="">
            <Image
              src={MilerIcon}
              alt="miler_logo"
              width={240}
              height={100}
              className="object-contain"
            />
          </Link> */}
        </section>
      </section>
    </section>
  );
};

export default Footer;
