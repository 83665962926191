import { useNavigate } from "react-router-dom";
import MilerButton from "../milerButton/MilerButton";

interface CardProps {
  title: string;
  subtitle: string;
  description?: string;
  showSide: "right" | "left";
  image: string;
}
const SolutionCard = (props: CardProps) => {
  const { showSide, title, description, subtitle, image } = props;

  const navigate = useNavigate();
  return (
    <>
      {showSide === "right" && (
        <section className="bg-hero_banner bg-slate-50 flex flex-row p-[3.5rem] mt-14 max-sm:w-full max-sm:flex max-sm:flex-col max-sm:justify-start max-sm:items-start max-sm:p-2 max-sm:mt-2 max-md:flex max-md:flex-col max-md:p-2 max-md:w-full ">
          <section className="flex flex-col gap-y-5 w-[50%] max-sm:flex max-sm:flex-col max-sm:gap-y-1 max-md:w-full   text-left max-sm:w-full max-sm:mt-3 max-sm:font-[400] max-sm:text-left max-lg:w-full  ">
            <section className="max-sm:w-full max-sm:p-2">
              <p className="text-3xl text-miler_blue font-bold antialiased max-sm:text-xl max-md:text-2xl">
                {title}
                <span className="text-3xl text-miler_yellow antialiased font-bold max-sm:text-xl max-md:text-2xl">
                  {subtitle}
                </span>
              </p>
              <p className="w-[90%] text-left leading-9  antialiased tracking-normal text-base font-medium  max-sm:text-justify max-sm:w-fit max-md:w-full max-lg:w-full">
                {description}
              </p>
            </section>
            <img
              src={image}
              width={570}
              height={432}
              alt="carrier_image"
              className="hidden max-sm:block max-sm:text-center max-sm:p-2 max-sm:object-contain max-sm:m-auto max-md:m-auto max-md:w-[500px] max-lg:block max-lg:w-[90%] max-lg:px-4"
            />
            <MilerButton
              title="Explore"
              showIcon={true}
              size="small"
              handleClick={() => {
                if (subtitle?.toLowerCase().includes("carriers")) {
                  navigate("/solutions/carrier");
                }

                if (subtitle.toLowerCase().includes("shippers")) {
                  navigate("/solutions/shipper");
                }
              }}
            />
          </section>
          <section className="w-[50%]  max-lg:hidden max-xl:p-4">
            <img
              src={image}
              width={570}
              height={532}
              alt="carrier_image"
              className="max-sm:hidden max-lg:hidden  w-[650px] h-[250px] max-sm:w-full object-contain "
            />
          </section>
        </section>
      )}

      {showSide === "left" && (
        <section className=" flex flex-row p-[3.5rem] max-sm:w-full max-sm:flex max-sm:flex-col max-sm:justify-start max-sm:items-start max-sm:p-2 max-sm:mt-3 max-md:flex max-md:flex-col max-md:w-full max-md:p-0 max-md:mt-6">
          <section className="w-[50%] max-xxl:w-[50vw] max-sm:w-full max-lg:hidden">
            <section className="w-full   max-lg:hidden max-xl:p-4 ">
            <img
              src={image}
              width={570}
              height={532}
              alt="carrier_image"
              className="max-sm:hidden max-lg:hidden  w-[550px] h-[250px] object-contain"
            />
            </section>
          </section>
          <section className="w-[50%] flex flex-col gap-y-5 max-sm:flex sm:flex-col max-sm:gap-y-1  text-justify max-sm:w-full max-sm:mt-3 max-md:w-full max-lg:w-full">
            <p className="text-3xl text-miler_blue font-bold antialiased max-sm:text-xl max-sm:w-fit ">
              {title}{" "}
              <span className="text-3xl text-miler_yellow antialiased font-bold max-sm:text-xl max-md:text-2xl">
                {subtitle}
              </span>
            </p>
            <p className="w-[90%] leading-9  antialiased tracking-normal text-base font-medium miler_black_color max-sm:text-justify max-sm:w-fit max-md:w-fit max-lg:w-fit ">
              {description}
            </p>
            <img
              src={image}
              width={570}
              height={532}
              alt="carrier_image"
              className="hidden object-contain max-sm:block max-sm:p-2 max-sm:object-contain max-sm:m-auto max-md:m-auto max-md:object-contain max-md:w-[500px] max-lg:block  max-lg:w-[90%] max-lg:px-4 "
            />
            <MilerButton
              title="Explore"
              showIcon={true}
              size="small"
              handleClick={() => {
                if (subtitle.toLowerCase().includes("brokers")) {
                  navigate("/solutions/broker");
                }
                if (subtitle.toLowerCase().includes("service providers")) {
                  navigate("/solutions/provider");
                }
              }}
            />
          </section>
        </section>
      )}
    </>
  );
};

export default SolutionCard;
