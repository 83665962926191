/* eslint-disable @typescript-eslint/no-unused-vars */
import { Routes, Route } from "react-router-dom";
import HomePage from "./pages/homePage/HomePage";
import ContactPage from "./pages/contact/Contact";
import PrivacyPage from "./pages/privacy/Privacy";
import TermsPage from "./pages/terms/Terms";
import RequestDemoPage from "./pages/requestDemo/RequestDemo";
import PageNotFound from "./pages/pageNotFound/pageNotFound";
import Broker from "./components/broker/broker";
import Carrier from "./components/carrier/Carrier";
import ServiceProvider from "./components/serviceProvider/ServiceProvider";
import Shipper from "./components/shipper/Shipper";
import Solution from "./components/solutions/Solution";
import SolutionCarrier from "./components/solutions/carrier/Carrier";
import Blogs from "./pages/blog/Blog";
import Pricing from "./pages/pricing/Pricing";
import ServiceAgreement from "./pages/ServiceLeveLAgreement/SLAgreement";
import BlogDetails from "./pages/blog/details/BlogDetails";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import client from "./client";
import SamsaraIntegration from "./pages/integration/samsara/Samsara";
import Motive from "./pages/integration/motive/Motive";
import Verizon from "./pages/integration/verizon/Verizon";
import Provider from "./pages/provider/Provider";
import SolutionBroker from "./components/solutions/broker/Broker";
import ShipperSolution from "./components/solutions/shipper/Shipper";
import PageTitleUpdater from "./components/pageTitleUpdater/PageTitleUpdater";
import AboutUs from "./pages/aboutUs/AboutUs";
import Sitemap from "./config/Sitemap";
import GetStarted from "./pages/getStarted/getStarted";
import ProviderTerms from "./pages/terms/provider/Terms";

export interface Blog {
  _id: string;
  title: string;
  slug: {
    current: string;
  };
  description: string;
  publishedAt: string;
  content: any;
  mainImage?: {
    asset: {
      _id: string;
      url: string;
    };
  };
}
function App() {
  const ScrollToTop = () => {
    const { pathname } = useLocation();
    window.history.scrollRestoration = "manual";
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);

    return null;
  };
  const [blogs, setBlogs] = useState<Blog[]>([]);
  const [error, setError] = useState<any>(null);
  /* eslint-disable @typescript-eslint/no-unused-vars */

  const query = `*[_type == "blog"]{
    _id,
    title,
    slug,
    description,
    publishedAt,
    content,
    mainImage{
      asset->{
        url
      }
    }
  }`;

  const fetchBlogs = async () => {
    // @ts-ignore
    client.fetch<Blog[]>(query)
      .then((data: Blog[]) => {
        setBlogs(data);
      })
      .catch((err: any) => {
        setError(err);
      });
  };

  useEffect(() => {
    fetchBlogs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="overflow-x-hidden">
      <ScrollToTop />
      <PageTitleUpdater />
      <Routes>
        <Route path="/" element={<HomePage />}></Route>
        <Route path="contact" element={<ContactPage />} />
        <Route path="privacy" element={<PrivacyPage />} />
        <Route path="terms" element={<TermsPage />} />
        <Route path="request-demo" element={<RequestDemoPage />} />
        <Route path="customers/carrier" element={<Carrier />} />
        <Route path="customers/broker" element={<Broker />} />
        <Route path="customers/shipper" element={<Shipper />} />
        <Route path="customers/provider" element={<ServiceProvider />} />
        <Route path="solutions" element={<Solution />}></Route>
        <Route path="/solutions/carrier" element={<SolutionCarrier />} />
        <Route path="solutions/broker" element={<SolutionBroker />} />
        <Route path="solutions/shipper" element={<ShipperSolution />} />
        <Route path="solutions/provider" element={<Provider />} />
        <Route path="/blogs" element={<Blogs blogs={blogs} />} />
        <Route path="blog/:blog" element={<BlogDetails blogs={blogs} />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="sla" element={<ServiceAgreement />} />
        <Route path="*" element={<PageNotFound />} />
        <Route path="/samsara-eld-tms" element={<SamsaraIntegration />} />
        <Route path="/motive-eld" element={<Motive />} />
        <Route path="/verizon-eld-tms" element={<Verizon />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/sitemap.xml" element={<Sitemap />} />
        <Route path="/sitemap.xml" element={<Sitemap />} />
        <Route path="/registration" element={<GetStarted />} />
        <Route path="/provider/terms" element={<ProviderTerms />} />
        <Route />
      </Routes>
    </div>
  );
}

export default App;
