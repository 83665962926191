import { useState } from "react";
import Footer from "../../../components/footer/Footer";
import NavBar from "../../../components/navBar/NavBar";
import HeroPageSection from "../../../components/pagesSection/HeroSection";

const Motive = () => {
  const [showSideBar, setShowSideBar] = useState(false);

  return (
    <section className="min-h-screen flex flex-col bg-slate-50">
      <NavBar showSideBar={showSideBar} setShowSideBar={setShowSideBar} />
      {!showSideBar && (
        <>
          <section className="bg-slate-50 flex-grow">
            <HeroPageSection
              title="integration with Motive ELD"
              subscriptionPlan="Free Miler TMS"
              description={
                "Miler TMS with Motive gives carriers all the operational efficiency & visibility they need to run more effectively"
              }
              plansOptions={[
                {
                  title: "Real-time shipment tracking",
                },
                {
                  title: "Location-aware dispatch workflow",
                },
                {
                  title: "Accurate distance calculation",
                },
                {
                  title: "IFTA reports",
                },
                {
                  title: "Automated fuel import",
                },
              ]}
              isIntergration={true}
            />
          </section>
        </>
      )}
      <Footer />
    </section>
  );
};

export default Motive;
